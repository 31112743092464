.modal {
  padding-top: 70px;
  padding-bottom: 32px;
  max-width: 720px;
  width: 100%;
  z-index: 999999;
  margin-left: auto;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: auto;
  left: 50%;
  top: 50%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone {
  border: 2px dashed #999;
  padding: 20px;
  width: 400px;
  text-align: center;
  cursor: pointer;
}

.appHide {
  display: none;
}

.cropContainer {
  position: relative;
  width: 320px;
  height: 200px;
  background: #fff;
}

.hide {
  display: none;
}

.controls {
  margin: 40px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.delete {
  margin-top: 24px;
}

.input {
  padding: 0;
  width: 100%;
}

.flex {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  margin-top: 24px;
  width: 100%;
}

.submit {
  border-radius: 8px;
  padding: 11px 22px;
  line-height: 20px;
  font-weight: 700;
  font-size: 14px;
  width: 144px;
}

.changeImage {
  background-color: transparent;
  color: #5bafd7;
  border-radius: 8px;
  padding: 11px 12px;
  line-height: 20px;
  font-weight: 700;
  font-size: 14px;
  width: 144px;
}

.rotationValue {
  line-height: 18px;
  font-size: 12px;
}

.deleteImage {
  border-radius: 8px;
  background: #eaeaea;
  padding: 12px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #61616f;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.deleteImage:hover {
  color: #61616f;
  background: #eaeaea;
}

.actionButton {
  border: none;
  background-color: transparent;
  outline: none;
  text-align: center;
  padding: 0;
  display: block;
  cursor: pointer;
  color: #353546;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
}

.actionButton:hover {
  background-color: transparent;
  color: #353546;
}

.rotate {
  background-color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d7d2d2;
  cursor: pointer;
}

.rotate svg {
  width: 24px;
  height: 24px;
}

.rotateActions {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media screen and (min-width: 768px) {
  .zoomContent {
    width: 50%;
  }

  .rotateContent {
    width: 50%;
  }

  .input {
    width: 100%;
  }

  .controls {
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }

  .deleteImage {
    margin-right: auto;
  }

  .input {
    width: 200px;
  }
}

@media screen and (min-width: 1008px) {
  .modal {
    min-width: 812px;
  }

  .cropContainer {
    width: 840px;
    height: 390px;
  }
}
