.textarea {
  display: block;
  background-color: #fff;
  min-height: 152px;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-height: 310px;
  resize: vertical;
}

.errorText {
  position: absolute;
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  text-align: right;
  margin-top: 4px;
}

.inputWrap {
  margin-bottom: 16px;
}

.textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: #abafb9;
}

.info {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #8b8b8b;
  display: block;
  margin-top: 16px;
}

.infoActive {
  color: var(--color-red-400);
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-bottom: 8px;
  color: #353546;
}

.content {
  margin-top: 24px;
}

.companyContent {
  margin-top: 28px;
}

.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
}

.select {
  width: 100%;
  max-width: 100%;
}

.select input[name="acquired_date"] {
  padding: 12px 10px;
  border: none;
}

.select .MuiTextField-root {
  padding: 12px 10px;
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.locationItem {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  width: 100%;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.input {
  background-color: #f8f8fa;
  padding: 10px;
  border: 1px solid transparent;
}

.input::placeholder,
.inputWebsite::placeholder {
  color: #abafb9;
}

.textareaError {
  border-color: var(--color-red-400);
  background-color: var(--color-red-100);
}

.inputWebsite {
  padding: 11px 12px;
}

.hidden {
  display: none;
}

@media screen and (min-width: 768px) {
  .info {
    margin-top: 28px;
  }

  .flex {
    display: flex;
    gap: 28px;
    width: 100%;
    margin-top: 20px;
  }

  .flex > div {
    width: 50%;
  }
}
