.answerTitle {
  margin-right: 10px;
}

.icon {
  margin-right: 10px;
}

.titleBox {
  display: flex;
  align-items: center;
}

.topContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.itemQuestionAnswers {
  margin-bottom: 40px;
}

.answerTextSimple {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  color: var(--color-black-500);
  margin-bottom: 0;
  margin-left: 5px;
}

.question {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-bottom: 8px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  display: block;
  color: var(--color-black-500);
  margin-bottom: 0;
}

.answerBox {
  font-size: 14px;
  line-height: 20px;
}

.myAnswer {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  margin-bottom: 5px;
  display: inline-block;
}

.missing {
  color: var(--color-red-400);
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  margin-left: 5px;
}

.fontWeight {
  font-weight: 600;
}

.editBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  padding: 0;
  margin-left: 5px;
  position: relative;
  background: var(--color-white-900);
  border-color: var(--color-white-900);
}

.editBtn svg {
  width: 14px;
  stroke: var(--color-blue-800);
}

.editBtnRed svg {
  stroke: var(--color-red-400);
}

.editBtn:hover {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
}

.penIcon {
  width: 13px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #353546;
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.hr {
  display: block;
  margin: 40px 0;
  min-width: auto;
}

.colorAttention {
  color: #da4655;
}

.content {
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .content {
    margin-bottom: 60px;
  }
}
