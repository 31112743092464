.questions {
  margin-bottom: 40px;
}
.title {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
}

.question {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #353546;
  display: block;
  margin-bottom: 10px;
}

.answer {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  margin: 0;
}

.item {
  margin-bottom: 40px;
}

.item:last-child {
  margin-bottom: 0;
}
