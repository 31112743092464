.head {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  margin-bottom: 0;
}

.button {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline;
  color: #5bafd7;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.button:hover {
  text-decoration: none;
}

.item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  min-height: 56px;
  max-width: 250px;
}

.hoursItem {
  margin-bottom: 16px;
}

.detail {
  display: flex;
  gap: 12px;
  align-items: center;
}

.detailItem {
  width: 100%;
  flex: none;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 36px;
}

.hoursDetailItem {
  width: 100%;
  flex: none;
  display: flex;
  align-items: center;
  gap: 12px;
}

.dateFullDate > div {
  width: 74px;
  border-radius: 8px;
}

.dateFullDate input {
  padding: 10px;
}

.dateSymbol {
  background: #f8f8fa;
  width: 24px;
  height: 24px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  border-radius: 100%;
  flex: none;
}

.dateSymbolActive {
  background: #2c2d64;
  color: #ffffff;
}

.closed {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(9, 10, 40, 0.8);
  margin-top: 4px;
  display: block;
}

.divider {
  width: 10px;
  height: 1px;
  background-color: #000000;
  display: block;
  margin: 0 -4px;
}

.input {
  width: 74px;
  margin-bottom: 0;
}

.classNameWrap {
  width: 74px;
}

.add {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}

.add svg {
  width: 100%;
  height: 100%;
  flex: none;
}

@media screen and (min-width: 768px) {
  .dateSymbol {
    width: 28px;
    height: 28px;
  }

  .detailItem {
    margin-left: 40px;
  }
}
