.title {
  font-weight: bold;
  font-size: 22px;
  color: #1c1d40;
  margin-bottom: 30px;
}

.label {
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: inline-block;
  margin-bottom: 12px;
}

.textAreaContent {
  margin-bottom: 30px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  color: #353546;
  display: block;
  margin-bottom: 30px;
}

.textArea {
  height: 300px;
}

.button {
  display: block;
  margin: 0 -40px;
  border-radius: 0;
}
