.plan {
  background: #ffffff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 15px 20px;
  margin-bottom: 30px;
}

.content {
  width: 100%;
}

.name {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1c1d40;
  text-align: center;
  margin-bottom: 16px;
}

.listingType {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #1c1d40;
  margin-top: 50px;
}

.listingName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  margin-left: 10px;
}

.listing {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.priceContent {
  text-align: center;
  margin-top: 30px;
}

.priceNumber {
  display: block;
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  color: #1c1d40;
}

.priceDescription {
  font-weight: 800;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
}

.icon path {
  fill: #1c1d40;
}

.listingDetail {
  max-width: 300px;
  margin: 0 auto;
}

@media screen and (min-width: 1008px) {
  .plan {
    padding: 40px 20px 40px 60px;
    margin-bottom: 60px;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .listingDetail {
    max-width: 100%;
    margin-top: -50px;
  }

  .listing {
    justify-content: start;
    margin-bottom: 0;
    width: calc(33.33% - 20px);
  }

  .listings {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 750px;
  }

  .priceContent {
    margin-top: 0;
    width: 120px;
    flex: none;
  }

  .description {
    text-align: left;
  }

  .name {
    text-align: left;
    margin-bottom: 0;
  }
}
