.wrapper {
  justify-content: flex-start;
}

.table {
  border-collapse: separate;
  border-spacing: 0 25px;
  display: none;
}

.title {
  margin-bottom: 0;
}

.mb20 {
  margin-bottom: 20px;
}

.heading {
  text-align: left;
  border-spacing: 0;
}

.th {
  padding: 18px 32px;
  border-bottom: 1px solid var(--color-gray-250);
  border-top: 1px solid var(--color-gray-250);
}

.th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-left: 1px solid var(--color-gray-250);
}

.th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-right: 1px solid var(--color-gray-250);
}

.td {
  padding: 18px;
  background: var(--color-white-900);
}

.tableRow {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
}

.td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 18px 32px;
}

.indicator {
  padding: 8px 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-radius: 12px;
}

.completed {
  background-color: var(--color-yellow-100);
  color: var(--color-green-400);
}

.noAnswered {
  background-color: var(--color-red-100);
  color: var(--color-red-400);
}

.blocked {
  background-color: var(--color-gray-250);
  color: var(--color-gray-400);
}

.desktopEmpty {
  display: none;
  justify-content: center;
}

.callHistoryMobile {
  width: 100%;
}

.emptyMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-gray-500);
  width: 100%;
}

@media screen and (min-width: 1320px) {
  .callHistoryMobile {
    display: none;
  }

  .emptyMobile {
    display: none;
  }

  .table {
    display: table;
  }

  .desktopEmpty {
    display: flex;
    justify-content: center;
  }
}
