.wrapper {
  padding: 12px 20px;
  background-color: var(--color-white-900);
  max-width: 1220px;
  margin: 0 auto;
}

.memberBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  background-color: #eaeaea;
  border-radius: 15px;
  padding: 17px 15px;
  margin: 0 auto 20px auto;
}

.memberBoxActive {
  background-color: var(--color-white-900);
}

.memberBoxSponsor {
  border: 2px solid #5bafd7;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  background-color: var(--color-white-900);
}

.disableScroll {
  overflow: hidden;
}

.personal {
  display: flex;
}

.tabletPopup {
  color: var(--color-white-900);
  font-weight: 600;
  font-size: 14px;
  background-color: var(--color-black-500);
  line-height: 20px;
  border: 0;
  margin-top: 15px;
}

.tabletPopup:first-child {
  margin-top: 0;
}

.buttonClear {
  border: 0;
  padding: 0;
  background-color: inherit;
}

.textBox {
  display: flex;
  align-items: center;
}

.searchField {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}

.textBox,
.headButton {
  width: 100%;
  position: relative;
  min-width: 180px;
}

.headButton {
  display: none;
}

.popupLink,
.popupLinkLast {
  display: inline-block;
  color: var(--color-white-900);
  padding: 7px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  background: transparent;
  text-align: left;
  border: none;
  cursor: pointer;
}

.popupLink:hover,
.popupLink:focus,
.popupLinkLast:hover,
.popupLinkLast:focus {
  color: var(--color-white-900);
  text-decoration: underline;
  background: transparent;
}

.mobileLink {
  position: absolute;
  display: block;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  right: 20px;
  top: 90px;
}

.mobileLink:hover {
  color: var(--color-blue-700);
  text-decoration: underline;
}

.link {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  right: 20px;
  top: 75px;
}

.linkTable {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.headerBox {
  width: 100%;
  margin: 0 0 28px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  margin: 0;
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  position: relative;
  background: transparent;
  border-color: var(--color-white-900);
  border: none;
  cursor: pointer;
  margin: 0 auto;
}

.editButton:hover svg path {
  fill: var(--color-blue-800);
}

.membershipName {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-blue-800);
  margin-bottom: 10px;
}

.personalAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  width: 100%;
  height: 100%;
  max-width: 40px;
  max-height: 40px;
  color: var(--color-white-900);
  font-size: 10px;
  font-weight: 700;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  text-transform: uppercase;
  flex: none;
  margin-bottom: 10px;
  object-fit: cover;
}

.initials {
  background-color: #abafb9;
}

.personalAvatar svg {
  width: 20px;
}

.personalAvatarObject {
  opacity: 0.5;
}

.personalAvatarActive {
  background-color: var(--color-blue-800);
}

.personalAvatarObjectActive {
  opacity: 1;
}

.avatarImg {
  width: 100%;
  height: 100%;
}

.personalInfo {
  text-align: left;
  word-break: break-word;
}

.memberType {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.premium {
  color: var(--color-red-300);
}

.acc {
  display: flex;
  justify-content: space-between;
}

.accControls {
  display: flex;
  flex-direction: column;
  width: 120px;
}

.accInfo,
.accInfoField {
  font-size: 12px;
}

.accInfo {
  margin-right: 20px;
  text-align: left;
}

.accInfoField {
  font-weight: 600;
  line-height: 20px;
}

.accStatusBadge {
  position: relative;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 12px;
  padding: 8px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
}

.active {
  color: var(--color-green-400);
  background-color: var(--color-yellow-100);
}

.negative {
  color: var(--color-red-400);
  background-color: var(--color-red-100);
}

.personalInfoTablet {
  display: none;
}

.accControlsRenew {
  font-size: 12px !important;
  padding: 7px 0;
  width: 100%;
  border-radius: 6px;
  max-width: 125px;
  font-weight: 600;
  min-width: 125px;
}

.accStatus {
  display: none;
  position: absolute;
  top: -20px;
  left: 0;
  color: var(--color-gray-500);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 10px;
}

.controlMenuContent {
  position: absolute;
  right: 0;
  top: 60px;
}

.accWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table {
  width: 100%;
  display: none;
}

.hr {
  display: block;
  margin: 25px 0;
}

.relative {
  position: relative;
}

.label {
  display: block;
  margin-bottom: 30px;
}

.buttonJoin {
  width: 100%;
  padding: 12px 30px;
  white-space: nowrap;
  color: white;
  background: var(--color-blue-400);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.buttonJoin:hover {
  background-color: var(--color-blue-700);
}

.inboxSearch {
  padding: 11px 10px 11px 40px;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.inboxSearch::placeholder {
  color: #abafb9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.inboxSearch:hover {
  background-color: #fff;
}

.inboxSearch:disabled {
  pointer-events: none;
}

.dropdownTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}

.addLawButton {
  color: var(--color-blue-400);
  background-color: inherit;
  border: 1px solid var(--color-blue-400);
  width: 100%;
  margin-bottom: 52px;
}

.radiusTooltip {
  display: inline-block;
  width: 16px;
  margin-left: 10px;
}

.popupSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border: 1px solid var(--color-gray-250);
  border-radius: 6px;
  padding: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--color-white-900);
  margin-bottom: 32px;
}

.inputLabel {
  color: var(--color-gray-500);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  display: block;
  position: relative;
}

.bottomPadding {
  margin-bottom: 40px;
}

.dropdownHead {
  border-bottom: 0;
}

.textInfoMenu {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
}

.textInfos {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
}

.infoTooltip {
  position: absolute;
  right: 8px;
  top: 8px;
}

.menuTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.infoText {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.3px;
  color: var(--color-black-500);
}

.tableLink {
  display: block;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-top: 10px;
}

.tableLink:hover {
  color: var(--color-blue-700);
  text-decoration: underline;
}

.areaInfo {
  display: block;
  text-align: center;
}

.textarea {
  border: 1px solid var(--color-gray-250);
  border-radius: 6px;
  height: 400px;
  overflow-y: auto;
}

.infoMenu {
  background: var(--color-black-500);
  border-radius: 6px;
  width: 100%;
  position: relative;
  padding: 12px;
  margin-bottom: 12px;
  color: var(--color-white-900);
}

.subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 25px;
}

.addMemberBtn {
  min-width: 100%;
}

.headControls {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0 20px;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px;
}

.switchLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.switchWrp {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-top: 10px;
}

.switchOpt1 {
  margin-right: 20px;
}
.switchOpt2 {
  margin-left: 20px;
}

.popupHeading {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-250);
  margin: 0 0 25px;
}

.paymentOkWrp {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-top: 10%;
}

.paymentMoreInfo {
  font-size: 16px;
  margin: 20% 0;
}

.couponWrp {
  display: block;
}

.couponInput {
  margin-right: 20px;
  width: calc(100% - 140px) !important;
}

.paginationMargin {
  margin-bottom: 80px;
  padding-top: 10px;
}

.couponBtn {
  width: 120px;
}

.paymentOkMain {
  justify-content: space-between;
}

.whiteInfo {
  background-color: var(--color-white-900);
  border: 1px solid var(--color-gray-250);
  color: var(--color-blue-800);
}

.socialIcon {
  display: none;
}

.check {
  width: 75px;
  height: 75px;
}

.textWhiteMenu {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
}

.levelSwitchWrp {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.levelSwitchItem {
  margin-right: 20px;
}

.searchFieldButton {
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  left: 10px;
  top: 50%;
  cursor: pointer;
  margin: -12px 0 0 0;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchFieldButton svg {
  width: 20px;
}

.searchFieldButton:hover + input {
  background: var(--color-blue-300);
}

.searchFieldDisabled {
  pointer-events: none;
}

.searchFieldDisabled .searchFieldDisabled {
  pointer-events: none;
}

.paymentOption {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-black-500);
}

.oldPryce {
  color: red;
  text-decoration: line-through;
}

.membershipListingOverlay {
  position: fixed;
}

.membershipListingSpinner {
  position: fixed;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto 0;
  font-size: 16px;
  font-weight: 600;
}

.emptyState img {
  margin-bottom: 20px;
}

.linkToEdit {
  background: var(--color-gray-150);
  border-radius: 8px;
  display: block;
  padding: 10px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-blue-800);
  text-align: center;
}

.linkToEdit:hover {
  background: var(--color-blue-700);
  color: var(--color-white-900);
}

.trActive .tableRow,
.trActiveSponsor .tableRow {
  background: #ffffff;
}

.draft,
.time,
.draftMobile {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #8b8b8b;
  width: 90px;
  margin: 0 auto 0;
}

.draftMobile {
  margin: 0;
  width: 100%;
  text-align: left;
}

.couponError {
  color: var(--color-red-400);
  display: block;
  margin: 10px 0;
  font-size: 14px;
}

.content {
  display: none;
  width: 100%;
}

.contentBlock {
  margin-left: -40px;
}

.redStatus {
  color: var(--color-red-400);
  text-decoration: none;
  display: block;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterButton {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding: 11px 12px;
  width: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
}

.filterButton svg {
  width: 20px;
}

.pin {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  background: #2c2d64;
  border-radius: 50%;
}

.selectWrap {
  width: 100%;
}

.select > [class$="control"] div {
  color: #353546;
}

.select > [class$="control"] {
  min-height: 44px;
  max-height: 44px;
  border-color: #eaeaea;
  border-radius: 8px;
}

.select div[class$="ValueContainer"] {
  padding-left: 12px;
}

.addFreeListingButton {
  margin: 16px 0;
}

@media screen and (min-width: 768px) {
  .selectWrap {
    min-width: 166px;
    max-width: 166px;
  }

  .headButton {
    display: block;
    min-width: 180px;
  }

  .actions {
    gap: 16px;
  }

  .filterButton {
    display: none;
  }

  .controlMenuContent {
    position: initial;
    right: auto;
    bottom: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 8px;
    margin-right: -10px;
  }

  .time {
    width: 160px;
    text-align: right;
  }

  .contentBlock {
    margin-left: 0;
  }

  .draftMobile {
    text-align: right;
  }

  .textInfoMenu {
    display: inline-block;
    padding: 0 12px;
    margin-bottom: 0;
  }

  .textInfoMenu:after {
    content: "";
    border: 1px solid #686883;
    transform: rotate(90deg);
    left: 12px;
    position: relative;
  }

  .headerBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .socialIcon {
    display: block;
    margin-left: 40px;
  }

  .searchField {
    min-width: 350px;
    width: 100%;
    max-width: 762px;
    margin: 0;
  }

  .areaInfo {
    display: flex;
    justify-content: space-between;
  }

  .textBox {
    margin: 0;
  }

  .memberBox {
    padding: 25px 28px 28px;
  }

  .headControls {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    margin-bottom: 0;
  }

  .addMemberBtn {
    min-width: initial;
  }

  .personal {
    display: flex;
    width: 100%;
    flex: none;
  }

  .listingContent {
    margin-left: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .personalAvatar {
    max-width: 68px;
    max-height: 68px;
    width: 68px;
    font-size: 18px;
    margin-bottom: 0;
  }

  .memberBox {
    flex-direction: row;
  }

  .acc {
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
  }

  .personalInfoTablet {
    display: block;
    max-width: 100%;
    word-break: break-word;
    text-align: left;
    margin-bottom: 5px;
  }

  .accControls {
    flex-direction: row;
    order: -2;
    width: unset;
  }

  .accStatusBadge {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 16px;
    padding: 16px;
    width: unset;
    letter-spacing: 1px;
  }

  .accControlsRenew {
    order: -1;
    font-size: 14px;
    line-height: 20px;
    padding: 13px;
  }

  .accInfo,
  .accInfoField {
    font-size: 14px;
  }

  .accInfo {
    text-align: end;
    order: 1;
    margin: 0;
  }

  .accControlsWrapper {
    display: flex;
    flex-direction: column;
  }

  .accStatus {
    display: flex;
  }

  .memberType {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
  }

  .addFreeListingButton {
    display: none;
  }
}

@media screen and (min-width: 1008px) {
  .emptyWrapper {
    display: flex;
    align-items: flex-start;
  }

  .rightSide {
    width: 100%;
  }

  .time {
    width: 90px;
    text-align: center;
  }

  .paginationMargin {
    max-width: 905px;
    margin-right: auto;
  }

  aside.leftSide {
    max-width: 350px;
    margin-right: 55px;
    margin-left: 0;
    margin-top: 25px;
  }

  .wrapper {
    margin: 0 auto;
    padding: 24px 40px;
  }

  .popup {
    height: 100%;
  }

  .memberBox {
    display: none;
  }

  .table {
    display: table;
  }

  .personalAvatar {
    width: 68px;
    height: 68px;
    font-size: 18px;
    flex: none;
    margin-right: 15px;
  }

  .personalAvatarObject,
  .initials {
    margin-right: 15px;
  }

  .personal {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0 24px 32px;
  }
  .accStatusBadge {
    text-align: center;
  }

  .accStatusBadgeDraft {
    margin-bottom: 30px;
  }

  .accControlsRenew {
    padding: 7px 15px;
    max-width: 100px;
    min-width: 100px;
  }

  .memberType {
    margin: 0 0 10px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .accInfo {
    text-align: left;
  }

  .th {
    text-align: left;
    text-transform: uppercase;
    border-top: 1px solid var(--color-gray-250);
    border-bottom: 1px solid var(--color-gray-250);
    padding: 20px;
  }

  .th:first-child {
    border: 1px solid var(--color-gray-250);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-right: none;
    padding-left: 30px;
  }

  .th:last-child {
    border: 1px solid var(--color-gray-250);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left: none;
    text-align: center;
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 25px;
  }

  .tr {
    padding: 20px 30px;
    border-radius: 12px;
    box-shadow: var(--box-shadow-300);
  }

  .tr:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .td {
    padding: 0 20px;
  }

  .tableRow {
    background: #eaeaea;
  }

  .tableRow:first-child {
    width: 550px;
    border-radius: 12px 0 0 12px;
  }

  .trActiveSponsor .tableRow {
    border: 2px solid #5bafd7;
    border-left: none;
    border-right: none;
  }

  .trActiveSponsor .tableRow:first-child {
    border-left: 2px solid #5bafd7;
  }

  .trActiveSponsor .tableRow:last-child {
    border-right: 2px solid #5bafd7;
  }

  .tableRow:last-child {
    border-radius: 0 12px 12px 0;
  }

  .tableRow:last-child {
    width: 150px;
    text-align: center;
  }

  .tableRowRelative {
    position: relative;
    padding: 24px 0;
    vertical-align: top;
  }

  .draftWarning {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    font-weight: 700;
    white-space: nowrap;
  }

  .draftWarningIcon {
    margin-right: 5px;
  }

  .td:last-child {
    text-align: center;
  }

  .prompt {
    top: 0;
    right: 70%;
    transform: none;
  }

  .prompt:after {
    right: 2px;
    top: 24px;
  }
  .editButton {
    margin: 3px auto 0;
  }

  .content {
    display: flex;
  }

  .headerBox {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 1320px) {
  .actions {
    gap: 16px;
  }

  aside.leftSide {
    margin-left: 0;
    max-width: 400px;
    margin-right: 55px;
  }

  .filterType {
    margin-bottom: 40px;
  }

  .team {
    width: 156px;
    color: #353546;
  }

  .team [class$="control"] div {
    color: #353546;
  }

  .team [class$="control"] {
    width: 156px;
    border-color: #eaeaea;
    border-radius: 8px;
  }

  .team > div {
    min-width: 156px;
  }
}
