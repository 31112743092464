.main {
  padding: 24px 0;
  background-color: #fff;
  max-width: 728px;
  margin: 0 auto;
  width: 100%;
}
.input {
  padding: 13px 10px 13px 40px;
  border-radius: 8px;
  border: 1px solid #d7d2d2;
  background: #fff;
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.input::placeholder {
  color: #abafb9;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.inputWrap {
  position: relative;
  margin-bottom: 8px;
}
.inputWrapModal {
  margin-bottom: 24px;
}
.searchIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
}
.select {
  min-height: 48px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d7d2d2;
  color: #1c1d40;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
}
.select svg {
  transform: rotate(90deg);
}
.button {
  padding: 11px 12px;
  text-align: center;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}
.overflow {
  position: relative;
  overflow-x: auto;
}
.listings {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
  padding-bottom: 8px;
}
.listingType {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 7px 12px;
  border-radius: 6px;
  border: 1px solid #d7d2d2;
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  background-color: #fff;
}
.listingType svg {
  transform: rotate(90deg);
  width: 12px;
  height: 12px;
}
.listingType path {
  fill: #353546;
}

main.modal {
  width: 100%;
  max-width: 1008px;
  height: 100vh;
  padding-top: 80px;
}

.title {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -55px;
  padding-right: 40px;
  margin-bottom: 40px;
}

.selectParent,
.viewAll {
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  color: #5bafd7;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.viewAll svg {
  transform: rotate(180deg);
}

.viewAll path {
  fill: #5bafd7;
}

.category {
  margin-bottom: 24px;
  border-bottom: 1px solid #d7d2d2;
}
.categoryListing {
  margin-bottom: 0;
  border-bottom: none;
}
.category:last-child {
  border-bottom: 0;
}
.categoryHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.categoryTitle {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}
.categoryItem {
  color: #353546;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 16px;
  display: block;
  cursor: pointer;
}

.categoryItemDisable {
  font-weight: 700;
}

.categoryItemBold {
  font-weight: 700;
}

.categoryItemProfession {
  text-align: left;
}

.viewAllTabletShow {
  display: none;
}

@media screen and (min-width: 768px) {
  .flex {
    display: flex;
  }
  .inputWrap {
    margin-bottom: 0;
    width: 275px;
    flex: none;
    border-right: none;
    border-radius: 0;
  }
  .input {
    border-right: none;
    border-radius: 0;
  }
  .inputModal {
    border-right: 1px solid #d7d2d2;
    border-radius: 8px;
    padding: 18px 18px 18px 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .inputModal::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .inputWrapModal {
    width: 100%;
    margin-bottom: 28px;
  }
  .inputWrapModal .searchIcon {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }
  .button {
    width: 114px;
    flex: none;
  }
  .select {
    border-radius: 8px 0 0 8px;
    margin-bottom: 0;
    width: 275px;
    flex: none;
  }
  .button {
    border-radius: 0 8px 8px 0;
  }
  .listingType {
    border: none;
  }
  main.modal {
    left: 50%;
    margin-left: 0;
    transform: translate(-50%, -50%);
    height: 530px;
  }
  .categories {
    display: flex;
    flex: 1 2 0;
  }

  .viewAllTabletShow {
    display: block;
  }

  .viewAllTabletHide {
    display: none;
  }

  .category {
    width: 25%;
    border-bottom: 0;
  }

  .categoryTitle {
    border-bottom: 1px solid #d7d2d2;
    padding-bottom: 16px;
  }

  .categoryActive,
  .categoryListing {
    width: 100%;
    align-content: baseline;
  }

  .categoryActive .categoryItem,
  .categoryListing .categoryItem {
    text-align: left;
  }

  .categoryItem {
    padding-right: 20px;
  }

  .categoryListingColumn {
    column-count: 3;
    margin-bottom: -10px;
    display: block;
  }

  .categoryListingSearch,
  .categoryListingSearchContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 300px;
    margin-bottom: -10px;
    max-height: 400px;
  }

  .categoryListingSearchContent {
    max-height: 360px;
  }

  .categoryListingSearch .categoryItem,
  .categoryListingSearchContent .categoryItem {
    width: 33.33%;
  }
}

@media screen and (min-width: 1248px) {
  .main {
    padding: 0;
    order: -1;
    max-width: 590px;
  }

  .viewAll {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
  }

  .viewAll svg {
    width: 24px;
    height: 24px;
  }

  .inputWrap {
    width: 238px;
  }
  .select {
    width: 238px;
  }
  main.modal {
    width: 1015px;
    max-width: 100%;
    padding: 80px 40px 40px;
  }
  .inputWrapModal {
    width: 100%;
  }
}
