.payment {
  background: inherit;
  margin-bottom: 20px;
  position: relative;
}

.billings {
  max-width: 1130px;
}

.pagination {
  margin-bottom: 60px;
  padding-top: 10px;
}

.tableHead {
  display: table-row;
  background: var(--color-gray-150);
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.3px;
  color: var(--color-black-500);
}

.active:hover {
  color: var(--color-blue-700);
  text-decoration: underline;
}

.tableRow {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-gray-250);
  padding-bottom: 12px;
}

.tableHead {
  display: none;
}

.tableCell {
  padding: 0 0 24px;
  display: flex;
  align-items: center;

  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.tableCell.active {
  color: #5bafd7;
}

.tableCell .paymentTitle {
  width: 92px;
  flex: none;
  text-align: left;
}

.tableCell:last-child {
  border-top-right-radius: 12px;
}

.paymentBold {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  margin-bottom: 24px;
}

.paymentTitle {
  color: #353546;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
}

.paymentSubtitle {
  margin-bottom: 24px;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.billingWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 24px;
}

.link {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .billings {
    padding: 0 0 20px 0;
    border-radius: 12px;
    width: 100%;
  }

  .billingWrapper {
    margin: 10px 0 0 0;
  }

  .pseudoBorder {
    display: block;
    border-top: 1px solid black;
    margin: 25px -20px 0 -20px;
  }

  .paymentTitle {
    display: none;
  }

  .pagination {
    margin: 0;
  }

  .tableRow {
    position: relative;
    display: table-row;
    border-bottom: 1px solid var(--color-gray-250);
    padding: 0;
    box-shadow: none;
  }

  .tableRow:last-child {
    border-bottom: 0;
  }

  .tableCell {
    padding: 18px 20px;
    display: table-cell;
    vertical-align: middle;
  }

  .tableBorder {
    border-bottom: 1px solid var(--color-gray-250);
  }

  .tableCell:first-child {
    padding: 18px 20px;
    border-top-left-radius: 12px;
  }

  .tableRow:last-child {
    margin: 30px;
  }

  .tableCell:last-child {
    padding: 0 20px;
  }

  .payment {
    display: table;
    width: 100%;
    background: var(--color-white-900);
    box-shadow: none;
    border-radius: 12px;
    margin: 0 0 32px 0;
  }

  .tableHead {
    display: table-header-group;
  }

  .paymentAmount {
    white-space: nowrap;
  }

  .tableCellHead {
    color: #353546;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .tableCellHead:first-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  button.link:last-child {
    background-color: transparent;
    cursor: pointer;
    padding: 18px 20px;
    margin: 0;
  }
}

@media screen and (min-width: 1008px) {
  .billings {
    padding: 0;
    border-radius: 12px;
  }

  .payment {
    display: table;
    width: 100%;
    background: var(--color-white-900);
    border-radius: 12px;
    max-width: 100%;
    margin: 0 auto 32px auto;
  }

  .tableCell:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .tableCell:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .link {
    background-color: transparent;
    cursor: pointer;
    padding: 18px 20px;
    margin: 0;
  }
}
