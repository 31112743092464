.professions {
  width: 100%;
  margin-bottom: 80px;
}

.professionsItem {
  width: 304px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #f4f5f6;
  gap: 12px;
  border: none;
  padding: 20px;
  margin: 0 auto 16px auto;
  cursor: pointer;
}

.professionsText {
  color: #2c2d64;
  font-size: 20px;
  font-weight: 500;
  line-height: 35.702px;
}

@media screen and (min-width: 768px) {
  .professionsItem {
    margin: 0;
  }

  .professions {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1008px) {
  .professions {
    margin-bottom: 112px;
  }
}
