.counterText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1c1d40;
}

.counterText span {
  color: #8b8b8b;
  font-weight: 500;
  padding-left: 4px;
}

.counterContent {
  margin-bottom: 45px;
}
