.wrapper {
  width: 100%;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  margin-bottom: 20px;
}

.group {
  display: block;
}

.total {
  font-weight: 800;
  font-size: 44px;
  line-height: 44px;
  color: #1c1d40;
  margin-bottom: 20px;
}

.subTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #353546;
  margin: 0;
}

.button {
  width: 100%;
  display: block;
  border-radius: 0;
}

.modal {
  max-width: 480px;
}

.modalTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.hr {
  display: block;
  margin-bottom: 20px;
}

.itemGroup {
  margin-bottom: 35px;
}

.footer {
  margin: 25px -40px 0;
}
