.inputContainer {
  margin: 0 0 16px 0;
}

.input {
  padding: 11px 12px;
}

@media screen and (min-width: 768px) {
  .inputContainer {
    margin: 0;
    width: 100%;
  }

  .content {
    gap: 32px;
    display: flex;
    margin-bottom: 24px;
  }
}
