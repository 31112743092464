.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: rgba(9, 10, 40, 0.8);
  margin: 0;
  display: flex;
  align-items: flex-start;
}

.titleRequired {
  color: #da4655;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 24px;
    line-height: 34px;
  }
}
