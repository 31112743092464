.notification-page {
  padding-bottom: 60px;
}

.title__text {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-blue-800);
}

.setting-button {
  border: 0;
  background-color: inherit;
}

.title--notification {
  align-items: center;
}

.notification__alert-icon {
  width: 20px;
  height: 20px;
  position: absolute;
}

.notification {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}

.notification__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-blue-800);
}

.time-icon {
  position: absolute;
}

.notification__time {
  margin-bottom: 15px;
  min-width: 108px;
  position: relative;
}

.notification__time-text,
.time-text {
  padding: 3px 0 0 33px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.3px;
  color: var(--color-black-500);
  font-weight: bold;
  margin-bottom: 0;
}

.notification__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  margin-bottom: 16px;
}

.button--view {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 9px;
  border: 1.4px solid #5bafd7;
  width: 100%;
  color: #5bafd7;
  display: flex;
  justify-content: center;
}

.notification__lawyer-text {
  margin-right: 70px;
}

@media screen and (min-width: 768px) {
  .notification__title-flex {
    display: flex;
    justify-content: space-between;
  }

  .notification__title--icon {
    padding-left: 32px;
  }

  .notification {
    padding: 24px 20px;
  }

  .notification__time-text {
    width: auto;
  }

  .notification__main-flex {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .notification__text {
    width: auto;
    display: inline-block;
    margin-right: 28px;
    margin-bottom: 0;
  }

  .button--view {
    min-width: 108px;
    width: auto;
    max-height: 52px;
  }
}

@media screen and (min-width: 1008px) {
  .notification__title-flex {
    display: inline-block;
    min-width: 300px;
    margin-right: 35px;
  }
  .notification {
    display: flex;
    align-items: center;
  }

  .button--view {
    padding: 17px;
    border: 1.4px solid #5bafd7;
    min-width: 126px;
    color: #5bafd7;
  }
}
