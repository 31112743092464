.answersCounter {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
}

.answersGrey {
  color: var(--color-gray-500);
}

@media screen and (min-width: 768px) {
  .answersCounter {
    font-size: 24px;
    line-height: 34px;
    margin-right: auto;
  }
}
