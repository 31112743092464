.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
}

.tile {
  padding: 12px 0;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0;
}

.tile:first-child {
  padding-top: 0;
}

.tile:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.title {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
}

.tileTitle {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}

.tileDescription {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.tileStatus {
  border-radius: 4px;
  background: #2c2d64;
  padding: 6px 22px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: inline-block;
  margin: 16px 0 0 0;
}

.flex {
  display: flex;
  gap: 16px;
}

.tileLogo {
  width: 44px;
  height: 44px;
}

.tileLogo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .tile {
    padding: 24px 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .tileStatus {
    margin-top: 0;
  }

  .tile:first-child {
    padding-top: 0;
  }

  .tile:last-child {
    padding-bottom: 0;
  }
}
