.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 8px;
}

.asterisk {
  color: var(--color-red-400);
  margin: 0 2px;
}

.input {
  border: 1px solid #d7d2d2;
  border-radius: 6px;
  padding: 15px 20px;
  font-size: 14px;
}

.invalid {
  border-color: var(--color-red-400);
  background-color: var(--color-red-100);
}

.invalid:focus,
.invalid:active {
  border-color: #da465582;
  background-color: #f9e1e491;
}

.invalid:hover {
  background-color: #f9e1e491;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  margin: 5px 0 -10px;
}
