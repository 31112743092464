.mainLogo {
  width: 170px;
}

.logoLink {
  padding: 0 20px;
}

@media screen and (min-width: 1008px) {
  .logoLink {
    padding-left: 0;
  }
}
