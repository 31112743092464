.content {
  margin: 40px 0 0 0;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #1c1d40;
  display: block;
  margin: 0 0 24px 0;
}

.item {
  margin-bottom: 24px;
}

.itemContent {
  background-color: #fff;
  padding: 24px 12px;
}

.itemImage {
  max-width: 320px;
  height: 120px;
  border-radius: 12px 12px 0 0;
  margin: 0 auto;
  flex: none;
  text-align: center;
}

.itemImage img {
  max-width: 100%;
  max-height: 100%;
}

.itemName {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #1c1d40;
  display: block;
  margin-bottom: 16px;
}

.itemDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #353546;
  margin-bottom: 16px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.itemAuthor {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #1c1d40;
}

@media screen and (min-width: 768px) {
  .content {
    margin: 44px 0 0 0;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 32px;
  }

  .item {
    display: flex;
    gap: 24px;
    margin-bottom: 40px;
  }

  .itemImage {
    width: 270px;
    height: 210px;
    border-radius: 12px;
    overflow: hidden;
    margin: 0;
    text-align: left;
  }

  .itemContent {
    background-color: transparent;
    padding: 0;
  }

  .itemName {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .itemDescription {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .itemAuthor {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

@media screen and (min-width: 1320px) {
  .content {
    margin: 80px 0 0 0;
  }

  .title {
    margin-bottom: 40px;
  }

  .item {
  }

  .itemImage {
    max-width: 329px;
    width: 329px;
    height: 248px;
  }
}
