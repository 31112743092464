.preloader {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloaderAbsolute {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sectionOverlayAbsolute {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(9, 10, 40, 0.3);
  overflow: hidden;
  z-index: 3;
  border-radius: 6px;
}
