.tile {
  position: relative;
  max-width: 320px;
  margin: 0 auto 16px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.14);
  height: 369px;
}

.tileExpand {
  max-width: 100%;
  margin-bottom: 16px;
}

.favorites {
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.favoritesButton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f8f8fa;
  box-shadow: 0 8px 24px 0 rgba(44, 44, 44, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
}

.favoritesButton:hover {
  background: #f8f8fa;
}

.favoritesButton svg {
  width: 24px;
  height: 24px;
}

.favoritesButton path {
  stroke: #abafb9;
}

.favoritesButtonActive svg {
  fill: #5bafd7;
}

.favoritesButtonActive path {
  stroke: #5bafd7;
}

.tileImage {
  width: 100%;
  height: 200px;
  display: block;
}

.tileImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.titleContent {
  padding: 12px;
}

.tileTitle {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
  cursor: pointer;
}

.tileTitle:hover {
  text-decoration: underline;
}

.tileDescription {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}

.titleDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}

.titleDetail:last-child {
  margin-bottom: 0;
}

.titleDetail svg {
  width: 20px;
  height: 20px;
  fill: #8b8b8b;
}

.flexExpand .tile {
  height: auto;
}

@media screen and (min-width: 768px) {
  .tileTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    padding-right: 50px;
  }

  .titleFlex {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .titleDetail {
    margin-bottom: 0;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
  }

  .flexExpand {
    display: block;
  }

  .flexExpand .tile {
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  .tile {
    margin: 0;
    max-width: 50%;
    flex: 0 1 calc(50% - 12px);
  }

  .tileExpand {
    max-width: 100%;
    margin-bottom: 24px;
  }

  .tileExpand .tileImage {
    height: 232px;
  }

  .expandContainer {
    display: flex;
  }

  .expandContainer .tileImage {
    width: 260px;
    height: 250px;
    flex: none;
  }

  .expandContainer .tileTitle {
    color: #1c1d40;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 28px;
  }
  .expandContainer .tileDescription {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 24px;
  }

  .tileDescription {
    line-height: 24px;
  }
}

@media screen and (min-width: 1320px) {
  .tile {
    flex: 1 1 calc(33.33333% - 16px);
    max-width: calc(33.33333% - 16px);
  }

  .favoritesButton:hover {
    background: #2c2d64;
  }
}
