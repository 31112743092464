.mainBox {
  max-width: 1440px;
  padding: 0 20px;
  margin: 30px auto 0;
}

.max {
  width: 100%;
  max-width: 905px;
  margin: 0 auto;
}

.lead {
  position: relative;
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 20px;
  margin: 0 auto 20px auto;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  display: block;
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .lead {
    padding: 40px 60px;
  }
}

@media screen and (min-width: 1008px) {
  .mainBox {
    display: flex;
    margin: 40px auto 0;
    padding: 0 40px;
  }

  .sidebar {
    margin-top: 75px;
  }
  .lead {
    padding: 40px 80px;
  }
}
