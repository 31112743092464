.input {
  padding: 10px 17px 10px 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 10px;
}

.wrap {
  position: relative;
  margin-bottom: 16px;
}
