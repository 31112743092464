.modal {
  padding: 24px 20px;
  max-width: 480px;
}

.title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 32px;
}

.label {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-bottom: 8px;
}

.label span {
  color: #da4655;
}

.submit {
  margin-top: 16px;
  padding: 12px;
  border: none;
  line-height: 20px;
  font-weight: 700;
  border-radius: 8px;
}

.formContent {
  margin-bottom: 16px;
  position: relative;
}

.input {
  padding: 9px 12px;
}

div .calendarInput {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding: 9px 10px 9px 40px;
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div .calendarInput svg path {
  fill: #abafb9;
}

div.location {
  margin-bottom: 16px;
}

.select [class$="control"] {
  min-height: 40px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.closeButton {
  display: none;
}

@media screen and (min-width: 768px) {
  .modal {
    padding: 24px;
  }
}
