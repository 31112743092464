.linkWrp {
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: #1c1d40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  align-self: center;
}

.linkWrpDisable {
  pointer-events: none;
  color: #abafb9;
}

.linkRowWrp {
  padding: 0 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: var(--color-white-900);
  border: 1px solid var(--color-gray-250);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.paginationItem {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  color: #1c1d40;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
}

.paginationItemActive {
  background: var(--color-blue-400);
  color: var(--color-white-900);
  border-color: var(--color-blue-400);
}

.icon {
  height: 10px;
}

.perPageContent {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
}

.perPageText {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.select > [class$="control"] {
  min-height: 34px;
  height: 36px;
  width: 81px;
  border-color: #eaeaea;
}

.select svg {
  fill: #090a28;
}
