.rdw-editor-wrapper .rdw-block-wrapper,
.rdw-editor-wrapper .rdw-fontfamily-wrapper,
.rdw-editor-wrapper .rdw-colorpicker-wrapper,
.rdw-editor-wrapper .rdw-link-wrapper,
.rdw-editor-wrapper .rdw-embedded-wrapper,
.rdw-editor-wrapper .rdw-emoji-wrapper,
.rdw-editor-wrapper .rdw-image-wrapper,
.rdw-editor-wrapper .rdw-remove-wrapper,
.rdw-editor-wrapper .rdw-history-wrapper,
.rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Monospace"],
.rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Superscript"],
.rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Subscript"],
.rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Strikethrough"],
.rdw-editor-wrapper .rdw-list-wrapper,
.rdw-editor-wrapper .rdw-fontsize-wrapper {
  display: none;
}

div.rdw-editor-toolbar {
  justify-content: flex-end;
  border: none;
  gap: 12px;
}

.rdw-editor-toolbar .rdw-option-wrapper {
  height: 27px;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
}

.rdw-editor-toolbar .rdw-option-wrapper:hover {
  outline: none;
  box-shadow: none;
  background: #f2fafd;
  border-radius: 4px;
}

.rdw-editor-toolbar .rdw-option-active {
  background: #f2fafd;
  border-radius: 4px;
}

.rdw-text-align-wrapper {
  gap: 12px;
}

.rdw-inline-wrapper {
  gap: 12px;
}

.rdw-inline-wrapper {
  padding-right: 12px;
  border-right: 2px solid #eaeaea;
}

.editor-class.rdw-editor-main {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
}

.editor-class_error.rdw-editor-main {
  border-color: #da4655;
}

.editor-class .public-DraftStyleDefault-block {
  margin: 0;
}

.editor-alignment-right > div {
  text-align: right;
}

.editor-alignment-left > div {
  text-align: left;
}

.editor-alignment-center > div {
  text-align: center;
}

.editor-alignment-justify > div {
  text-align: justify;
}
