.colorInfo {
  border-radius: 12px;
  padding: 15px;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 24px;
  letter-spacing: 0.04em;
}

.caseHead {
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeaea;
}

.textPendingReview {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  text-align: center;
  margin: 0;
}

.textMissing {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #da4655;
  text-align: center;
  margin: 0;
}

.textRejected {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #da4655;
  text-align: center;
}

.reason {
  color: #da4655;
}

.rejectedDetail {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #da4655;
  display: block;
  margin-bottom: 24px;
  text-align: center;
}

.textMatch {
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  text-align: center;
  margin: 0;
}

.matches {
  background: var(--color-yellow-100);
  color: var(--color-green-400);
}

.textBold {
  font-weight: 600;
  color: var(--color-black-500);
  text-align: center;
}

.textRed {
  color: var(--color-red-400);
  font-weight: 600;
  font-size: 14px;
}

.matchPreview {
  background: var(--color-black-500);
  border-radius: 12px;
  color: var(--color-white-900);
  display: flex;
  padding: 20px 25px;
  margin-bottom: 24px;
  cursor: pointer;
}

.noMatchDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #353546;
}

.noMatchTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #ac4b77;
  margin-bottom: 4px;
}

.noMatch {
  display: flex;
  gap: 18px;
  align-items: flex-start;
}

.icon {
  width: 32px;
  height: 32px;
}

@media screen and (min-width: 768px) {
  .matchPhoto {
    width: 36px;
    height: 36px;
  }

  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
