.practiceDropdown {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #eaeaea;
  height: 52px;
}

.practiceContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: auto;
}

.practiceItem {
  background: #f4f5f6;
  padding: 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #1c1d40;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: none;
  justify-content: space-between;
}

.searchIcon {
  margin-right: 10px;
}

.practiceItem svg {
  width: 8px;
  height: 8px;
}

.practiceItem path {
  fill: #1c1d40;
}

.headerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.editBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  padding: 0;
  margin-left: 5px;
  position: relative;
  background: var(--color-white-900);
  border-color: var(--color-white-900);
}

.content {
  margin-bottom: 32px;
}

.editBtn svg {
  width: 14px;
  stroke: var(--color-blue-800);
}

.editBtn:hover {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
}

.flexbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.flexboxIndentChild .flexItem {
  margin-bottom: 30px;
}

.flexboxIndentChild .flexItem:nth-last-child(2) {
  margin-bottom: 0;
}

.flexItem {
  flex: 0 0 55%;
}

.flexItem:nth-child(2n) {
  flex: 0 0 45%;
}

.iconSetting {
  width: 20px;
  height: 20px;
}

.flexboxRightBox {
  min-width: 30%;
}

.settingsBtn {
  position: absolute;
  top: -10px;
  right: 0;
}

.penIcon {
  width: 13px;
}

.hr {
  display: block;
  margin: 40px;
  min-width: auto;
}

.hrFullWidth {
  display: block;
  margin: 40px 0;
  min-width: auto;
}

.mt30 {
  margin-top: 30px;
}

.label {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0;
  color: #353546;
}

.contentItem {
  margin-bottom: 32px;
}

.textarea {
  min-height: 164px;
  max-height: 164px;
}

.textContent,
.profileLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  margin: 0 0 10px;
}

.profileLink {
  text-decoration: underline;
  color: #5bafd7;
}

.profileLink:hover {
  text-decoration: none;
}

.mb8 {
  margin-bottom: 8px;
}

.mb13 {
  margin-bottom: 13px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb0 {
  margin-bottom: 0;
}

.textContentBold {
  font-weight: 600;
}

.errorEmail {
  color: var(--color-red-400);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  margin-top: 10px;
}

.editContactInfo {
  position: absolute;
  right: 0;
  top: 0;
}

.relative {
  position: relative;
}

.heading {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 16px 0;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 40px;
}

.headingItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headingLabel {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #abafb9;
  text-transform: uppercase;
}

.headingValue {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #5bafd7;
  margin-bottom: 0;
}

.form {
  margin-bottom: 32px;
}

.input {
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border: 1px solid #eaeaea;
  background: #fff;
  border-radius: 4px;
  color: #353546;
  width: 100%;
  display: block;
}

.formLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #353546;
  display: block;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.formItem {
  margin-bottom: 16px;
}

.formItem:last-child {
  margin-bottom: 0;
}

.flexHeading {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .form {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
  }

  .formItem {
    width: 100%;
    margin-bottom: 0;
  }
}
