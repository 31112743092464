.wrapper {
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: var(--color-blue-800);
  display: flex;
  align-items: center;
}

.group {
  display: block;
}

.checkIcon {
  width: 35px;
  display: block;
  margin-right: 15px;
  flex: none;
}

.total {
  font-weight: 800;
  font-size: 44px;
  line-height: 64px;
  color: #1c1d40;
  margin-bottom: 10px;
}

.subTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #353546;
}

.modal {
  max-width: 480px;
}

.modalTitle {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #8b8b8b;
  margin-bottom: 32px;
}

.hr {
  display: block;
  margin-bottom: 30px;
}

.itemGroup {
  margin-bottom: 50px;
}
