.content {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;
}

.tab {
  outline: none;
  background-color: inherit;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid transparent;
  color: #8b8b8b;
  padding: 7px 12px;
}

.tabActive {
  border-color: var(--color-blue-400);
  color: var(--color-blue-800);
}
