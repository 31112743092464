.nav {
  background: var(--color-white-900);
  border-radius: 4px;
  width: 240px;
  position: absolute;
  padding: 12px 0;
  right: -22px;
  z-index: 6;
  top: 55px;
  outline: none;
  display: block;
}

.link,
.dropdown {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  padding: 10px 24px 10px 24px;
  background-color: #fff;
  text-decoration: none;
  margin: 0;
  color: #61616f;
  display: block;
  cursor: pointer;
}

.dropdown {
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown path {
  fill: #61616f;
}

.link:hover,
.dropdown:hover {
  background-color: var(--color-blue-300);
}

.dropdownActive {
  color: #5bafd7;
}

.dropdownActive path {
  fill: #5bafd7;
}

.dropdownActive:hover {
  background: #fff;
}

.hr {
  display: block;
  margin: 10px 0 20px 0;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 70px;
  display: block;
  background: rgba(9, 10, 40, 0.08);
  z-index: 2;
  outline: none;
}

.logout {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  padding: 10px 10px 10px 24px;
  background-color: #fff;
  text-decoration: none;
  margin: 0;
  color: #61616f;
  gap: 8px;
  display: flex;
  align-items: center;
  width: 100%;
}

.indent {
  width: 100%;
  display: block;
  margin: 8px 0;
  background: #abafb9;
  height: 1px;
}

.logout:hover {
  background: #e8f7fc;
}

.logout svg {
  fill: #abafb9;
}

.icon {
  width: 16px;
  height: 16px;
}

.iconActive {
  transform: rotate(180deg);
}

.tool {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  color: var(--color-black-500);
  position: relative;
}

.text {
  margin: 0 10px 10px 0;
}

.img {
  width: 16px;
  padding-top: 3px;
}

@media screen and (min-width: 1008px) {
  .background {
    top: 114px;
  }

  .nav {
    top: 85px;
  }
}
