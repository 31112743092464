.label {
  color: #8b8b8b;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.11px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
}

.title {
  color: #1c1d40;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  display: block;
  padding-bottom: 24px;
  margin-bottom: 32px;
  border-bottom: 1px solid #eaeaea;
}

.confirmation {
  color: #1c1d40;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.successIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
}

.successIcon svg {
  width: 84px;
  height: 84px;
}
