.content {
  margin-bottom: 44px;
}

@media screen and (min-width: 768px) {
  .content {
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 1008px) {
  .content {
    margin-bottom: 80px;
  }
}
