.messageItem {
  padding: 12px 20px 12px 12px;
  cursor: pointer;
  position: relative;
}

.tile {
  width: 100%;
}

.content {
  display: flex;
  gap: 12px;
}

.initials {
  background: #353546;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.topContent {
  display: flex;
  justify-content: space-between;
}

.name {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.date {
  color: #abafb9;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.unreadCount {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  flex: none;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  background-color: #ac4b77;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 20px;
}

.message {
  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: block;
  margin-bottom: 0;
  overflow: hidden;
  height: 16px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  padding-right: 50px;
}

.listing {
  color: #5bafd7;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
}

.messageItemActive {
  background-color: #ac4b77;
}

.messageItemActive .name,
.messageItemActive .date,
.messageItemActive .message,
.messageItemActive .listing {
  color: #fff;
}

.messageItemActive .initials {
  background: #eaeaea !important;
  color: #2c2d64 !important;
}
