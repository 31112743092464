.textarea {
  display: block;
  background-color: #fff;
  min-height: 152px;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-height: 310px;
  resize: vertical;
}

.textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.info {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #8b8b8b;
  display: block;
  margin-top: 16px;
}

.textareaError {
  border-color: var(--color-red-400);
}

.infoActive {
  color: var(--color-red-400);
}

@media screen and (min-width: 768px) {
  .info {
    margin-top: 28px;
  }
}
