.value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  margin: 0;
  outline: none;
  padding-right: 5px;
  text-transform: capitalize;
}

.stars {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #8b8b8b;
  margin: 0;
}
