.paymentOkMain {
  justify-content: space-between;
}

.popupHeading {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-250);
  margin: 0 0 25px;
}

.listing {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.listingInitials {
  width: 68px;
  height: 68px;
  background: #1c1d40;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  text-transform: uppercase;
  margin-right: 16px;
}

.listingType {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #1c1d40;
  display: block;
  margin-bottom: 10px;
}

.listingName {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #8b8b8b;
  text-transform: capitalize;
}

.description {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 24px;
}

.deleteDescription {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin: 24px 0 0 0;
}

.buttonControls {
  display: flex;
  gap: 65px;
  padding-top: 40px;
  margin-bottom: 60px;
  border-top: 1px solid #eaeaea;
}

.cancelDowngrade,
.downgradeNow {
  width: 50%;
}

.downgradeNow {
  background: #eaeaea;
  color: #8b8b8b;
  border-color: #eaeaea;
}
