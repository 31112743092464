.question {
  font-size: 14px;
  line-height: 20px;
  display: block;
  color: var(--color-black-500);
  margin-bottom: 5px;
}

.answerBox {
  font-size: 14px;
  line-height: 20px;
}

.myAnswer {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  margin-bottom: 5px;
  display: inline-block;
}

.answerTextSimple {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  color: var(--color-black-500);
  margin-bottom: 0;
  margin-left: 5px;
}

.hr {
  display: block;
  margin: 40px 0;
  min-width: auto;
}

.editBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  padding: 0;
  margin-left: 5px;
  position: relative;
  background: var(--color-white-900);
  border-color: var(--color-white-900);
}

.editBtn svg {
  width: 14px;
  stroke: var(--color-blue-800);
}

.editBtn:hover {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
}

.penIcon {
  width: 13px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
