.content {
  margin: 20px 0 40px 0;
}

.title {
  color: #353546;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 4px;
}

.description {
  color: #8b8b8b;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin-bottom: 24px;
}
