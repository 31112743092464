.popupHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--color-gray-250);
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 8px 0;
}

.subtitle {
  color: var(--color-gray-500);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.edit {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.edit svg {
  width: 24px;
  height: 24px;
}

.edit:hover {
  background-color: rgba(234, 234, 234, 0.5);
}
