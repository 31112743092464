.modalControl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 0;
}

.exitButton {
  height: 40px;
  width: 100px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5bafd7;
  border-radius: 8px;
  background-color: transparent;
  flex: none;
  color: #5bafd7;
}

.confirmButton {
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #5bafd7;
  flex: none;
  min-width: 100px;
}

.exitButton:hover {
  background-color: #1c1d40;
  color: #ffffff;
  border-color: #1c1d40;
}
