.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-top: 16px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
  color: #1c1d40;
}

.website {
  color: #61616f;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-bottom: 16px;
}

.address {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #61616f;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 40px 24px;
    margin-top: 24px;
  }

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 32px;
  }

  .website {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1320px) {
  .content {
    padding: 40px 50px;
  }
}
