.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 24px;
  margin-top: -18px;
}

.inputWrap {
  margin-bottom: 24px;
}

.footer {
  display: flex;
  margin: 32px -40px 0;
}

.save,
.close {
  width: 50%;
  flex: none;
  border-radius: 0;
}

.close {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: #eaeaea;
  border: none;
  color: #8b8b8b;
  border-top: 1px solid #d7d2d2;
}

.close:hover {
  background-color: #1c1d40;
  color: #ffffff;
  border-color: #1c1d40;
}

@media screen and (min-width: 768px) {
  .flexWrap {
    display: flex;
    gap: 20px;
    margin-bottom: 32px;
  }
  .inputWrap {
    margin-bottom: 0;
    width: 100%;
  }
}
