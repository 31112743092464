.professions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px auto 60px auto;
  align-items: center;
}

@media screen and (min-width: 1008px) {
  .professions {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    margin: 100px auto 120px;
  }
}
