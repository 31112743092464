.subTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  display: block;
  margin-bottom: 32px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  display: block;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.value {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 20px;
}

.key {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: block;
  margin-bottom: 40px;
}

.listing {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  margin-bottom: 10px;
}

.listingName {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 60px;
}
