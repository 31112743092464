.filterRow {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.filterRow select {
  text-align: center;
  max-width: 100%;
}

.filterByLabel {
  display: inline-block;
  color: #353546;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  white-space: nowrap;
}

.filterDropdown {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: #fff;
  font-size: 14px;
  color: #353546;
  margin-bottom: 0;
}

.mobileButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}

.followButtonMobile {
  justify-content: center;
  gap: 8px;
  width: 100%;
}
.followButtonMobile path {
  fill: #FFFFFF;
}

.followAllButton {
  background-color: transparent;
  color: #5BAFD7;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #5BAFD7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.followAllButton path {
  fill: #5BAFD7;
}

/* Hide desktop elements on mobile */
.tagsContainer .viewAll {
  display: none;
}

.followButtonContainer {
  display: none;
}

.input {
  padding: 13px 10px 13px 40px;
  border-radius: 8px;
  background: #fff;
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.input::placeholder {
  color: #abafb9;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.inputWrap {
  position: relative;
  margin-bottom: 28px;
  width: 100%;
}

.searchIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
}

main.modal {
  width: 100%;
  max-width: 800px;
  padding-top: 40px;
  border-radius: 16px;
  padding-bottom: 40px;
}

.title {
  color: #1C1D40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 4px;
}

.subtitle {
  color: #8B8B8B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}

.heading {
  margin-bottom: 24px;
}

.viewAll {
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  color: #5BAFD7;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.viewAll path {
  fill: #5bafd7;
}

.categoryItem {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 6px;
  background: #eaeaea;
  border: none;
  cursor: pointer;
}

.categoryItemActive {
  color: #FFFFFF;
  background: #91B848;
}

.tagsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.tagItem {
  display: flex;
  color: #353546;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding: 12px 24px;
  border-radius: 6px;
  background: #f2fafd;
  border: none;
  cursor: pointer;
}

.tagItemActive {
  background: #5bafd7;
  color: #fff;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 28px;
}

.followButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}

.followButton {
  background-color: #5BAFD7;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.followButton:disabled,
.followButtonMobile:disabled {
  background-color: #eaeaea;
}

.followButton path {
  fill: #FFFFFF;
}


@media screen and (min-width: 768px) {
  .filterByLabel,
  .filterDropdown,
  .mobileButtonsContainer,
  .filterRow {
    display: none;
  }

  .tagsContainer .viewAll {
    display: flex;
  }

  .followButtonContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
  }

  .inputWrap {
    width: 100%;
  }

  .inputWrap .searchIcon {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }

  main.modal {
    left: 50%;
    margin-left: 0;
    transform: translate(-50%, -50%);
    width: 728px;
    height: auto;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .inputWrap {
    width: 100%;
  }

  main.modal {
    width: 1015px;
    max-width: 100%;
    padding: 40px 40px 40px;
  }
}
