.popupActions {
  position: absolute;
  border: 0;
  z-index: 2;
  border-radius: 8px;
}

.static {
  position: static;
}

.relWrap {
  position: relative;
}

.dots {
  padding: 5px;
  background-color: inherit;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  top: -2px;
}

.dots:hover svg {
  fill: var(--color-blue-400);
}

.section-tooltip__btn:focus {
  outline: none;
}

.content {
  position: absolute;
  background: var(--color-black-500);
  border-radius: 8px;
  text-align: left;
  padding: 16px;
  z-index: 2;
  min-width: 80px;
  right: 50%;
  transform: translate(-20%, -50%);
  top: 50%;
  box-shadow: 0 0 28px rgba(239, 239, 239, 0.1);
}

.contentResponsive {
  position: absolute;
  background: var(--color-black-500);
  border-radius: 8px;
  text-align: left;
  padding: 16px;
  z-index: 2;
  min-width: 80px;
  right: -12px;
  transform: translate(0, -155%);
  top: 50%;
  box-shadow: 0 0 28px rgba(239, 239, 239, 0.1);
}

.content:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background: var(--color-black-500);
  border-radius: 4px;
  transform: rotate(45deg);
  right: -9px;
}

.contentResponsive:after {
  content: "";
  position: absolute;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background: var(--color-black-500);
  border-radius: 4px;
  transform: rotate(45deg);
  right: 6px;
  bottom: -7px;
}

.modal .contentResponsive {
  transform: translate(0, -140%);
}
.modal .contentResponsive:after {
  top: unset;
  left: unset;
}

.actionButton {
  color: var(--color-white-900);
}

.actionButton {
  border: 0;
  background: none;
  outline: none;
}

.actionButton:hover,
.actionButton:active {
  background: none;
  color: var(--color-white-900);
  text-decoration: underline;
}

.prompt {
  top: 0;
  transform: translate(30%, calc(-100% - 16px));
}

.prompt::after {
  top: unset;
  bottom: -8px;
  width: 20px;
  height: 20px;
  transform: translateX(50%) rotate(45deg);
  right: 30%;
}

.promptWrap {
  display: flex;
  justify-content: space-evenly;
}

.cancel {
  color: var(--color-gray-500);
  border: 1px solid var(--color-gray-500);
  background: transparent;
  min-width: 95px;
}

.confirm {
  background-color: var(--color-red-400);
  margin-left: 20px;
  min-width: 95px;
  border: 1px solid var(--color-red-400);
}

.warning {
  color: var(--color-white-900);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .prompt {
    transform: translate(50%, calc(-100% - 16px));
  }

  .prompt::after {
    right: 50%;
  }

  .contentResponsive {
    right: -12px;
    transform: translate(110%, -50%);
    top: 50%;
  }

  .contentResponsive:after {
    top: 50%;
    margin-top: -10px;
    left: -9px;
  }
}
