.header {
  background: var(--color-white-900);
  height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 32px;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-blue-400);
}

.progressBar {
  position: absolute;
  top: 0;
  z-index: 5;
  margin: 0 -32px;
}
