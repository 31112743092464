.asideTitle {
  display: inline-block;
  margin-right: 100px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.input {
  width: 100%;
}

.title {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  pointer-events: none;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.wrapper {
  padding: 12px 20px;
  max-width: 712px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white-900);
  min-height: calc(100vh - 72px);
}

.filterFlex {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.activeDropdown {
  left: auto;
  right: 0;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding: 40px 30px;
  }

  .title {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1008px) {
  .wrapper {
    max-width: 1204px;
    margin: 0 auto;
    padding: 4px 24px 24px;
  }

  .title {
    margin-bottom: 40px;
  }
}
