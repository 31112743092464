.content {
  margin-top: 40px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.description {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #353546;
  margin-bottom: 24px;
}

.leftSide {
  text-align: center;
  margin: 0 auto;
}

.rightSide {
  display: none;
}

.button {
  width: 100%;
  max-width: 340px;
}

@media screen and (min-width: 1008px) {
  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 60px;
    margin-bottom: 60px;
  }

  .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 40px;
  }

  .leftSide {
    max-width: 400px;
    text-align: left;
  }

  .content {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
  }

  .rightSide {
    display: flex;
  }
}
