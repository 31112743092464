.messageThread {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
}

@media screen and (min-width: 1320px) {
  .messageThread {
    padding-top: 10px;
  }
}
