.wrapper {
  margin: 30px auto;
  max-width: 400px;
}

.iconContainer {
  width: 160px;
  height: 160px;
  margin: 0 auto 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f8f8fa;
}

.icon {
  width: 120px;
  height: 90px;
}

.button {
  border-radius: 6px;
  border: 1.4px solid #5bafd7;
  color: #5bafd7;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 11px 24px;
  background-color: #fff;
  display: block;
  margin: 0 auto 16px;
  cursor: pointer;
}

.button:last-child {
  margin-bottom: 0;
}

.button:hover,
.button:active {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

.title {
  color: #1c1d40;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .iconContainer {
    width: 200px;
    height: 200px;
  }

  .icon {
    width: 152px;
    height: 120px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
}
