.title {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
}

.tile {
  margin-bottom: 12px;
  padding: 16px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.04);
}

.tileTitle {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.tileDescription {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 24px;
}

.tileButtonDesktop {
  display: none;
}

.tileButton {
  border-radius: 4px;
  background: #91b848;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding: 8px 12px;
  border: none;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .tile {
    background-color: transparent;
    padding: 24px 0;
    margin-bottom: 0;
    box-shadow: none;
    border-top: 1px solid #eaeaea;
    border-radius: 0;
  }

  .tile:first-child {
    padding-top: 0;
    border-top: none;
  }

  .tileHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .tileDescription {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 24px;
  }

  .tileTitle {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .tileButtonDesktop {
    display: block;
  }

  .tileButtonMobile {
    display: none;
  }

  .tileButton {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}
