.paymentOkMain {
  justify-content: space-between;
}

.popupHeading {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-250);
  margin: 0 0 25px;
}

.pointer {
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-500);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: block;
  margin-bottom: 40px;
}

.phoneText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 20px;
}

.phoneValue {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
}
