.aside {
  width: 100%;
  max-width: 872px;
  margin: 0 auto;
}

.asideTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.jobs {
  width: 100%;
}

.jobsItem {
  padding: 20px;
  background-color: #fff;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 12px;
}

.jobsItem:last-child {
  margin-bottom: 0;
}

.jobsLogo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.jobsLogo img {
  max-width: 100%;
}

.jobsHeading {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.jobsCompanyName {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #61616f;
  margin: 0;
}

.jobsPoint {
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #61616f;
}

.jobsLocation {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #61616f;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.jobsLocation path {
  fill: #61616f;
}

.jobsName {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #1c1d40;
  margin-bottom: 8px;
}

.jobsDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8b8b8b;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .asideTitle {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1320px) {
  .aside {
    max-width: 382px;
    margin: 0;
  }

  .jobsHeading {
    margin-bottom: 16px;
  }
}
