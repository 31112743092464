.add {
  border: 1.4px solid #5bafd7;
  border-radius: 6px;
  padding: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5bafd7;
  margin-top: 24px;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
  gap: 12px;
}

.add:hover {
  background: rgba(91, 175, 215, 0.1);
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.plusIcon {
  width: 12px;
  height: 12px;
}

@media screen and (min-width: 768px) {
  .dropdown {
    padding: 20px;
    margin-bottom: 20px;
  }

  .add {
    font-size: 16px;
    line-height: 22px;
    padding: 18px;
    margin-top: 28px;
  }

  .actions {
    flex-direction: row;
    gap: 28px;
  }
}
