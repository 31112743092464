.tooltip {
  position: relative;
  margin-right: 12px;
}

.content {
  display: none;
  position: absolute;
  top: 0;
  background: var(--color-black-500);
  color: var(--color-white-900);
  z-index: 1;
  padding: 15px;
  border-radius: 8px;
  transform: translate(-50%, calc(-100% - 10px));
  min-width: 255px;
  left: 50%;
  text-align: center;
}

.content:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  background: var(--color-black-500);
  border-radius: 4px;
  transform: translate(-50%, 5px) rotate(45deg);
  left: 50%;
}

.tooltip:hover .content {
  display: flex;
  justify-content: center;
  width: 120px;
}

.contentMiddle {
  display: none;
  position: absolute;
  top: 0;
  background: var(--color-black-500);
  color: var(--color-white-900);
  z-index: 1;
  padding: 15px;
  border-radius: 8px;
  transform: translate(-50%, calc(-100% - 15px));
}

.contentMiddle:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  background: var(--color-black-500);
  border-radius: 4px;
  transform: translate(37%, 5px) rotate(45deg);
}

.tooltip:hover .contentMiddle {
  display: flex;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
}
