.content {
  margin: 60px 0 72px;
  text-align: center;
}

.icon {
  display: block;
  width: 120px;
  margin: 0 auto 24px;
}

.message {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-800);
}

.modal {
  max-width: 485px;
}
