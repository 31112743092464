.content {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 12px;
  margin-bottom: 20px;
  position: relative;
}

.contentMain {
  padding: 32px 12px 24px 12px;
}

.spinner {
  display: none;
}

.overlay {
  background: rgba(0, 0, 0, 0.1);
}

.photoContainerRequired {
  border-color: #da4655;
}

.errorText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #da4655;
  text-transform: capitalize;
  text-align: left;
}

.rightSection {
  width: 100%;
}

.edit {
  background-color: #eaeaea;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 65px;
  right: 98px;
}

.relative {
  position: relative;
  width: 180px;
}

.sizeInfo {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #abafb9;
  display: block;
  text-align: center;
  margin-top: 12px;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  margin: 10px 0 0;
}

.edit svg {
  width: 8px;
  height: 8px;
}

.edit:hover {
  background-color: #dfe1e2;
}

.editCover {
  width: 8px;
  height: 8px;
  padding: 0;
  right: 3px;
}

.editCover svg {
  width: 4px;
  height: 4px;
}

.required {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #abafb9;
  text-align: left;
  display: block;
  margin-top: 20px;
}

.requiredError {
  color: #da4655;
}

.input {
  width: 100%;
  margin-top: 8px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  padding: 11px 12px;
  background-color: #fff;
}

.photoRequired {
  width: 80px;
  height: 80px;
}

.inputInvalid {
  background-color: #fff;
  border-color: #da4655;
}

.fileInput {
  display: none;
}

.input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #abafb9;
}

.inputContent {
  margin-bottom: 16px;
}

.input:hover,
.input:focus {
  background-color: transparent;
}

.iconPhoto {
  width: 24px;
  height: 24px;
}

.coverImage {
  background-color: #d9d9d9;
  width: 100%;
  height: 194px;
  position: relative;
}

.coverImageError {
  border: 1px solid #da4655;
}

.coverImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.coverImageActive {
  background-color: transparent;
}

.photoCamera {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  background-color: #abafb9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.cameraIcon {
  width: 24px;
  height: 24px;
}

.leftSection {
  margin-top: -42px;
  margin-left: 12px;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 20px;
    gap: 40px;
  }

  .leftSection {
    margin-top: -62px;
    margin-left: 20px;
  }

  .input {
    margin-top: 12px;
  }

  .inputContent {
    margin-bottom: 24px;
  }

  .photoRequired {
    width: 120px;
    height: 120px;
  }

  .iconPhoto {
    width: 40px;
    height: 40px;
  }

  .edit {
    width: 24px;
    height: 24px;
  }

  .edit svg {
    width: 16px;
    height: 16px;
  }

  .contentMain {
    padding: 32px 28px 24px 28px;
  }

  .editCover {
    width: 8px;
    height: 8px;
  }

  .editCover svg {
    width: 4px;
    height: 4px;
  }

  .edit {
    top: 95px;
    right: 55px;
  }
}

@media screen and (min-width: 1320px) {
  .content {
    padding: 28px;
  }
}
