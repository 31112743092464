:root {
  /* colors */
  --color-white-900: #fff;
  --color-black-500: #353546;
  --color-gray-150: #f8f8fa;
  --color-gray-250: #eaeaea;
  --color-gray-400: #abafb9;
  --color-gray-500: #8b8b8b;
  --color-blue-300: #f2fafd;
  --color-blue-400: #5bafd7;
  --color-blue-700: #2c2d64;
  --color-blue-800: #1c1d40;
  --color-yellow-100: #e9f1da;
  --color-green-400: #91b848;
  --color-red-100: #f9e1e4;
  --color-red-300: #ac4b77;
  --color-red-400: #da4655;

  /* shadows */
  --box-shadow-300: 0 0 28px rgba(0, 0, 0, 0.04);
}
