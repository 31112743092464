.radio {
  margin-bottom: 15px;
}

.radio {
  cursor: pointer;
}

.input[type="radio"] {
  transform: translateX(15px);
  opacity: 0;
}

.icon {
  height: 28px;
  width: 28px;
  margin-right: 5px;
  display: inline-block;
  border: 1px solid #d7d2d2;
  position: relative;
  vertical-align: middle;
  margin-bottom: 5px;
}

.radio:hover .icon {
  border-color: var(--color-blue-400);
}

.input[type="radio"]:checked + i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 12px;
  width: 12px;
  background: var(--color-blue-400);
  transform: translate(-50%, -50%);
}

.input[type="radio"]:disabled + i {
  border-color: #eee;
}

.input[type="radio"]:disabled:checked + i:after {
  background-color: #d7d2d2;
}

.input[type="radio"] + i {
  border-radius: 50%;
}

.input[type="radio"]:checked + .icon:after {
  border-radius: 50%;
}

.disabled:hover {
  cursor: not-allowed;
}

.newRadio .input[type="radio"]:checked + i:after {
  background: #fff;
}

.newRadio .input[type="radio"]:checked + i {
  background: #2c2d64;
}

.newRadio .radio:hover .icon {
  border-color: #2c2d64;
}
