.messagesContent {
  max-width: 768px;
  margin: 0 auto;
  height: 100%;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  display: none;
}

.messagesContentActive {
  display: flex;
}

.messagesContentDisable {
  display: none;
}

.preloader {
  position: absolute;
}

.userContent {
  padding: 10px 24px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.details {
  display: block;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
}

.detailsDesktop {
  display: none;
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
}
.image img {
  max-width: 100%;
}

.initials {
  background-color: #ac4b77;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  text-transform: uppercase;
}

.name {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.buttonSvg path {
  fill: #abafb9;
}

.emptyState {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.emptyState svg {
  display: block;
}

.emptyTitle {
  color: #1c1d40;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: block;
  margin: 0 0 8px 0;
}

.emptyText {
  color: #61616f;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.emptyIcon {
  padding: 12px;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid #eaeaea;
  background: #f8f8fa;
}

@media screen and (min-width: 768px) {
  .messagesContent {
    width: 100%;
    max-width: initial;
    margin: 0;
    padding: 0 20px;
    display: flex;
  }
}

@media screen and (min-width: 1320px) {
  .messagesContent {
    padding: 0 16px 10px;
  }

  .detailsMobile {
    display: none;
  }

  .detailsDesktop {
    display: block;
  }
}
