.modal {
  max-width: 420px;
  padding: 24px 12px;
}

.title {
  margin-bottom: 30px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.followingItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  color: #5bafd7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.removeButton {
  border: none;
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
}

.removeButton svg {
  width: 20px;
  height: 20px;
}

.removeButton path {
  stroke: #da4655;
}

.close {
  padding: 12px;
  border-radius: 8px;
  background: #5bafd7;
  color: #f8f8fa;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border: none;
  cursor: pointer;
}
