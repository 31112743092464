.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
}

.item {
  margin-bottom: 24px;
  padding-right: 48px;
  position: relative;
  width: 100%;
}

.removeButton {
  position: absolute;
  right: 0;
  top: 24px;
}

.flex {
  display: flex;
  margin-left: 16px;
  gap: 16px;
  align-items: center;
}

@media screen and (min-width: 1320px) {
  .removeButton {
    position: inherit;
    top: auto;
    right: auto;
  }

  .flexItem {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }

  .item {
    padding-right: 0;
  }

  .content {
    width: 100%;
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .labelHideDesktop {
    display: none;
  }

  .name {
    margin: 0;
  }
}
