.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-gray-500);
  width: 100%;
}

@media screen and (min-width: 768px) {
  .empty {
    padding: 40px;
  }
}
