.notifications {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 22px 15px;
}

.notificationsHead {
  display: none;
  margin-bottom: 20px;
}

.notificationsTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #353546;
  flex: none;
}

.buttonClose {
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  width: auto;
  height: auto;
}

.iconClose {
  width: 9px;
  height: 9px;
  stroke: #353546;
}

.iconClose path {
  fill: #353546;
}

.notificationsText {
  font-weight: 700;
  font-size: 12px;
  color: #353546;
}

.notificationsItem {
  margin-bottom: 40px;
}

.icon,
.checkedIcon {
  width: 20px;
  height: 20px;
}

.notificationsElement {
  display: flex;
  justify-content: space-between;
  width: 296px;
  margin-bottom: 15px;
}

.notificationsElement:nth-child(2) {
  margin-bottom: 23px;
  padding-left: 0;
  width: 100%;
  justify-content: start;
}

.notificationsElement:nth-child(1) {
  display: none;
}

.notificationsElement:last-child {
  margin-bottom: 0;
}

.notificationsCheckboxText {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #353546;
  display: block;
  margin-right: 11px;
}

.notificationsElementFlex:nth-child(2) {
  display: flex;
  max-width: 307px;
  justify-content: space-between;
}

.iconContent {
  display: none;
}

@media screen and (min-width: 768px) {
  .notificationsCheckboxText {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .notificationsText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .iconContent {
    display: block;
  }

  .notificationsElementFlex:nth-child(2) {
    justify-content: flex-start;
    gap: 12px;
  }

  .buttonClose {
    margin-left: auto;
  }
}

@media screen and (min-width: 1008px) {
  .notificationsCheckboxText {
    display: none;
  }

  .notificationsElement {
    display: block;
    margin: 0;
    padding: 0;
  }

  .notificationsHead {
    display: flex;
  }

  .notifications {
    padding: 56px 40px 40px 40px;
  }

  .notificationsItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .notificationsTitle:nth-child(1),
  .notificationsElement:nth-child(1) {
    width: 82px;
    display: flex;
  }

  .notificationsTitle:nth-child(2),
  .notificationsElement:nth-child(2) {
    width: 290px;
    margin: 0;
  }

  .notificationsElement:nth-child(2) {
    display: flex;
  }

  .notificationsTitle:nth-child(2) {
    padding-left: 20px;
  }

  .notificationsTitle:nth-child(3),
  .notificationsElement:nth-child(3) {
    width: 55px;
    margin-right: 52px;
    text-align: center;
  }

  .notificationsTitle:nth-child(4),
  .notificationsElement:nth-child(4) {
    width: 62px;
    margin-right: 52px;
    text-align: center;
  }

  .notificationsTitle:nth-child(5),
  .notificationsElement:nth-child(5) {
    width: 40px;
    margin-right: 52px;
    text-align: center;
  }

  .notificationsTitle:nth-child(6),
  .notificationsElement:nth-child(6) {
    width: 55px;
    text-align: center;
  }

  .iconContent {
    display: none;
  }

  .buttonClose {
    margin-left: auto;
  }
}
