.modal {
  max-width: 484px;
}

.subTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  margin-bottom: 32px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}

.modalInput {
  margin-bottom: 12px;
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 60px;
}

.resendBtn {
  color: var(--color-blue-400);
  font-weight: 600;
  text-align: left;
  padding: 0;
  margin-left: 5px;
}

.error {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: right;
  margin: -5px 0 5px;
  color: var(--color-red-400);
}
