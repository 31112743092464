.name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 10px;
}

.heading {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-gray-250);
  padding-bottom: 17px;
}

.downLine {
  width: 100%;
  border-top: 1px solid #eaeaea;
  margin-bottom: 30px;
}

.photo {
  width: 92px;
  min-width: 92px;
  height: 92px;
  min-height: 92px;
  background-color: var(--color-red-400);
  overflow: hidden;
  margin-right: 17px;
  border-radius: 50%;
}

.calendarIcon {
  width: 20px;
  height: 20px;
}

.date {
  color: var(--color-gray-500);
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  position: relative;
}

.text {
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-black-500);
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .calendarIcon {
    display: block;
  }

  .date {
    margin: 0;
  }

  .infoBox {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 24px;
    gap: 8px;
  }

  .name {
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }

  .heading {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }

  .photo {
    width: 120px;
    height: 120px;
    min-height: 120px;
    min-width: 120px;
    margin-right: 25px;
  }
}

@media screen and (min-width: 1008px) {
  .box {
    padding: 30px 0 0 90px;
  }
}
