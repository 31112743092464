.messagePanel {
  margin-bottom: 25px;
}

.name {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #1c1d40;
}

.headContent {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 16px 0;
}

.buttonExit {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconLeft {
  width: 8px;
  height: 13px;
  margin-right: 20px;
}

.actions {
  margin-left: auto;
}

.buttonLetter {
  padding: 0;
  width: 19px;
  height: 14px;
}

.buttonArchive {
  padding: 0;
  width: 17px;
  height: 14px;
  margin-left: 10px;
}

.detail {
  padding: 15px 20px 15px 40px;
  background: #ffffff;
  border: 2px solid #eaeaea;
  border-left: none;
  border-right: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -20px;
}

.source {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 5px;
}

.listing,
.attorney {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
}

.detailButton {
  background: #1c1d40;
  width: 75px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding: 5px;
  border: none;
}

.detailDisabled {
  display: none;
}

@media screen and (min-width: 768px) {
  .detail {
    margin: 0;
    border: 2px solid #eaeaea;
    padding: 15px 12px 15px 20px;
  }
  .listing,
  .attorney {
    display: inline-block;
  }

  .attorney {
    margin-left: 5px;
  }

  .buttonExitDisabled {
    display: none;
  }
}

@media screen and (min-width: 1320px) {
  .buttonExit {
    display: none;
  }

  .messagePanelDisabled {
    display: none;
  }
}
