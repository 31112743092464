.popupHeading {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-250);
  margin: 0 0 25px;
}

.label {
  display: block;
  margin-bottom: 0;
}

.coupon {
  display: flex;
  gap: 16px;
}

.couponInput {
  padding: 11px 12px;
  width: 100%;
}

.couponBtn {
  width: 100px;
  flex: none;
  padding: 12px;
  border-radius: 8px;
  background: #5bafd7;
}

.couponBtn:disabled {
  background: #eaeaea;
  border-color: #eaeaea;
  color: #8b8b8b;
  pointer-events: none;
}

.error {
  color: var(--color-red-400);
  display: block;
  margin: 10px 0;
  font-size: 14px;
}

.couponError {
  color: #da4655;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: block;
  margin-top: 6px;
}

.switch {
  margin: 0 20px;
}

.plan {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  min-height: 60px;
}

.planTerm {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  width: 180px;
  cursor: pointer;
  display: block;
}

div.modalControl {
  margin-top: 60px;
}

.paymentContent {
  min-height: 102px;
}

.planTermOld {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-400);
  display: block;
  margin-bottom: 5px;
  text-decoration: line-through;
}

.successCoupon {
  color: #353546;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 6px;
}

.successCoupon span {
  text-transform: uppercase;
}

.confirmClassName {
  pointer-events: none;
  background-color: var(--color-gray-400);
  border-color: var(--color-gray-400);
}

.currentPlan {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.currentPlanItem {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #d7d2d2;
  background: #fff;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.currentPlanItemActive {
  border-color: #5bafd7;
}

.currentPlanName {
  color: #8b8b8b;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.11px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 18px 0;
}

.currentPlanPrice {
  display: flex;
  gap: 6px;
  margin-bottom: 8px;
  align-items: center;
}

.currentPlanOldPrice {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: line-through;
}

.currentPlanNewPrice {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.currentPlanPeriod {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-bottom: 12px;
}

.currentPlanTotal {
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.currentPlanDiscount {
  display: flex;
  gap: 2px;
  margin-bottom: 8px;
}

.currentPlanDiscount svg {
  width: 24px;
  height: 24px;
}

.currentPlanSave {
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.currentPlanRadioContent {
  display: flex;
  justify-content: flex-end;
}

.currentPlanItemActive .currentPlanRadioIcon {
  border: none;
}

div .currentPlanRadioIcon {
  margin: 0;
  width: 20px;
  height: 20px;
}

.currentPlanRadio input[type="radio"]:checked + i.currentPlanRadioIcon {
  background: #5bafd7;
}

.couponText {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}
