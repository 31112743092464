.aside {
  width: 312px;
  margin: 40px auto;
}

.mapImage {
  width: 100%;
  max-height: 200px;
  margin-bottom: 40px;
}

.mapImage img {
  max-width: 100%;
}

.marks {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 36px;
}

.marksItem {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d7d2d2;
  margin-bottom: 4px;
  color: #353546;
  border-radius: 6px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #1c1d40;
  margin-bottom: 24px;
}

.subTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: #1c1d40;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #353546;
  display: block;
  margin-bottom: 24px;
}

.links {
  margin: 40px 0;
}

.linksItem {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  color: #353546;
}

.linksItem:hover {
  text-decoration: underline;
  color: #5bafd7;
}

.linkFilled {
  background: #5bafd7;
  color: #f8f8fa;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
}

.linkFilled:hover {
  background-color: #1c1d40;
  color: #ffffff;
}

.filterContent {
  padding-bottom: 32px;
  border-bottom: 1px solid #d7d2d2;
  margin-bottom: 32px;
}

.select {
  width: 100%;
  max-width: 100%;
}

.select > [class$="control"] {
  background-color: #fff;
}

@media screen and (min-width: 1320px) {
  .aside {
    margin: 0;
    flex: none;
  }
}
