.container {
  padding: 24px 20px;
}

.title {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin: 0 0 12px 0;
}

.text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin: 0 0 16px 0;
}

.button {
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 52px;
    font-weight: 800;
    line-height: 66px;
  }

  .text {
    font-size: 18px;
    line-height: 28px;
  }
}
