.content {
  position: relative;
}

.dropdownButton {
  height: 44px;
  padding: 10px 12px;
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background-color: transparent;
  cursor: pointer;
  min-width: 152px;
  flex: none;
}

.checkbox {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
}

.iconActive {
  transform: rotate(180deg);
}

.icon path {
  fill: #090a28cc;
}

.dropdownContent {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px 20px 0 20px;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  min-height: 350px;
  width: 280px;
  top: 50px;
  z-index: 1;
  overflow: auto;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.14);
}

@media screen and (min-width: 768px) {
  .dropdownContent {
    height: 100%;
  }
}
