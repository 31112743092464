.couponInput {
  margin-right: 20px;
  width: calc(100% - 140px) !important;
}

.couponBtn {
  width: 120px;
}
.couponBtn:disabled {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
}

.coupon {
  margin-bottom: 60px;
}

.labelText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
}

.applied {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  margin-top: 18px;
  font-weight: normal;
}

.applied span {
  text-transform: uppercase;
  font-weight: bold;
}

.couponContent {
  border-top: 1px solid var(--color-gray-250);
  padding-top: 24px;
}

.leadText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 10px;
}

.price {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-bottom: 10px;
}

.priceActive {
  text-decoration: line-through;
}

.updatedPrice {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ac4b77;
  display: block;
  margin-bottom: 10px;
}

.label {
  margin-top: 24px;
  display: block;
}
