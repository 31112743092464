.container {
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.04);
  margin-top: 24px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  color: #353546;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.detail {
  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 24px;
}

.detail svg {
  width: 20px;
  height: 20px;
}

.buttonText {
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #5bafd7;
  background-color: transparent;
  cursor: pointer;
  display: none;
}

.buttonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
}

.buttonIconActive {
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .buttonText {
    display: flex;
  }

  .buttonIcon {
    display: none;
  }

  .content {
    align-items: center;
  }
}
