.StripeElement {
  height: 52px;
  border: 1px solid #d7d2d2;
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
}

.StripeElement:focus {
  outline: none;
  border: 1px solid #5bafd7;
}

.StripeElement::placeholder {
  color: #353546;
}

.StripeElement--invalid {
  border-color: #da4655;
  background-color: #f9e1e4;
}

.stripeInput {
  margin-bottom: 30px;
}

.stripeInputInvalid .StripeElement {
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #da4655;
  background-color: #f9e1e4;
}
