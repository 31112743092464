.button {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
}

.button:hover {
  background-color: rgba(234, 234, 234, 0.5);
}

.buttonHide {
  opacity: 0;
  pointer-events: none;
}

.buttonCrossIcon svg {
  width: 10px;
  height: 10px;
}
