.checkbox {
  display: inline-block;
  width: auto;
}

.check {
  flex-direction: row;
}

.hidden {
  display: none;
}

.dropdownArrowUp {
  transform: rotate(180deg);
}

.dropdown {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eaeaea;
}

.dropdownHeading {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px 0;
  cursor: pointer;
  outline: none;
}
