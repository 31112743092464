.label {
  color: var(--color-gray-500);
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 16px;
}
