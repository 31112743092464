.title {
  color: #212121;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 0 16px 0;
}

.flex {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
}

.pin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #eaeaea;
}

.pin svg {
  width: 20px;
  height: 20px;
}

.pin path {
  fill: #292d32;
}

.place {
  color: #212121;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 8px 0;
}

.address {
  color: #212121;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.mapContainer {
  height: 324px;
  width: 100%;
}
