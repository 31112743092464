.nameContent {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.initials {
  background-color: #ac4b77;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  text-transform: uppercase;
}

.name {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
}

.action:last-child {
  margin-bottom: 0;
}

.actionIcon,
.actionIconPhone {
  width: 17px;
  height: 12px;
  display: block;
  margin-right: 10px;
  flex: none;
}

.actionIconPhone {
  width: 20px;
  height: 20px;
}

.actionText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  word-break: break-all;
}

.actionIcon path {
  fill: #abafb9;
}

.hr {
  display: block;
  margin: 20px 0;
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 10px;
  display: block;
}

.date,
.added {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  text-transform: lowercase;
}

.added {
  text-transform: capitalize;
}

.status {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  margin-bottom: 20px;
}

.publicUrl {
  display: inline-block;
  margin-left: 5px;
}

.icon {
  width: 12px;
  height: 11px;
}

.editListing {
  display: inline-block;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #5bafd7;
  margin-left: 5px;
}

.lastEditDate {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1c1d40;
}

.listingDetail {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #1c1d40;
  width: 100%;
  display: block;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.closeIcon path {
  fill: #1c1d40;
}

.listingWebSite {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  margin-bottom: 20px;
}

.professionalName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  margin-bottom: 20px;
}

.listingUrl,
.activeStatus {
  color: #5bafd7;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  display: block;
}

.listingUrl:first-letter,
.activeStatus :first-letter {
  text-transform: capitalize;
}

.listingUrl:hover,
.activeStatus :hover {
  text-decoration: none;
}

.activeStatus {
  margin-bottom: 10px;
}
