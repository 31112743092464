.modal {
  max-width: 484px;
}

.subTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  margin-bottom: 32px;
}

.content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 0 0;
}

.title {
  font-weight: 700;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-blue-800);
}

.icon {
  text-align: center;
  display: block;
  width: 75px;
  height: 75px;
  margin-top: auto;
}

.detail {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-800);
  text-align: center;
  display: block;
  margin-top: 20px;
}
