.coupon {
  margin-top: 12px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ac4b77;
}

.code,
.price {
  text-transform: uppercase;
}

.couponContent {
  border-top: 1px solid var(--color-gray-250);
  padding-top: 24px;
}

.couponError {
  color: var(--color-red-400);
  display: block;
  margin: 10px 0;
  font-size: 14px;
}
