.purchase {
  margin-bottom: 20px;
}

.button {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.buttonGrey {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  width: 100%;
  padding: 15px;
  display: block;
  text-align: center;
  border: 1px solid var(--color-gray-250);
  border-radius: 6px;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  text-align: center;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .purchase {
    display: flex;
    justify-content: center;
  }

  .button {
    margin-bottom: 0;
    margin-right: 20px;
    max-width: 400px;
  }

  .buttonGrey {
    max-width: 200px;
  }
}
