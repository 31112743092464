.parent {
  position: relative;
}

.calendar {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
}

.inputParent {
  position: relative;
  width: 100%;
}

.input::placeholder {
  color: #abafb9;
}

.input {
  width: 100%;
  background: #f8f8fa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  padding: 9px 10px 9px 40px;
  border: 1px solid transparent;
}

.calendarIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-top: -8px;
}

.calendarIcon path {
  fill: rgba(9, 10, 40, 0.8);
}

.error {
  color: var(--color-red-400);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.inputInvalid {
  outline: 1px solid var(--color-red-400);
}

.hide {
  display: none;
}
