.content {
  margin-top: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.tab {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 7px 12px;
  border: 1px solid transparent;
  border-radius: 320px;
  cursor: pointer;
  background: transparent;
}

.tabActive {
  border-color: #5bafd7;
  color: #5bafd7;
}

@media screen and (min-width: 768px) {
  .content {
    gap: 12px;
  }

  .tab {
    padding: 8px 16px;
  }
}

@media screen and (min-width: 1320px) {
  .content {
    gap: 16px;
    margin-top: 0;
  }
}
