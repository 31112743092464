.inputField {
  background: var(--color-white-900);
  border-radius: 6px;
  padding: 11px 10px;
  display: block;
  width: 100%;
  border: 1px solid transparent;
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.inputFieldDisable {
  pointer-events: none;
}

.inputField::placeholder {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.inputField:focus::placeholder {
  color: transparent;
}

.sendBtn {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.sendBtn[disabled] {
  opacity: 0.7;
}

.sendIcon {
  width: 20px;
  height: 20px;
}

.preloader {
  position: initial;
  width: 20px;
  height: 20px;
  left: auto;
  top: auto;
  transform: translate(0, 0);
}

.messageInput {
  width: 100%;
  margin: auto auto 0 auto;
  position: relative;
}

.messageInputLoading {
  z-index: 1;
}
