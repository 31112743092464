.button {
  color: var(--color-white-900);
  background-color: var(--color-blue-400);
  border: 1.4px solid var(--color-blue-400);
  border-radius: 6px;
  padding: 15px;
  line-height: 19px;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.button:disabled {
  cursor: default;
}

.button:hover,
.button:active {
  color: #fff;
  background: #2c2d64;
  border-color: #2c2d64;
}

.buttonDowngrade {
  padding: 5px 20px;
  background: #eaeaea;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  border: none;
}

.button:focus {
  outline: none;
}

.outline {
  color: var(--color-gray-500);
  background: transparent;
  border: 1.5px solid var(--color-gray-250);
}

.blueOutline {
  background-color: transparent;
  color: var(--color-blue-400);
  border: 1.4px solid var(--color-blue-400);
}

.clear {
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
}

.clear:hover,
.clear:active {
  background: none;
  color: unset;
}
