.head {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
}

.select {
  width: 100%;
}

.hidden {
  display: none;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.education {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  width: 100%;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.removeButton {
  position: absolute;
  right: 0;
  top: 0;
}

.label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 8px;
}

div.year {
  max-width: 100%;
  width: 100%;
}

.educationName {
  width: calc(100% - 50px);
}

@media screen and (min-width: 768px) {
  .label {
    display: none;
  }

  .name {
    display: none;
  }

  .flex {
    display: flex;
    gap: 24px;
    width: calc(100% - 50px);
  }

  .inputName,
  .major {
    width: 50%;
  }

  .position {
    width: 100%;
  }

  div.year {
    max-width: 30%;
  }
}

@media screen and (min-width: 1320px) {
  .education {
    display: flex;
    gap: 24px;
  }

  .educationName {
    width: 100%;
    max-width: 274px;
    flex: none;
    display: flex;
    gap: 12px;
  }

  div.year {
    flex: none;
    width: 110px;
  }

  .removeButton {
    position: inherit;
    margin-right: 0;
  }

  .input {
    margin-bottom: 0;
  }

  .major {
    width: 250px;
    flex: none;
  }

  .inputName {
    width: 300px;
  }
}
