.nextIcon {
  position: absolute;
  top: 50%;
  padding: 0;
  display: none;
  right: 10px;
}

@media screen and (min-width: 1008px) {
  .nextIcon {
    display: inline-block;
  }
}

@media screen and (min-width: 1320px) {
  .nextIcon {
    right: 150px;
  }
}
