.link {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 16px;
  background-color: transparent;
  border: none;
  display: block;
  padding: 0;
}

.link:hover {
  background-color: transparent;
  text-decoration: underline;
}

.link:last-child {
  margin-bottom: 0;
}

.controls {
  width: 170px;
  right: 0;
  transform: translate(-60px, -10px);
}

.controls:after {
  right: 2px;
  bottom: auto;
  top: 30px;
}

.editButton {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editButton:hover {
  background-color: rgba(234, 234, 234, 0.5);
}
