.avatar {
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #353546;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
  border: 1px solid #eaeaea;
}

.preloader {
  width: 30px;
  height: 30px;
  position: absolute;
}

.buttonAvatar {
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 1320px) {
  .avatar {
    width: 44px;
    height: 44px;
  }
}
