.wrapper {
  max-width: 1116px;
  margin: 0 auto;
  padding: 0 10px;
}

.section {
  background-color: #f8f8fa;
  padding: 40px 0 100px 0;
}

.pricingItem {
  background-color: #ffffff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 40px;
  max-width: 380px;
  margin: 0 auto 24px;
}

.pricingItemBlue {
  background-color: #5bafd7;
}

.pricingItemBlue .pricingTitle,
.pricingItemBlue .pricingType,
.pricingItemBlue .pricingPrice,
.pricingItemBlue .pricingTerm,
.pricingItemBlue .pricingPeriod,
.pricingItemBlue .pricingSubtitle,
.pricingItemBlue .pricingIncludesItem {
  color: #fff;
}

.pricingItemBlue .pricingIncludesItem {
  font-weight: 700;
}

.pricingItemBlue .pricingButton {
  background-color: #ffffff;
  color: #5bafd7;
}

.pricingTitle {
  text-align: center;
  color: #1c1d40;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.pricingType {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-bottom: 24px;
  display: block;
}

.pricingPrice {
  color: #1c1d40;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  margin-bottom: 16px;
  display: block;
}

.pricingPeriod {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  margin-bottom: 32px;
  text-align: center;
}

.pricingTerm {
  display: flex;
  align-items: center;
  margin-bottom: 46px;
  justify-content: center;
}

.pricingTermMonth {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #abafb9;
  display: block;
  margin-right: 18px;
}

.pricingTermCheckbox {
  width: 58px;
  height: 32px;
  margin-right: 18px;
}

.pricingTermAnnual {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
}

.pricingSubtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #353546;
  margin-bottom: 20px;
  padding-top: 20px;
  display: block;
}

.pricingSubtitle:first-child {
  padding-top: 0;
}

.pricingPlus {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #353546;
  display: block;
  text-align: center;
  margin-bottom: 28px;
}

.pricingIncludes {
  height: 180px;
  overflow: hidden;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  margin-bottom: 60px;
}

.pricingIncludesActive {
  height: auto;
}

.pricingIncludesActive {
  height: auto;
}

.pricingIncludesItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: flex;
  margin-bottom: 16px;
  gap: 10px;
}

.pricingIncludesItem svg {
  flex: none;
  width: 16px;
  height: 16px;
  flex: none;
}

.pricingIncludesItemNotIncluded {
  color: #abafb9;
}

.pricingIncludesItemNotIncluded svg {
  width: 16px;
  height: 16px;
  flex: none;
}

.pricingIncludesItemIncluded {
  font-weight: 700;
}

.pricingIncludesItemNotIncluded span {
  font-weight: 700;
}

.pricingIncludesBig .pricingIncludes {
  padding-left: 0;
}

.pricingIncludesBig .pricingIncludesItem {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #353546;
  margin-bottom: 28px;
}

.pricingIncludesCheckmark {
  display: block;
  flex: none;
  max-width: 20px;
  max-height: 20px;
}

.pricingIncludesBig .pricingIncludesCheckmark {
  width: 28px;
  height: 28px;
}

.pricingButtonContent {
  padding: 24px 0 0 0;
  position: relative;
  margin-top: 12px;
}

.pricingButton {
  width: 100%;
}

.pricingButtonContent:before {
  content: "";
  display: block;
  width: calc(100% + 80px);
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  background-color: #eaeaea;
  height: 1px;
}

.pricingIncludesItem path {
  fill: #353546;
}

.pagePricingDescription {
  color: #1c1d40;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  display: block;
  margin: 24px 0;
}

.pricingInfoButton {
  max-width: 320px;
  margin: 0 auto 48px;
}

.pricingInfoButton:hover {
  color: #ffffff;
  background-color: var(--color-blue-700);
}

.titlePricingPage {
  margin-bottom: 60px;
  text-align: center;
}

.pricingItemBlue .pricingHide {
  color: #fff;
  border: 1px solid #fff;
}

.pricingItemBlue .pricingIncludesItem path {
  fill: #fff;
}

.pricingHide {
  width: 100%;
  margin-bottom: 16px;
  background-color: transparent;
  color: #5bafd7;
  border: 1px solid #5bafd7;
  height: 44px;
  border-radius: 8px;
}

.pricingButtonBlue {
  color: #fff;
  background: #5bafd7;
}

.pricingButtonBlue,
.pricingButton {
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderSliderTitle {
  color: #1c1d40;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  display: block;
  margin: 0 0 16px;
}

.sliderSliderDescription {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: block;
  margin-bottom: 32px;
}

.pricingToggle {
  margin: 0 auto 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.pricingToggleTitle {
  color: #1c1d40;
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}

.pricingToggleTitleAnnually {
  color: #8b8b8b;
}

.pricingToggle.active .pricingToggleTitle {
  color: #8b8b8b;
}

.pricingToggle.active .pricingToggleTitleAnnually {
  color: #1c1d40;
}

.pricingToggleTitleUnactive {
  color: #8b8b8b;
}

.pricingToggleTitleActive {
  color: #1c1d40;
}

.pricingToggleElement {
  width: 60px;
  background: #eaeaea;
  border-radius: 30px;
  height: 32px;
  position: relative;
  cursor: pointer;
  margin: 0 15px;
}

.pricingToggleElement.active {
  background: #5bafd7;
}

.pricingToggleElement::before {
  content: "";
  width: 24px;
  height: 24px;
  background: #fff;
  position: absolute;
  border-radius: 100%;
  top: 4px;
  left: 4px;
  transition: 0.3s;
}

.pricingToggleElement.active::before {
  left: calc(100% - 28px);
}

.pricingToggleTitle.inactive {
  opacity: 0.5;
}

.pricingToggleLabel {
  display: flex;
  padding: 4px;
  gap: 4px;
  width: 100px;
  align-items: center;
  justify-content: center;
  color: #5bafd7;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 6px;
  background: #fff;
  flex: none;
  position: absolute;
  top: -40px;
  right: 0;
}

.helpContent {
  max-width: 640px;
  margin: 48px auto 0 auto;
}

.helpText {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  display: block;
  margin: 0 0 24px 0;
}

.helpLink {
  width: 240px;
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #5bafd7;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .section {
    padding-bottom: 160px;
  }

  .helpContent {
    margin-top: 100px;
  }

  .pricingTitle {
    font-size: 28px;
    line-height: 36px;
  }
  .pricing {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .pricingContent {
    margin-left: 36px;
  }

  .titlePricingPage {
    margin-bottom: 80px;
  }

  .pricingPrice {
    font-size: 52px;
    font-weight: 800;
    line-height: 66px;
  }

  .pagePricingDescription {
    margin: 76px 0 24px;
  }

  .pricingInfoButton {
    max-width: 320px;
    margin: 0 auto 100px;
  }

  .sliderSliderTitle {
    color: #1c1d40;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 24px;
  }

  .sliderSliderDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 44px;
  }

  .pricingToggle {
    margin: 0 auto 40px;
    max-width: 620px;
    padding-left: 110px;
  }

  .pricingToggleLabel {
    position: inherit;
    top: auto;
    right: auto;
    font-size: 14px;
    width: 105px;
  }

  .pricingToggleTitle {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .pricingContent {
    display: flex;
    margin-left: 0;
    gap: 60px;
  }

  .pricingIncludes {
    height: auto;
  }

  .buttonRegularActivityFeatures {
    display: none;
  }

  .pricingHide {
    display: none;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    max-width: 460px;
    margin: 0 auto 80px auto;
  }

  .pricingButtonContent {
    margin-top: auto;
  }
}

@media screen and (min-width: 1320px) {
  .section {
    padding-bottom: 210px;
  }

  .pricingItem {
    display: flex;
    flex-direction: column;
    padding: 32px 60px;
  }
  .pricing {
    align-items: inherit;
  }

  .pricingButtonContent:before {
    width: calc(100% + 120px);
  }

  .pricingButton:hover,
  .pricingButton:active,
  .pricingButtonBlue:hover,
  .pricingButtonBlue:active,
  .helpLink:hover,
  .helpLink:active {
    color: var(--color-white-900);
    background: var(--color-blue-700);
    border-color: var(--color-blue-700);
  }
}
