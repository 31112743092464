.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
  margin-bottom: 16px;
}

.select {
  width: 100%;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.select div[class$="MenuList"] {
  overflow: auto;
  max-height: 220px;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
  border: 1px solid #da4655;
}

.select div[class$="option"] {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #1c1d40;
  cursor: pointer;
  border: 0.5px solid transparent;
}

.select div[class$="option"]:hover {
  background: #f8f8fa;
  border: 0.5px solid #eaeaea;
}

.errorText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: inherit;
  text-align: left;
}

.placeholder {
  color: #abafb9;
}

@media screen and (min-width: 768px) {
  .selectWrap {
    max-width: 180px;
    margin-bottom: 0;
  }
}
