.container {
  border-radius: 4px;
  border: 1px solid #8b8b8b;
  background: #eff0f2;
  height: 194px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.coverImage {
  height: 194px;
  display: block;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0 auto;
  display: block;
  text-align: right;
}

.content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.contentActive {
  left: auto;
  right: 40px;
  top: 80px;
  transform: translate(0, 0);
}

.photoCamera {
  width: 40px;
  height: 40px;
  background-color: #abafb9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.contentActive .photoCamera {
  background: #fff;
}

.contentActive .title {
  color: #fff;
}

.photoCamera svg {
  width: 24px;
  height: 24px;
}

.cameraIcon {
  width: 24px;
  height: 24px;
}

.edit {
  background-color: #eaeaea;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 0;
  right: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit svg {
  width: 8px;
  height: 8px;
}

.edit:hover {
  background-color: #dfe1e2;
}

.editCover {
  width: 8px;
  height: 8px;
  padding: 0;
  right: 3px;
}

.editCover svg {
  width: 4px;
  height: 4px;
}

.title {
  color: #1c1d40;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin: 5px 0;
}

.description {
  color: #8c91a2;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.2px;
  margin: 0;
}

.fileInput {
  display: none;
}

.mainTitle {
  color: #30373d;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
}

.photoCameraAbsolute {
  position: absolute;
  right: 10px;
  top: 10px;
}

.container {
  position: relative;
}

@media screen and (min-width: 768px) {
  .container {
    height: 240px;
  }

  .coverImage {
    height: 300px;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1320px) {
  .container {
    height: 300px;
  }

  .coverImage {
    height: 300px;
  }
}
