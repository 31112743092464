.head {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  margin-bottom: 8px;
  display: block;
}

.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
}

.select {
  width: 100%;
}

.select input[name="acquired_date"] {
  padding: 12px 10px;
  border: none;
}

.select .MuiTextField-root {
  padding: 12px 10px;
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.locationItem {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  width: 100%;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.removeButton {
  position: absolute;
  right: 0;
  top: -10px;
}

.label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 8px;
}

div.select {
  max-width: 100%;
  width: 100%;
  flex: auto;
}

.associationName {
  width: calc(100% - 50px);
}

.input {
  margin-bottom: 20px;
  width: 100%;
}

.hidden {
  display: none;
}

@media screen and (min-width: 768px) {
  .label {
    display: none;
  }

  .name {
    display: none;
  }

  .removeButton {
    top: 27px;
  }

  .flex {
    display: flex;
    gap: 28px;
    width: calc(100% - 40px);
    margin-bottom: 20px;
  }

  .position {
    width: 100%;
  }
  .input {
    width: 100%;
  }

  .inputWrap {
    width: calc(100% - 40px);
  }

  .smallInput {
    margin-bottom: 0;
    width: 100%;
  }

  .smallWrapInput {
    width: 100%;
  }

  .nameMain {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #1c1d40;
    display: block;
  }

  .itemContent {
    width: 100%;
    max-width: 420px;
  }

  .flexItem {
    width: 100%;
  }
}

@media screen and (min-width: 1320px) {
  .locationItem {
    display: flex;
    gap: 100px;
  }

  .itemContent {
    position: relative;
  }

  .removeButton {
    right: -10px;
  }
}
