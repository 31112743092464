.main {
  background-color: #f8f8fa;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 32px 20px;
}

.content {
  width: 100%;
  max-width: 872px;
  margin: 0 auto 40px;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 40px 20px;
  }
}

@media screen and (min-width: 1320px) {
  .container {
    padding: 40px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content {
    margin: 0;
  }
}
