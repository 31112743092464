.wrapper {
  max-width: 1440px;
  margin: 0 auto 32px auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: var(--color-white-900);
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
}

.infoContent:first-child {
  display: none;
}

.infoItem {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 20px;
  margin: 0;
  align-items: center;
}

.infoItem span {
  width: 25px;
  height: 25px;
  display: block;
  flex: none;
  border-radius: 6px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .svgContent {
    display: flex;
  }

  .infoContainer {
    padding-left: 50px;
  }
}
