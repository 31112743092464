.content {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.item {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #abafb9;
}

.itemActive {
  color: #353546;
}

.icon {
  width: 16px;
  height: 16px;
}
