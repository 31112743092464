.popupItem {
  padding: 24px;
  border-bottom: 1px solid var(--color-gray-250);
}

.new {
  background-color: var(--color-blue-300);
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.timeIcon {
  position: absolute;
  left: 25px;
}

.timeText {
  padding-left: 30px;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-black-500);
  font-weight: bold;
  padding-top: 3px;
  margin-bottom: 0;
}

.readMark {
  color: var(--color-gray-500);
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
