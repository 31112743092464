.title {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .title {
    color: #1c1d40;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 0;
  }
}
