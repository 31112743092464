.head {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
}

.hidden {
  display: none;
}

.select {
  width: 100%;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.insuranceName {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  display: block;
  margin-bottom: 18px;
}

.insurance {
  margin-bottom: 24px;
  position: relative;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.insuranceContent {
  width: 100%;
}

.removeButton {
  position: absolute;
  right: 0;
  top: -10px;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 8px;
}

div.halfWidth {
  max-width: 100%;
}

div.fullWidth {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .name {
    display: none;
  }

  .removeButton {
    top: 0;
  }

  .insurance {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }

  .insuranceName {
    display: none;
  }

  .content {
    display: flex;
    align-items: flex-start;
    gap: 36px;
    padding-top: 1px;
  }

  .selectWrap {
    max-width: 180px;
  }

  div.skillLanguage {
    max-width: 330px;
    width: 330px;
  }

  div.fullWidth {
    max-width: 100%;
    width: calc(100% - 50px);
  }

  div.halfWidth {
    max-width: 100%;
    width: 100%;
    flex: auto;
  }

  .label {
    display: none;
  }

  .flex {
    display: flex;
    gap: 24px;
    padding-right: 50px;
  }

  .inputContent {
    width: 100%;
  }
}

@media screen and (min-width: 1320px) {
  .insurance {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }

  .removeButton {
    position: inherit;
    margin: 0 10px 0 0;
  }

  div.fullWidth {
    width: 230px;
  }

  .inputContent {
    width: 100%;
  }

  .flex {
    width: 100%;
    padding: 0;
  }
}
