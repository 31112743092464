.reply {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
}

.reply:disabled {
  background: #eaeaea;
  border-color: #eaeaea;
  color: #8b8b8b;
  pointer-events: none;
}

.textarea {
  width: 100%;
  min-height: 145px;
  background: var(--color-white-900);
  border: 1px solid var(--color-gray-250);
  border-radius: 6px;
  margin-bottom: 28px;
  resize: vertical;
}

.icon {
  position: absolute;
  bottom: 55px;
  right: 30px;
}
