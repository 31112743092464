.modal {
  max-width: 484px;
}

.subTitle {
  color: #8b8b8b;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.11px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 32px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}

.modalInput {
  margin-bottom: 32px;
}

.button {
  position: absolute;
  padding: 0 10px;
  right: 5px;
  top: 42px;
  display: flex;
  align-items: center;
  color: var(--color-gray-400);
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  margin: 5px 0 -10px;
}
