.content {
  max-width: 1440px;
  width: 100%;
  padding: 0 20px 40px;
  margin: 0 auto;
}

.case {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1204px;
}

.caseWrp {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 24px;
}

.emptyText {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  text-align: center;
}

.empty {
  margin: 40px auto 0;
  text-align: center;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  display: block;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .caseWrp {
    padding: 40px;
  }

  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }
}

@media screen and (min-width: 1008px) {
  .caseWrp {
    margin: 0 auto;
    width: 100%;
  }
  .content {
    display: flex;
    padding: 0 40px 40px;
  }
  .sidebar {
    margin-top: 65px;
  }
}

@media screen and (min-width: 1320px) {
  .caseWrp {
    max-width: 1204px;
  }
}
