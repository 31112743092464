.wrapper {
  max-width: 1156px;
  margin: 0 auto;
  padding: 24px 20px;
}

.heading {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.heading span {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  display: block;
}

.viewAll {
  color: #5bafd7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

.mainTitle {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
}

.title span {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.content {
  max-width: 812px;
  width: 100%;
}

.searchContent {
  position: relative;
  margin-bottom: 26px;
  max-width: 812px;
}

.searchIcon {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 16px;
  top: 50%;
  margin-top: -14px;
}

.searchIcon path,
.searchIcon circle {
  stroke: #1c1d40;
}

.reset {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #5bafd7;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.search {
  border-radius: 8px;
  border: 1px solid #d7d2d2;
  padding: 12px 80px 12px 52px;
  color: #71717a;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.following {
  margin-bottom: 40px;
  max-width: 812px;
}

.followingHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.followingTitle {
  color: #353546;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
}

.followingEdit {
  background-color: transparent;
  border: none;
  padding: 4px;
  cursor: pointer;
}

.followingEdit svg {
  width: 16px;
  height: 16px;
}

.followingEdit path {
  fill: #353546;
}

.followingList {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.followingItem {
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  background: #eaeaea;
  color: #353546;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.emptyContent {
  max-width: 400px;
  margin: 0 auto;
}

.emptyContentImage {
  display: flex;
  width: 160px;
  height: 160px;
  margin: 0 auto 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f8f8fa;
}

.emptyTitle {
  color: #1c1d40;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 4px 0;
}

.emptyDescription {
  color: #353546;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 24px 0;
  display: block;
}

.icon {
  width: 120px;
  height: 90px;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 32px;
  }

  .title span {
    font-size: 24px;
    line-height: 34px;
  }

  .mainTitle {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;
  }

  .emptyContentImage {
    width: 200px;
    height: 200px;
  }

  .icon {
    width: 120px;
    height: 90px;
  }

  .emptyTitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .emptyDescription {
    color: #353546;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

@media screen and (min-width: 1320px) {
  .wrapper {
    padding: 24px 8px;
  }

  .searchContent {
    margin-bottom: 28px;
  }

  .flex {
    display: flex;
    flex-direction: row-reverse;
    gap: 32px;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .following {
    width: 312px;
    max-width: 312px;
    padding: 24px 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 0 32px 0 rgba(20, 20, 21, 0.1);
    margin-top: 50px;
  }

  .followingTitle {
    font-size: 16px;
    line-height: 24px;
  }

  .followingHeading {
    margin-bottom: 24px;
  }

  .mainTitle {
    margin-bottom: 40px;
  }

  .container {
    margin-bottom: 48px;
  }

  .emptyTitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }
}
