.questionWrap {
  margin-bottom: 60px;
}

.questionLabel {
  display: block;
  margin-bottom: 12px;
}

.inputNumber {
  font-size: 20px;
  font-weight: 700;
  font-family: montserrat, sans-serif;
}

.input {
  margin-bottom: 30px;
}

.title {
  color: #353546;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.48px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.hr {
  margin-bottom: 24px;
}

.modalWrp {
  display: flex;
  flex-direction: column;
  height: 100%;
}
