.questionWrap {
  margin-bottom: 60px;
}

.questionLabel {
  display: block;
  margin-bottom: 12px;
}

.inputNumber {
  font-size: 20px;
  font-weight: 700;
  font-family: montserrat, sans-serif;
}

.input {
  margin-bottom: 30px;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  margin-bottom: 24px;
}

.hr {
  margin-bottom: 24px;
}

.modalWrp {
  display: flex;
  flex-direction: column;
  height: 100%;
}
