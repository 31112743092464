.newCard,
.editCard {
  display: none;
  background: var(--color-gray-150);
  width: 100%;
  overflow: hidden;
}

.selectBox > [class$="control"] {
  height: 50px;
}

.selectBox [class$="singleValue"] {
  overflow: hidden;
}

.isOpen {
  display: flex;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.disable {
  pointer-events: none;
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
  color: var(--color-gray-500);
}

.textRed {
  color: #da4655;
  margin: 0 2px;
}

.downLine {
  width: 100%;
  border-top: 1px solid #eaeaea;
  margin-bottom: 25px;
}

.modalForm {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.buttonNew {
  width: 100%;
  margin: 32px 0 0;
  padding: 18px;
}

.buttonPaymentMethod {
  max-width: 156px;
  margin-top: 8px;
}

.checkLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black-500);
}

.flexWrap,
.flexWrapFake {
  display: flex;
  flex-wrap: wrap;
}

.flexWrapFake {
  position: relative;
  pointer-events: none;
}

.flexWrapFake .inputWrap {
  margin-right: 0;
}

.fakeBrandCardIcon {
  width: 32px;
  height: 30px;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  display: flex;
}

.fakeBrandCardIcon img {
  max-width: 100%;
}

.inputWrap {
  flex: 1 1 100%;
  margin-bottom: 30px;
}

.errorText {
  margin-bottom: -10px;
}

.hiddenInput {
  display: none;
}

.cardSubtitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.checkCard {
  margin-right: 12px;
}

.selectNew {
  width: 100%;
  border: 1px solid var(--color-gray-250);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 13px 7px 15px 20px;
  background-color: var(--color-white-900);
}

.cardNewTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-blue-800);
}

.cardTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  margin-bottom: 24px;
}

.cantDeleteText {
  display: block;
  margin-bottom: 8px;
  margin-top: -18px;
  font-weight: 700;
}

.cantDeleteTextDetail {
  display: block;
  margin-bottom: 8px;
}

.cantDeleteContent {
  margin-bottom: 20px;
}

.hr {
  display: block;
  margin-bottom: 24px;
}

.checkLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black-500);
  display: inline-block;
  margin-bottom: 40px;
}

.buttonCancel {
  background: var(--color-white-900);
  border-radius: 0;
  width: 30%;
  border: none;
  border-top: 1px solid #d7d2d2;
  background-color: #eaeaea;
  color: #8b8b8b;
}

.buttonCancel:hover {
  background-color: #1c1d40;
  color: #ffffff;
  border-color: #1c1d40;
}

.numberWrap {
  display: inline-block;
  color: var(--color-gray-500);
}

.buttonChangesMargin {
  margin-bottom: 50px;
}

.layoutMain {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.6px;
  color: var(--color-gray-500);
  margin-bottom: 0;
}

.defaultCard {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.stripeIcon {
  margin: 32px auto 20px auto;
  display: block;
}

.flex {
  display: flex;
  text-align: center;
  gap: 32px;
  margin-top: 40px;
}

.stripeDisclaimer {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.layoutNumber {
  display: inline-block;
  margin-right: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  color: #1c1d40;
}

.defaultNumber {
  color: var(--color-blue-800);
}

.paymentMethod {
  padding: 0;
}

.cardWrapper {
  display: block;
}

.creditNumber {
  display: flex;
  color: var(--color-white-900);
}

.cardNumber {
  margin-right: 12px;
}

.stars {
  position: relative;
  top: 4px;
}

.creditNumber {
  margin-bottom: 8px;
}

.creditCard {
  color: var(--color-white-900);
  background: var(--color-black-500);
  border-radius: 10px;
  padding: 24px;
  position: relative;
  margin: 0 auto 16px auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  max-width: 320px;
  height: 190px;
}

.cardControl {
  display: flex;
  margin-bottom: 60px;
  justify-content: center;
}

.buttonEdit {
  color: var(--color-blue-400);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  margin-right: auto;
}

.cardsLayout {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buttonUpdate {
  width: 100%;
  padding: 15px;
}

.buttonDelete {
  color: var(--color-red-400);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: -50px 0 24px 0;
  line-height: 12px;
}

.cardsLayoutItem {
  margin-bottom: 15px;
}

.cardsLayoutItem:last-child {
  margin-bottom: 0;
}

.cardsLayoutItemDefault {
  order: -1;
}

.cardItemLabel {
  display: flex !important;
  align-items: center;
  cursor: pointer;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 70%;
}

.emptyStateButton {
  margin: 30px 0 100px 0;
  padding: 15px 20px;
  min-height: 50px;
}

.sectionHeadingDesktop {
  display: none;
}

.cardIconRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
}

.cardIconRow img:last-child {
  position: absolute;
  right: 24px;
}

.cardIconSim {
  position: absolute;
  left: 24px;
}

.cardBrandImg {
  display: flex;
  background: var(--color-white-900);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: 0 20px 0 15px;
  width: 88px;
  height: 50px;
}

.cardBrandImg img {
  display: block;
  margin: 0 auto;
  max-width: 85%;
}

.allCardBrands {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.allCardBrands img {
  margin-right: 20px;
}

.cardControl .deletePrompt {
  transform: translate(-65%, calc(-100% - 16px));
  padding: 16px;
  right: unset;
}

.cardControl .deletePrompt:after {
  right: 20%;
}

.buttonDeleteGrey {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8b8b8b;
  padding: 0;
  display: flex;
}

.tooltip {
  margin-left: 5px;
}

.textTooltip {
  width: 300px;
  transform: translate(0, calc(-100% - 15px));
  right: 0;
}

.buttonNewCardTablet {
  display: none;
}

.title {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 24px 0;
}

.addNewCardButton {
  width: 100%;
}

.remove {
  color: #5bafd7;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  background: transparent;
  padding: 12px;
  border: none;
  display: block;
  width: 100px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 20px;
    line-height: 28px;
  }
  .buttonHideTablet {
    display: none;
  }

  .buttonNewCardTablet {
    display: block;
  }

  .newCard,
  .editCard,
  .isOpen {
    padding: 0;
  }

  .buttonNewCard {
    display: block;
  }

  .cardsLayoutItem {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
  }

  .footerContent {
    display: flex;
  }

  .cardsLayoutItem:last-child {
    margin-bottom: 0;
  }

  .cardControl {
    justify-content: space-between;
    max-width: 400px;
    margin-bottom: 70px;
    line-height: 16px;
  }

  .creditCard {
    margin: 0 0 20px 0;
    width: 100%;
    max-width: 400px;
    height: 230px;
  }

  .cardIconRow {
    margin-bottom: 54px;
  }

  .stripeIcon {
    margin: 0 32px 0 0;
  }

  .stripeDisclaimer {
    text-align: left;
  }

  .creditNumber {
    margin-bottom: 50px;
  }

  .buttonPanel {
    padding: 18px;
    max-height: 52px;
    line-height: 16px;
  }

  .cardWrapper {
    display: flex;
  }

  .cardWrapperComp {
    display: flex;
    flex-direction: column;
  }

  .paymentMethod {
    width: 712px;
  }

  .newCard,
  .editCard {
    width: 100%;
  }

  .leftAside {
    min-width: 284px;
    background: rgba(9, 10, 40, 0.8);
    display: block;
    width: 100%;
  }

  .flexWrap,
  .flexWrapFake {
    flex-wrap: nowrap;
  }

  .inputWrap {
    flex: 1 1 50%;
    margin-right: 20px;
  }

  .inputWrapSingle {
    margin-right: 0;
  }

  .inputWrap:last-child {
    margin-right: 0;
  }

  .cardsLayout {
    margin-bottom: 32px;
  }

  .buttonPaymentMethod {
    margin-top: 8px;
  }

  .cardBrandImg {
    margin: 0 50px 0 40px;
  }

  .deletePrompt {
    transform: translate(-30%, calc(-100% - 16px)) !important;
  }

  .deletePrompt:after {
    right: 50% !important;
  }

  .textTooltip {
    width: 520px;
    right: auto;
  }

  .cantDeleteText {
    margin-top: -18px;
    padding-left: 65px;
  }

  .cantDeleteTextDetail {
    padding-left: 65px;
  }
}

@media screen and (min-width: 1008px) {
  .buttonNewCardTablet {
    display: block;
  }

  .leftAside {
    max-width: 956px;
    min-width: 956px;
    width: 100%;
  }

  .paymentMethod,
  .billings {
    width: 100%;
    margin-bottom: unset;
  }

  .sectionHeadingDesktop {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 50px;
  }

  .cardBrandImg {
    margin: 0 20px;
  }
}

@media screen and (min-width: 1320px) {
  .buttonNewCardTablet {
    display: none;
  }
}
