.link {
  display: none;
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  pointer-events: none;
  margin-left: auto;
}

.main {
  margin-bottom: 12px;
}

.activeLink {
  display: block;
}

@media screen and (min-width: 768px) {
  .link {
    font-size: 28px;
    line-height: 36px;
  }

  .main {
    margin-bottom: 24px;
  }

  .mainListings {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1320px) {
  .mainListings {
    margin-bottom: 40px;
  }
}
