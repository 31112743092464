.switch {
  width: 58px;
  height: 32px;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  margin-left: 1px;
}

.switchWhite {
  background-color: var(--color-gray-400);
}

.switchBlue {
  background-color: var(--color-blue-800);
}

.switch:focus,
.switch:active,
.switch:hover {
  outline: none;
}

.switchWhite:focus,
.switchWhite:active,
.switchWhite:hover {
  outline: none;
  background-color: var(--color-gray-400);
}

.switchBlue:focus,
.switchBlue:active,
.switchBlue:hover {
  background-color: var(--color-blue-800);
}

.switchCircle {
  display: block;
  width: 24px;
  height: 24px;
  top: 0;
  left: -3px;
  border-radius: 12px;
  background: white;
  position: relative;
  transition: all 0.3s;
}

.switchActive {
  background-color: var(--color-blue-800);
}

.switchActiveRed {
  background-color: var(--color-red-300);
}

.switchActive:focus,
.switchActive:active,
.switchActive:hover {
  background-color: var(--color-blue-800);
  outline: none;
  border-color: transparent;
}

.switchActiveRed:focus,
.switchActiveRed:active,
.switchActiveRed:hover {
  background-color: var(--color-red-300);
  outline: none;
  border-color: transparent;
}

.switchActive .switchCircle {
  margin-left: 25px;
}

.switchActiveRed .switchCircle {
  margin-left: 25px;
}
