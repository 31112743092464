.row {
  margin-bottom: 40px;
}

.address {
  text-transform: capitalize;
}

.item {
  width: 100%;
  flex: none;
  margin-bottom: 40px;
  order: 1;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
}

.icon {
  width: 12px;
  height: 12px;
  margin: 0 0 -1px 5px;
}

.valueWebsite {
  text-transform: inherit;
}

@media screen and (min-width: 768px) {
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    width: 50%;
  }
}

@media screen and (min-width: 1008px) {
  .item:nth-child(1) {
    order: 1;
  }

  .item:nth-child(2) {
    order: 3;
  }

  .item:nth-child(3) {
    order: 2;
  }

  .item:nth-child(4) {
    order: 4;
  }
}
