.accountNotifications {
  margin-bottom: 30px;
}

.notificationForm {
  position: relative;
  padding-bottom: 50px;
}

.hr {
  display: block;
  margin: 60px 0 20px 0;
}

.buttonUpdate {
  width: 100%;
  padding: 15px;
}

.acctSubtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.checkboxWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;
}

.checkboxWrapLeads {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.checkLabelActive {
  color: black;
}

.infoIcon {
  position: absolute;
  right: -24px;
  max-width: 16px;
  top: 3px;
}

.leadNoteText {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
}

.checkboxLabel {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 15px;
}

.tooltip {
  display: inline-block;
  width: 16px;
  margin: 0 0 0 10px;
}

.description {
  color: #61616f;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin: 0 0 16px 0;
}

.title {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 18px;
  border-top: 1px solid #eaeaea;
  padding-top: 40px;
}

.notificationDescription {
  color: #353546;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  display: block;
}

.listing {
  margin-bottom: 40px;
}

.listingHead {
  margin-bottom: 16px;
  display: flex;
  gap: 24px;
  align-items: center;
}

.listingName {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.listingEdit {
  color: #5bafd7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.accText {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin: 0 0 24px 0;
}

@media screen and (min-width: 768px) {
  .notificationForm {
    padding-bottom: 100px;
  }

  .hr {
    display: block;
    margin: 50px 0 10px 0;
  }

  .hr {
    margin: 50px 0 -20px 0;
  }

  .accountNotifications {
    width: 712px;
    background: var(--color-white-900);
    box-shadow: var(--box-shadow-300);
    border-radius: 12px;
    margin: 0 auto 60px auto;
  }

  .checkboxLabel {
    white-space: nowrap;
  }

  .acctSubtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .description {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1008px) {
  .accountNotifications {
    width: 100%;
    margin: 0;
  }

  .notificationForm {
  }
}
