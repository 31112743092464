.label {
  color: #8b8b8b;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.11px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
}

.title {
  color: #1c1d40;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  display: block;
  padding-bottom: 24px;
  margin-bottom: 32px;
  border-bottom: 1px solid #eaeaea;
}

.description {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-bottom: 32px;
}

.user {
  display: flex;
  gap: 16px;
  align-items: center;
}

.userPhoto {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: none;
  background-color: #abafb9;
}

.userPhoto svg {
  width: 20px;
  height: 20px;
}

.userInfo {
  display: block;
}

.userName {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: block;
  margin-bottom: 4px;
}

.userData {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
