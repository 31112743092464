.externalLoginBtn {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 25px;
}

.buttonLink {
  background: #2867b2;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(9, 10, 40, 0.1);
  overflow: hidden;
  z-index: 3;
  border-radius: 6px;
}

.authForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
  font-weight: 600;
}

.mainLogo {
  width: 200px;
  margin: 0 auto;
}

.google {
  width: 100%;
}

.buttonFb {
  background: #4267b2;
}

.buttonTwit {
  background: #1da1f2;
}

.icon {
  position: absolute;
  left: 25px;
  width: 20px;
  top: 10px;
}

.text {
  color: var(--color-gray-400);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 16px 0;
  text-align: center;
}

.createAccountButton {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent;
  color: #5bafd7;
  border: 1.4px solid #5bafd7;
}

.loginMethods {
  display: flex;
  flex-direction: column;
  position: relative;
}

.mb30 {
  margin-bottom: 30px;
}

.newForm {
  max-width: 424px;
  margin: 0 auto 100px;
}

.wrapperNewLogin {
  max-width: 648px;
}

div.wrapper {
  max-width: 648px;
  margin: 100px auto 0;
}

.register {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.registerText {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.registerButton {
  background-color: transparent;
  border: none;
  color: #5bafd7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    box-shadow: var(--box-shadow-300);
  }
}

@media screen and (min-width: 1320px) {
  .wrapper {
    box-shadow: var(--box-shadow-300);
  }

  .newForm {
    margin: 0 auto 200px;
  }
}
