.content {
  margin-bottom: 52px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  gap: 16px;
}

.title {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
}

.title span {
  font-weight: 400;
}

.viewAll {
  color: #5bafd7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}
