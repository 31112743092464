.downgrade {
  display: flex;
  position: relative;
  width: 160px;
  margin-left: -40px;
  margin-bottom: 10px;
}

.text,
.options {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  height: auto;
  padding: 0;
}

.options {
  color: #5bafd7;
  text-decoration: underline;
  margin-left: 5px;
  position: absolute;
  bottom: 0;
  right: 25px;
}

.options:hover {
  text-decoration: none;
  color: #5bafd7;
}

@media screen and (min-width: 768px) {
  .downgrade {
    margin: 10px 0 0 0;
  }
}

@media screen and (min-width: 1008px) {
  .downgrade {
    margin-top: 20px;
  }
}
