.forms-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  color: #5bafd7;
  border-bottom: 1px solid var(--color-gray-250);
}

.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-white-900);
  margin: 40px auto 30px auto;
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
}

.info-section__icon {
  margin: 45px 0;
}

.info-section__title {
  text-align: center;
}

.info-section__title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: var(--color-blue-800);
  line-height: 34px;
  margin-bottom: 40px;
}

.info-section__content {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 32px;
}

.not-available-wrapper {
  margin: 80px 0;
}

.purchase-already-wrapper {
  margin: 75px 0;
}

.forms__footer {
  width: 100%;
  text-align: center;
}

.form__button {
  width: 100%;
}

.header__login-link {
  color: #5bafd7;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .info-section {
    padding: 46px 20px 32px 20px;
    background: var(--color-white-900);
    box-shadow: var(--box-shadow-300);
    border-radius: 12px;
    max-width: 648px;
    margin: 100px auto;
  }

  .completed-icon,
  .not-available-icon {
    width: 120px;
  }

  .form__button {
    max-width: 648px;
    min-width: 648px;
    margin: auto;
  }

  .forms__footer {
    margin: 0 auto;
    width: auto;
  }

  .info-section__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
  }
}

@media screen and (min-width: 1320px) {
  .info-section {
    max-width: 648px;
    margin-bottom: 32px;
    margin-top: 65px;
  }

  .forms__footer {
    max-width: 648px;
    width: 100%;
    padding: 0;
    margin: auto;
  }
}
