.answersBox {
  padding: 60px 0;
}

@media screen and (min-width: 768px) {
  .selectContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1008px) {
  .answersBox {
    max-width: 872px;
    width: 100%;
    margin: auto;
  }
}
