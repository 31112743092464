.subscription {
  margin-bottom: 16px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 12px;
}

.subscriptionItem:last-child {
  margin-bottom: 0;
}

.subscriptionItem {
  color: #61616f;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  margin-bottom: 24px;
}

.subscriptionName {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 150px;
}

.subscriptionItem .cardNumber {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #353546;
  margin-left: 5px;
  display: block;
}

.subscriptionItem span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: block;
}

.subscriptionItem button {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #5bafd7;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.subscriptionItem button:hover {
  text-decoration: underline;
}

.cardBrandImg {
  width: 20px;
  height: 15px;
  flex: none;
}

.cardBrandImg img {
  max-width: 100%;
}

.cardDetail {
  display: flex;
  align-items: baseline;
}

@media screen and (min-width: 768px) {
  .subscription {
    display: flex;
    background: transparent;
    box-shadow: none;
    padding: 16px;
  }

  .subscriptionName {
    display: none;
  }

  .subscriptionItem:nth-child(1) {
    width: 240px;
    padding-right: 20px;
  }

  .subscriptionItem:nth-child(2) {
    width: 350px;
    padding-right: 80px;
  }

  .subscriptionItem:nth-child(3) {
    width: 180px;
    padding-right: 50px;
  }

  .subscriptionItem:nth-child(4) {
    width: 125px;
  }

  .subscriptionItem {
    margin: 0;
  }

  .subscriptionItem:nth-child(1) span,
  .subscriptionItem:nth-child(3) span {
    font-weight: 400;
  }
}

@media screen and (min-width: 1008px) {
  .subscriptionItem:nth-child(1) {
    width: 330px;
    padding-right: 20px;
  }

  .subscriptionItem:nth-child(2) {
    width: 330px;
    padding-right: 80px;
  }
}
