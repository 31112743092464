.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 0;
}

.inputLabel {
  color: #353546;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.inputHeading {
  display: flex;
  gap: 8px;
  align-items: center;
}

.inputFlex {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
}

.notVerified {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  border-radius: 6px;
  background: #fff3f4;
  color: #da4655;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.verified {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #edffcc;
  color: #91b848;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.blueButton {
  padding: 0;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5bafd7;
  cursor: pointer;
}

.blueButtonPhone {
  margin-left: auto;
  margin-right: 12px;
}

.blueButton:hover {
  text-decoration: underline;
}

.verificationItem {
  margin-bottom: 24px;
}

.defaultValue {
  border-radius: 8px;
  padding: 12px;
  background-color: #eaeaea;
  height: 44px;
  color: rgba(9, 10, 40, 0.8);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.verificationsContent {
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .verificationsContent {
    display: flex;
    gap: 32px;
  }

  .verificationItem {
    width: 100%;
    margin: 0;
  }
}
