.content {
  text-align: center;
  margin: 0 auto;
}

.description {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-800);
  display: block;
  margin-top: 20px;
  text-align: center;
}
