.main {
  background-color: #f8f8fa;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 32px 20px;
}

.content {
  width: 100%;
  max-width: 872px;
  margin: 0 auto 40px;
}

.back {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  padding: 0 12px;
  margin-bottom: 8px;
}

.backIcon {
  width: 52px;
  height: 52px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 40px 20px;
  }

  .back {
    margin-top: 12px;
  }
}

@media screen and (min-width: 1320px) {
  .container {
    padding: 40px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content {
    margin: 0;
  }

  .back {
    margin-bottom: 32px;
  }
}
