.container {
  max-width: 1204px;
  margin: 0 auto 40px;
  padding: 12px 20px;
}

@media screen and (min-width: 768px) {
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .flex h3 {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1320px) {
  .container {
    padding: 12px 24px;
    margin-bottom: 80px;
  }

  .flex {
    margin-bottom: 40px;
  }
}
