.g {
  margin: 0 10px;
}

.tooltipD3,
.tooltipD3Listing {
  background: #353546;
  border-radius: 8px;
  display: none;
  flex-direction: column;
  padding: 24px 24px 30px 24px;
  position: absolute;
  left: 20px;
}

.tooltipD3:after,
.tooltipD3Listing:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  background: #353546;
  transform: rotate(45deg);
}

.tooltipD3 {
  white-space: nowrap;
  text-align: center;
}

.tooltipD3Listing {
  width: 150px;
  height: 140px;
}

.tooltipD3ListingBig {
  width: 300px;
  min-height: 226px;
  height: auto;
  padding: 24px 32px;
  border-radius: 8px;
}

.tooltipD3Listing:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 40%;
  width: 20px;
  height: 20px;
  background: #353546;
  transform: rotate(45deg);
}

.svgWrap {
  display: none;
}

.svgWrap:nth-child(1) {
  display: initial;
}

.svgLine {
  color: red;
}

.svg_diagram_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.svg_diagram_income_distribution_bar_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  font-family: "openSans, sans-serif";
}

.svg_diagram_leads_xaxis {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  font-family: "openSans, sans-serif";
}

.svg_diagram_income_distribution_small {
  font-family: "openSans, sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.svg_diagram_legend_element {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.svg_diagram_pie_legend_element {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #353546;
}

.svg_diagram_axis_left {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-400);
}
.svg_diagram_axis_bottom {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #353546;
}

.svg_diagram_income_distribution_small_axis_bottom {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #353546;
}

.dateTool {
  color: var(--color-white-900);
  text-align: center;
}

.name {
  font-weight: 800;
  font-size: 44px;
  color: var(--color-white-900);
  word-break: break-word;
  margin: 0;
}

.sub_name {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: var(--color-white-900);
}

.tooltipTextD3 {
  color: var(--color-white-900);
  font-size: 10px;
  margin-left: 10px;
  overflow: hidden;
  word-break: break-word;
  line-height: 14px;
  margin-bottom: 0;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.tooltipLegendD3 {
  width: 290px;
  background: #353546;
  border-radius: 8px;
  display: none;
  flex-direction: column;
  padding: 12px;
  position: absolute;
  left: 20px;
}

.relative {
  position: relative;
}

.tooltipLegendD3:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  background: #353546;
  transform: rotate(45deg) translateX(-50%);
  box-sizing: border-box;
}

.linkPanelD3 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.tooltipLegendD3Text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.tooltipLegendD3 .linkPanelD3 {
  margin-bottom: 0;
}

.xAxisD3 line {
  stroke: var(--color-gray-250);
}

.xAxisD3 path {
  stroke: var(--color-gray-250);
}

.xAxisD3 text {
  fill: #353546;
  font-weight: 600;
  font-size: 14px;
}

.yAxisD3 path {
  stroke: none;
}

.yAxisD3 text {
  fill: var(--color-gray-400);
  font-weight: 600;
  font-size: 14px;
}

.linkPanelD3 svg {
  width: 20px;
  height: 20px;
}

.grid-line {
  stroke: var(--color-gray-250);
  shape-rendering: crispedges;
  stroke-opacity: 0.2;
  stroke-dasharray: 5, 5;
}

.nameValue {
  font-weight: 800;
  font-size: 44px;
  line-height: 64px;
  color: #ffffff;
  display: block;
  margin-bottom: 20px;
}

.subNameValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  display: block;
  margin-bottom: 20px;
}

.tooltipTextD3Name {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.dotColor {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: block;
  flex: none;
}

.linkPanelD3Big {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
