.wrapper {
  max-width: 1156px;
  margin: 0 auto;
  padding: 0 20px;
}

.emptyContent {
  display: flex;
  width: 160px;
  height: 160px;
  margin: 0 auto 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f8f8fa;
}

.icon {
  width: 120px;
  height: 90px;
}

@media screen and (min-width: 1320px) {
  .wrapper {
    padding: 24px 8px;
  }

  .emptyContent {
    width: 200px;
    height: 200px;
  }

  .icon {
    width: 120px;
    height: 90px;
  }
}
