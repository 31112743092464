.sidebar {
  width: 236px;
  background-color: #fff;
  flex: none;
  padding: 32px 24px;
  display: none;
  z-index: 9;
  position: absolute;
  left: 0;
  top: 0;
}

.sidebarActive {
  display: block;
}

.overlay {
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: none;
}

.overlayActive {
  display: block;
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 34px;
}

.logo a {
  padding: 0;
}

.link,
.logout {
  color: #61616f;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  margin: 0 0 24px 0;
  text-align: center;
  align-items: center;
  gap: 8px;
  padding: 6px 4px;
}

.logout {
  border: none;
  cursor: pointer;
  padding-left: 8px;
  background-color: transparent;
}

.logout svg {
  fill: #61616f;
}

.activeLink {
  border-radius: 6px;
  background: #e8f7fc;
  color: #5bafd7;
}

.link path,
.link circle,
.link rect,
.link ellipse {
  stroke: #61616f;
}

.link svg {
  max-width: 24px;
  max-height: 24px;
  flex: none;
}

.activeLink svg path,
.activeLink svg circle,
.activeLink svg rect,
.activeLink svg ellipse {
  stroke: #5bafd7;
}

.inbox {
  display: none;
  min-width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 100%;
  background: #2c2d64;
}

.inboxActive {
  display: flex;
}

@media screen and (min-width: 1320px) {
  .sidebar {
    display: block;
    position: inherit;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    z-index: 0;
    border-right: 1px solid #eaeaea;
  }

  .overlayActive,
  .overlay {
    display: none;
  }

  .shortSidebar {
    width: 94px;
    padding: 0;
  }

  .shortSidebar .link {
    flex-direction: column;
  }

  .shortSidebar .inbox {
    margin: 0 auto;
  }

  .shortSidebar .logo {
    width: 38px;
    height: 42px;
    margin: 32px auto 40px auto;
  }
}
