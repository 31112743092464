.item {
  background: #ffffff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  overflow: hidden;
  padding: 12px 12px 12px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 349px;
  width: 100%;
  flex: none;
}

.text {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1c1d40;
}

.color {
  position: absolute;
  width: 12px;
  height: 100%;
  left: 0;
  top: 0;
  background: #5bafd7;
}

.item:nth-child(1n + 6) .color {
  background: #ac4b77;
}

.item:nth-child(1n + 10) .color {
  background: #91b848;
}

@media screen and (min-width: 1008px) {
  .item {
    padding: 24px 24px 24px 28px;
  }
}
