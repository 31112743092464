.container {
  background-color: #f8f8fa;
}

.content {
  max-width: 1320px;
  padding: 20px;
  margin: 0 auto;
}

.leftSide {
  max-width: 904px;
  margin: 0 auto;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 40px 20px;
  }

  .title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 24px;
  }

  .description {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1320px) {
  .content {
    padding: 22px 0;
    display: flex;
    gap: 80px;
    justify-content: space-between;
  }

  .leftSide {
    margin: 0;
  }
}
