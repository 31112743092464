.previousIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  padding: 0;
  display: none;
}

@media screen and (min-width: 1008px) {
  .previousIcon {
    display: inline-block;
  }
}

@media screen and (min-width: 1320px) {
  .previousIcon {
    left: 150px;
  }
}
