.flex {
  display: flex;
  margin-bottom: 24px;
  margin-top: 30px;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #1c1d40;
}

.description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 50px;
}

.button {
  display: block;
  margin: 0 -40px;
  border-radius: 0;
  background-color: #eaeaea;
  color: #8b8b8b;
  border-top: 1px solid #d7d2d2;
}

.button:hover {
  background-color: #1c1d40;
  color: #ffffff;
  border-color: #1c1d40;
}

.icon {
  width: 52px;
  display: block;
  margin-left: 100px;
}
