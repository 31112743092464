.input {
  border: 1px solid #d7d2d2;
  border-radius: 6px;
  padding: 15px 20px;
  font-size: 14px;
}

.dateInput {
  font-size: 14px;
}

.dateInput:hover {
  background-color: unset;
}

.dateInput:focus {
  outline: none;
  background-color: unset;
  border: unset;
}

.select > [class$="control"] {
  min-height: 52px;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 8px;
}

.asterisk {
  color: var(--color-red-400);
  margin: 0 2px;
}

.invalid,
.selectInvalid > [class$="control"] {
  border-color: var(--color-red-400);
  background-color: var(--color-red-100);
}

.invalid:focus,
.invalid:active {
  border-color: #da465582;
  background-color: #f9e1e491;
}

.invalid:hover {
  background-color: #f9e1e491;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  margin: 5px 0 -10px;
}

.datePicker {
  width: 100%;
}

.datePicker input {
  padding: 14.5px 15px;
}

.datePicker input:focus {
  border: none;
}

.greyDatePicker {
  background: #f8f8fa;
}

.greyDatePicker input {
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.greyDatePicker fieldset {
  border: none;
}

.greyDatePicker > div {
  display: flex;
  flex-direction: row-reverse;
}

.datePickerTime svg,
.datePickerTime button {
  display: none;
  padding: 0;
}

.datePickerTime input {
  margin-left: -10px;
}

.datePickerTime {
  width: 60px;
}

.datePickerTime > div {
  padding: 0;
}

.selectInvalid [class$="MuiInputAdornment"] {
  display: none;
}
