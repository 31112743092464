.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
}

.gallery {
  position: relative;
}

.galleryRight {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
}

.galleryRight span {
  width: calc(33.33% - 8px);
  object-fit: cover;
}

.galleryRight img {
  height: 100%;
}

.galleryRight span:nth-child(3) {
  display: none;
}

.galleryLeft {
  margin-bottom: 8px;
  height: 295px;
}

.galleryLeft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.allPhotos {
  border-radius: 8px;
  background: #f4f5f6;
  color: #353546;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: none;
  padding: 12px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
    display: flex;
    justify-content: space-between;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 8px;
  }

  .galleryLeft {
    flex: 1;
  }

  .galleryRight {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    flex: 1;
  }

  .galleryRight span {
    width: calc(50% - 8px);
  }

  .galleryRight span:nth-child(3) {
    display: block;
  }

  .galleryLeft {
    margin-bottom: 0;
  }
}
