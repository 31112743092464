.location {
  position: relative;
  margin-bottom: 40px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin: 0 0 24px 0;
}

.select,
.input {
  width: 100%;
  margin-bottom: 16px;
}

.select [class$="control"] {
  max-height: 44px;
  min-height: 44px;
  border-radius: 8px;
  border-color: #eaeaea;
}

.inputField {
  padding: 11px 12px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

@media screen and (min-width: 768px) {
  .title {
    margin-bottom: 40px;
  }

  .select,
  .input {
    margin-bottom: 0;
    width: 100%;
  }

  .item {
    display: flex;
    gap: 32px;
    margin-bottom: 24px;
  }

  .item:last-child {
    margin-bottom: 0;
  }
}
