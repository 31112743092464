.historyContent {
  margin: 40px auto;
}

.row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.row:first-child {
  padding: 0;
}

.itemLeft {
  width: 300px;
  padding-right: 10px;
}

.itemRight {
  width: 45%;
}

.label,
.title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-500);
  text-transform: uppercase;
  margin-bottom: 0;
}

.title {
  color: #5bafd7;
  letter-spacing: 0.04em;
  margin-bottom: 24px;
}

.textContent {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  margin: 0;
}

.textContentBold {
  font-weight: 600;
}

.rowContent {
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

.date {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin: 0;
}

.status {
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border-radius: 6px;
  display: inline-block;
}

.statusYellow {
  background-color: #fdf2da;
  color: #f7bf44;
}

.statusGreen {
  background-color: #e9f1da;
  color: #91b848;
}

.statusRed {
  background-color: #f9e1e4;
  color: #da4655;
}
