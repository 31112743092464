.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
}

.title {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
}

.description {
  color: #353546;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
}
