.login {
  padding: 32px 20px;
  position: relative;
  border-radius: 8px;
}

.loginWrp {
  max-width: 528px;
  margin: 0 auto;
}

.login-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 50px;
}

.login-banner__title {
  margin-bottom: 100px;
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.login-banner__logo {
  height: 54px;
  margin: 0 auto 24px auto;
}

.login-banner__content {
  text-align: center;
  font-weight: 600;
}

.login-banner__content--set-new-pass {
  padding: 0 50px;
  margin-top: 50px;
}

.login-banner__content--deep {
  margin-top: 100px;
}

.button--blue {
  padding: 15px 0;
  margin-bottom: 24px;
  font-weight: 600;
}

.password-prompt {
  display: flex;
  align-items: baseline;
  margin-bottom: 24px;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray-250);
}

.password-prompt__reset-link {
  color: var(--color-gray-500);
}

.password-prompt__reset-link:hover,
.password-prompt__reset-link:focus {
  text-decoration: underline;
}

.auth-page__link {
  text-align: center;
  margin-bottom: 20px;
  color: #5bafd7;
  display: block;
}

@media screen and (min-width: 768px) {
  .login {
    background-color: var(--color-white-900);
    margin: 64px auto;
    max-width: 728px;
    padding-top: 60px;
  }

  .login-banner__title {
    margin-bottom: 40px;
    font-size: 32px;
  }

  .login-banner__content {
    font-weight: bold;
    font-size: 18px;
  }

  .button--blue {
    margin-bottom: 30px;
  }

  .login-banner__logo {
    margin: 0 auto 40px;
    height: 89px;
    width: 80px;
  }

  .auth-page__link {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .login-banner__logo {
    margin: 0 auto 40px;
    height: 58px;
    width: 65px;
  }

  .login {
    margin: 50px auto;
    padding: 32px 60px;
    height: 100%;
  }

  .login-banner__title {
    font-size: 32px;
    text-align: center;
  }

  .login-banner__content {
    font-size: 18px;
  }
}
