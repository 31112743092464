.title {
  font-style: normal;
  margin-bottom: 20px;
}

.titleLink {
  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.titleLink svg {
  width: 16px;
  height: 16px;
}

.titleLink path {
  stroke: #61616f;
}

.titleLink:hover {
  text-decoration: underline;
}

.title {
  color: #353546;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
  display: block;
}

.description {
  overflow: hidden;
  color: #353546;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-bottom: 16px;
}

.box {
  padding: 16px;
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  position: relative;
  border: 1px solid #eaeaea;
  margin-bottom: 16px;
}

.hr {
  display: block;
  margin: 16px 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.city {
  color: var(--color-gray-500);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.dateFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.link {
  width: 100%;
  padding: 7px 15px;
  color: var(--color-gray-500);
  background-color: var(--color-white-900);
  border: 1.4px solid var(--color-gray-250);
  border-radius: 6px;
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
}

.link:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

.favorite {
  margin-bottom: 20px;
}

.date {
  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.box:last-child {
  margin-bottom: 0;
}

.questionHome,
.answerQA,
.questionQA {
  display: none;
}

.iconButton,
.iconButtonFilled {
  padding: 0;
  text-align: right;
}

.iconButton:hover svg {
  fill: var(--color-blue-400);
}

.iconFill {
  display: none;
}

.iconButtonFilled svg {
  fill: var(--color-blue-400);
}

.answerFlex {
  margin-bottom: 8px;
  order: 2;
}

.iconFilled {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../../../../media/icons/saved.svg");
}

.iconButton:hover .iconFill {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../../../../media/icons/saved.svg");
}

.time {
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.iconButtonDesktop {
  display: none;
}

.cityMobile,
.iconButtonMobile {
  display: block;
}

.info {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (min-width: 768px) {
  .iconButtonDesktop {
    display: block;
  }

  .cityMobile,
  .iconButtonMobile {
    display: none;
  }
  .dateFlex {
    flex-direction: row-reverse;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }

  .home {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .container {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .answerFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }

  .titleLink {
    margin-top: 0;
  }

  .website {
    padding: 0 40px;
  }

  .link {
    max-width: 100px;
  }

  .box {
    margin-bottom: 16px;
  }

  .questionQA {
    display: inline-block;
    position: absolute;
    left: 24px;
    top: 29px;
    width: 26px;
  }

  .answerQA {
    display: inline-block;
    position: absolute;
    left: 23px;
    height: 30px;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 16px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .info {
    gap: 24px;
  }

  .description {
    margin-bottom: 24px;
  }

  .container {
    padding-top: 24px;
    border-top: 1px solid #eaeaea;
  }
}
