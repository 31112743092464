/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 21, 2020 */

@font-face {
  font-family: "openSans";
  src: url("opensans-regular-webfont.woff2") format("woff2"), url("opensans-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "openSans";
  src: url("opensans-semibold-webfont.woff2") format("woff2"), url("opensans-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "openSans";
  src: url("opensans-bold-webfont.woff2") format("woff2"), url("opensans-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "openSans";
  src: url("opensans-extrabold-webfont.woff2") format("woff2"), url("opensans-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "open_sanslight";
  src: url("opensans-light-webfont.woff2") format("woff2"), url("opensans-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
