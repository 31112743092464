.input {
  background: #f8f8fa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  padding: 9px 10px;
  border: 1px solid transparent;
  margin-bottom: 16px;
}

.classNameInvalid {
  outline: 1px solid #da4655;
}

input:focus {
  outline: none;
  border: 1px solid #5bafd7;
}

input:hover {
  background: #f8f8fa;
}

.input::placeholder {
  color: #abafb9;
}

.errorClassName {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #da4655;
  text-transform: inherit;
  text-align: left;
  margin: 0 0 8px 0;
}
