.label {
  color: #8b8b8b;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.11px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
}

.title {
  color: #1c1d40;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  display: block;
  padding-bottom: 24px;
  margin-bottom: 32px;
  border-bottom: 1px solid #eaeaea;
}

.modalInput {
  margin-bottom: 16px;
}

.permission {
  margin-top: 40px;
}

.permissionTitle {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.checkboxName {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 5px;
}

.remove {
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  display: block;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 0;
}
