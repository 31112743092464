.statusContent {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #fff;
  padding: 12px;
  margin-bottom: 16px;
}

.publishOn {
  color: rgba(9, 10, 40, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent;
  width: 100%;
  margin-bottom: 16px;
}

.publishOnActive svg {
  transform: rotate(180deg);
}

.status {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.selectWrap {
  margin-bottom: 0;
  max-width: 200px;
}

.selectWrap div[class$="control"] {
  min-height: 34px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #abafb9;
}

.neverPublish {
  padding: 10px 12px;
  color: rgba(9, 10, 40, 0.8);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #abafb9;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 230px;
}

.selectWrap [class$="singleValue"] {
  position: relative;
  padding-left: 10px;
}

.selectWrap [class$="singleValue"]:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #da4655;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
}

.selectWrapActive [class$="singleValue"]:before {
  background: #91b848;
}

.dotRed {
  width: 6px;
  height: 6px;
  background: #da4655;
  display: block;
  border-radius: 50%;
}

.publishItemButton {
  border-radius: 0;
  border: none;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.publishItemHeading {
  display: flex;
  border-bottom: 1px solid #d7d2d2;
  background: #fff;
  padding: 0 8px;
}

.publishItemHeadingButtonActive {
  background: #f2fafd;
}

.dropdownAction {
  background: transparent;
  border: none;
  width: 200px;
  text-align: right;
  flex: none;
}

.publishItemHeadingButtonActive svg {
  transform: rotate(180deg);
}

.publishItemContent {
  border-radius: 8px;
  background: #f2fafd;
  padding: 16px 8px 32px;
  display: none;
}

.publishItemContentActive {
  display: block;
}

.publishItemAvailability {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 124px;
  margin-bottom: 16px;
}

.publishItemLabel {
  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.publishItemSocials {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0 0;
}

.container {
  border-radius: 12px;
  background: #fff;
  padding: 12px;
}

.publishDirectories {
  margin-bottom: 16px;
}

.publishList {
  display: none;
}

.publishListActive {
  display: block;
}

.publishItemAvailability i {
  margin: 0;
}

.heading {
  border-radius: 8px 0px 8px 0px;
  background: #eaeaea;
  display: none;
  overflow: hidden;
  border: 1px solid #ebebeb;
}

.headingItem {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 14px 0;
  text-align: center;
}

.social svg {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 24px;
    background-color: #fff;
    border-radius: 12px;
  }

  .publishDirectories {
    margin-bottom: 32px;
  }

  .selectWrap div[class$="control"] {
    min-height: 42px;
  }

  .publishItemLabel {
    display: none;
  }

  .publishItemContentActive {
    display: block;
  }

  .publishItemAvailability {
    width: auto;
    display: block;
    margin: 0;
  }

  .publishItemContainer {
    display: flex;
    border: 1px solid #e1e3ea;
    border-top: none;
  }

  .publishItemButton {
    background: #fff;
    width: 180px;
    border: none;
    padding: 0 16px;
    height: 60px;
    flex: none;
  }

  .dropdownAction {
    display: none;
  }

  .publishItemHeading {
    border-bottom: none;
    padding: 0;
  }

  .publishItemContent {
    background: #fff;
    display: flex;
    padding: 0;
    width: 100%;
  }

  .publishItemSocials {
    height: 60px;
    padding: 0 24px;
    margin-left: auto;
  }

  .publishItemCheckBoxContent {
    width: 100px;
    height: 60px;
    border-left: 1px solid #e1e3ea;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .publishItemCheckBoxContent:nth-child(3n) {
    border-right: 1px solid #e1e3ea;
  }

  .heading {
    display: flex;
  }

  .headingItem {
    flex: none;
    border-right: 1px solid #dedfe1;
    width: 100px;
  }

  .headingItem:first-child {
    width: 181px;
    text-align: left;
    padding: 14px 16px;
  }

  .headingItem:last-child {
    border-right: none;
    width: 240px;
    margin-left: auto;
  }

  .publishOn {
    display: none;
  }

  .statusContent {
    margin-bottom: 0;
    padding: 24px;
    border-radius: 12px 12px 0 0;
  }

  .container {
    border-radius: 0 0 12px 12px;
    padding-top: 0;
  }

  .publishList {
    display: block;
  }
}

@media screen and (min-width: 1300px) {
  .publishItemButton {
    width: 260px;
    padding: 0 24px;
  }

  .publishItemCheckBoxContent {
    width: 180px;
    flex: none;
  }

  .publishItemSocials {
    margin-left: 0;
    justify-content: center;
    width: 100%;
  }

  .headingItem {
    flex: none;
    border-right: 1px solid #dedfe1;
    width: 180px;
  }

  .headingItem:first-child {
    width: 261px;
    padding: 14px 16px;
  }

  .headingItem:last-child {
    width: 260px;
    margin-left: 0;
  }
}
