.wrapper {
  max-width: 1116px;
  margin: 0 auto;
  padding: 0 10px;
}

.title {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1008px) {
  .title {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 70px;
  }
}
