.banner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #5bafd7;
  padding: 24px 26px;
  max-width: 1204px;
  margin: 0 auto;
}

.bannerText {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.bannerText span {
  font-size: 16px;
  font-weight: 600;
}

.buttonImport {
  color: #1c1d40;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  background: #fff;
  width: 160px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .banner {
    padding: 20px;
  }
}

@media screen and (min-width: 1008px) {
  .banner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .bannerText {
    margin: 0;
  }
}
