.modal {
  max-width: 400px;
  margin-left: auto;
  transform: translate(-50%, -50%);
}

.content {
  text-align: center;
  padding: 10px 0;
}

.icon {
  width: 64px;
  height: 64px;
  display: block;
  margin: 0 auto 12px;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-800);
  margin-bottom: 12px;
}

.text {
  color: #353546;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;
}

.submit {
  display: flex;
  align-self: center;
  width: 100%;
  gap: 8px;
  min-width: 100%;
}

.submit svg path {
  fill: var(--color-white-900);
}

.closeIcon {
  display: none;
}
