.title {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
}

.tile {
  margin-bottom: 12px;
  padding: 16px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.04);
}

.name {
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 16px 0;
}

.location {
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 24px;
}

.flex {
  margin-bottom: 24px;
}

.locationName {
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}

.locationAddress {
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
}

.locationAddress svg {
  width: 20px;
  height: 20px;
}

.locationPhone {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 0;
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.locationPhone span {
  display: block;
  margin-bottom: 10px;
}

.locationPhone span:last-child {
  margin-bottom: 0;
}

.locationPhone svg {
  width: 20px;
  height: 20px;
}

.locationPhone path {
  fill: #abafb9;
}

.websiteTitle {
  color: #353546;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
}

.website {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: underline;
}

.website:hover {
  text-decoration: none;
}

.days {
  margin-top: 24px;
}

.daysTitle {
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
}

.dayItem {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 8px;
}

.weekDay {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 76px;
}

.workingHours {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-bottom: 4px;
}

.workingHours:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .location {
    display: flex;
    gap: 120px;
    align-items: flex-start;
  }

  .days {
    margin-top: 0;
  }

  .name {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .locationName {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .locationAddress {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 18px;
  }

  .locationPhone {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .locationPhone span {
    margin-bottom: 12px;
  }
}
