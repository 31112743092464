.subTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  margin-bottom: 32px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  margin: 0;
}

.modalControl {
  margin-top: 40px;
}
