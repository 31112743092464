.title {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 24px;
}

.heading {
  background: #f8f8fa;
  padding: 14px 16px;
  display: none;
}

.headingItem {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #353546;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .heading {
    margin-bottom: 16px;
    display: flex;
  }

  .headingItem:nth-child(1) {
    width: 240px;
    padding-right: 20px;
  }

  .headingItem:nth-child(2) {
    width: 325px;
    padding-right: 20px;
  }

  .headingItem:nth-child(3) {
    width: 174px;
    padding-right: 50px;
  }

  .headingItem:nth-child(4) {
    width: 125px;
  }

  .main {
  }
}

@media screen and (min-width: 1008px) {
  .main {
  }

  .headingItem {
    flex: none;
  }

  .headingItem:nth-child(1) {
    width: 330px;
    padding-right: 20px;
  }
}
