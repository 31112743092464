.photoContainer {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #fff;
  position: relative;
}

.photoDefault {
  background-color: #abafb9;
}

.photo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.cameraIcon {
  width: 38px;
  height: 38px;
}

.photoContainerSmall {
  width: 40px;
  height: 40px;
}

.photoContainerSmall .cameraIcon {
  width: 16px;
  height: 16px;
}

.text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  display: none;
}

@media screen and (min-width: 768px) {
  .photoContainer {
    width: 200px;
    height: 200px;
  }

  .cameraIcon {
    width: 64px;
    height: 64px;
  }

  .photoContainerSmall {
    width: 75px;
    height: 75px;
  }

  .photoContainerSmall .cameraIcon {
    width: 24px;
    height: 24px;
  }

  .text {
    display: block;
  }
}
