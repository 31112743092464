.filter {
  margin-bottom: 36px;
}

.filterMobile {
  display: flex;
  align-items: center;
  gap: 16px;
}

.label {
  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.filterDesktop {
  display: none;
}

.selectWrapper {
  width: calc(100% - 70px);
}

.select > [class$="control"] {
  min-height: 48px;
}

.filterButton {
  padding: 16px 25px;
  border-radius: 6px;
  color: #353546;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.filterButtonActive {
  background-color: #5bafd7;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .filterDesktop {
    display: flex;
    align-items: center;
  }

  .filterMobile {
    display: none;
  }

  .filter {
    margin-bottom: 44px;
  }
}
