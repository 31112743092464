.boxTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.box {
  max-width: none;
  width: auto;
  margin-bottom: 24px;
}

.empty {
  max-width: 350px;
  padding: 0 10px;
  margin: 40px auto 0;
  text-align: center;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  margin-top: 10px;
}

.content {
  width: 100%;
}

.pagination {
  margin-top: 24px;
}
