.tile {
  position: relative;
  max-width: 320px;
  margin: 0 auto 16px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.14);
  padding: 12px;
}

.tileExpand {
  max-width: 812px;
  margin-bottom: 16px;
}

.favorites {
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.favoritesButton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f8f8fa;
  box-shadow: 0 8px 24px 0 rgba(44, 44, 44, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
}

.favoritesButton:hover {
  background: #f8f8fa;
}

.favoritesButton svg {
  width: 24px;
  height: 24px;
}

.favoritesButton path {
  stroke: #abafb9;
}

.favoritesButtonActive svg {
  fill: #5bafd7;
}

.favoritesButtonActive path {
  stroke: #5bafd7;
}

.tileTitle {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

.tileDescription {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  padding-top: 12px;
  border-top: 1px solid #eaeaea;
}

.locationContent {
  display: flex;
  align-content: center;
  gap: 6px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.location {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.heading {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
}

.headingContent {
  padding-right: 40px;
}

.tileImage {
  width: 100px;
  height: 108px;
  flex: none;
  display: block;
}

.tileImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tilePosition {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
}

.sponsor {
  border-radius: 6px;
  background: #f4f5f6;
  padding: 4px 12px;
  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.sponsorMobile {
  display: inline-block;
  margin-bottom: 8px;
}

.practices {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.practicesContent {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tileFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.experience {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.phone {
  color: #5bafd7;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #5bafd7;
  padding: 10px;
  border-radius: 6px;
}

.phone svg {
  width: 20px;
  fill: #5bafd7;
}

.phone path {
  stroke: #5bafd7;
}

.tileInfoTablet {
  display: none;
}

.sponsorTablet {
  display: none;
}

.flexExpand .tile {
  height: auto;
}

@media screen and (min-width: 768px) {
  .tileTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    padding-right: 50px;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
  }

  .flexExpand {
    display: block;
  }

  .flexExpand .tile {
    width: 100%;
    max-width: 100%;
  }

  .tile {
    margin: 0;
    max-width: 50%;
    flex: 0 1 calc(50% - 12px);
  }

  .tileExpand {
    max-width: 812px;
    margin-bottom: 24px;
  }

  .tileExpand .tileTitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .tileExpand .tilePosition {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: block;
    margin-bottom: 16px;
  }

  .tileExpand .practices {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .tileExpand .tileImage {
    width: 166px;
    height: 166px;
    flex: none;
  }

  .tileExpand .tileInfoTablet {
    display: block;
    margin-top: 16px;
  }

  .tileExpand .tileInfoMobile {
    display: none;
  }

  .tileExpand .headingContent {
    width: 100%;
    padding-right: 0;
  }

  .tileExpand .experience {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .tileExpand .tileDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .tileExpand .headingFlex {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;
  }

  .tileExpand .sponsorTablet {
    display: block;
    margin: 0;
  }

  .tileExpand .sponsorMobile {
    display: none;
  }

  .locationContent {
    gap: 16px;
  }

  .locationContentMobile {
    display: none;
  }
}

@media screen and (min-width: 1320px) {
  .tile {
    flex: 1 1 calc(33.33333% - 16px);
    max-width: calc(33.33333% - 16px);
  }

  .favoritesButton:hover {
    background: #2c2d64;
  }
}
