.content {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 12px;
  margin-bottom: 20px;
  position: relative;
}

.spinner {
  display: none;
}

.overlay {
  background: rgba(0, 0, 0, 0.1);
}

.photoContainerRequired {
  border-color: #da4655;
}

.photoContainer {
  margin: auto;
}

.fileInput {
  display: none;
}

.errorText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #da4655;
  text-transform: capitalize;
  text-align: left;
}

.leftSection {
  max-width: 200px;
  margin: 0 auto 24px auto;
  position: relative;
}

.edit {
  background-color: #eaeaea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 155px;
  right: 15px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.relative {
  position: relative;
}

.edit:hover {
  background-color: #dfe1e2;
}

.edit svg {
  width: 16px;
}

.required {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #abafb9;
  text-align: center;
  display: block;
  margin-top: 20px;
}

.sizeInfo {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #abafb9;
  display: block;
  text-align: center;
  margin-top: 12px;
}

.requiredError {
  color: #da4655;
}

.input {
  width: 100%;
  max-width: 400px;
  margin-top: 8px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  padding: 11px 12px;
  background-color: #fff;
}

.inputInvalid {
  background-color: #fff;
  border-color: #da4655;
}

.input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #abafb9;
}

.inputContent {
  margin-bottom: 16px;
}

.input:hover,
.input:focus {
  background-color: transparent;
}

.rightSection {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 20px;
    display: flex;
    gap: 40px;
  }

  .leftSection {
    margin: 0;
  }

  .input {
    margin-top: 12px;
  }

  .inputContent {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1320px) {
  .content {
    padding: 28px;
  }
}
