.modal {
  max-width: 340px;
  margin-left: -170px;
}

.modalHide {
  padding: 0;
  background-color: transparent;
}

.button {
  display: none;
}
