.title {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
}

.wrapper {
  margin: 0 auto;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  max-width: 1204px;
}

.title {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .title {
    color: #1c1d40;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1008px) {
  .wrapper {
    padding: 12px 24px;
  }
}

@media screen and (min-width: 1320px) {
  .title {
    margin-bottom: 40px;
  }
}
