.statuses {
  display: block;
  position: relative;
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  max-width: 1204px;
  margin: 0 auto 24px auto;
  width: 100%;
  overflow: hidden;
  border: 1px solid #eaeaea;
}

.bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 58px;
  flex: 1 1 50%;
  flex-grow: 1;
  color: var(--color-white-900);
  font-weight: 600;
}

.barReceived {
  background: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #da4655;
  display: flex;
}

.barRejected {
  background: #f9e1e4;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #da4655;
  padding-left: 15px;
}

.requestReceived {
  background: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #abafb9;
}

.barCorner:after {
  content: "";
  position: absolute;
  right: -26px;
  top: 0;
  z-index: 1;
  width: 58px;
  height: 58px;
  background-color: #ffffff;
  transform: rotate(45deg);
  border: 1px solid #eaeaea;
  border-left: none;
  border-bottom: none;
}

.requestReceived:after {
  background-color: #ffffff;
}

.barPending {
  background: #f2fafd;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #5bafd7;
  padding-left: 15px;
}

.barPending:after {
  background-color: #f2fafd;
}

.barMissingInformation:after {
  background: #f9e1e4;
}

.barMissingInformation {
  background: #f9e1e4;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #da4655;
  padding-left: 20px;
}

.barReviewed {
  background: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #abafb9;
  padding-left: 20px;
}

.barReviewed:after {
  background: #ffffff;
}

.barMatch {
  background: #fdf2da;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #e16d4f;
  padding-left: 15px;
}

.barNotMatch {
  background: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #abafb9;
  padding-left: 15px;
}

.barMatches {
  background: #5bafd7;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding-left: 15px;
}

.bar p {
  margin: 0;
}

.checkIcon {
  margin-right: 15px;
}

.bar:last-child,
.bar:only-child {
  margin-bottom: 0;
}

.profileLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-blue-700);
  color: var(--color-white-900);
  min-height: 52px;
  font-size: 16px;
  line-height: 27px;
  padding-left: 30px;
}

.profileLinkInactive {
  background: var(--color-white-900);
  color: #d7d2d2;
  cursor: unset;
}

.profileLinkIcon {
  fill: var(--color-white-900);
  margin-right: 10px;
}

.profileLinkIconInactive {
  fill: #d7d2d2;
}

.pendingBar {
  font-size: 16px;
  line-height: 27px;
  color: var(--color-gray-500);
  padding: 16px 40px;
  background: var(--color-white-900);
  white-space: nowrap;
}

.pendingBar::after {
  content: "";
  position: absolute;
  right: -55px;
  top: -3px;
  border: 32px solid transparent;
  border-left: 24px solid var(--color-white-900);
  z-index: 1;
}

.statusBarDraft {
  text-transform: capitalize;
  background: var(--color-white-900);
  color: rgba(139, 139, 139, 0.8);
  padding: 16px 20px 16px 40px;
  font-size: 18px;
}

.statusBarDraft::after {
  border-left: 24px solid var(--color-white-900);
}

.statusBarPaused {
  text-transform: capitalize;
  background: #fdf2da;
  color: var(--color-gray-500);
}

.statusBarPaused::after {
  border-left: 24px solid #fdf2da;
}

.statusBarPayment {
  text-transform: capitalize;
  background: var(--color-red-100);
  color: var(--color-red-400);
}

.statusBarPayment::after {
  border-left: 24px solid var(--color-red-100);
}

.statusBarApproved {
  text-transform: capitalize;
  background: var(--color-yellow-100);
  color: var(--color-black-500);
}

.statusBarApproved::after {
  border-left: 24px solid var(--color-yellow-100);
}

@media screen and (min-width: 768px) {
  .statuses {
    display: flex;
  }

  .barDraft {
    width: 20%;
  }
}

@media screen and (min-width: 1008px) {
  .statuses {
    margin: 0 auto 24px auto;
  }
}
