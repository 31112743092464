.select {
  display: block;
  margin-bottom: 32px;
  position: relative;
}

.selectIcon {
  position: absolute;
  right: 14px;
  bottom: 22px;
  pointer-events: none;
}

.answersFilter {
  width: 100%;
  -webkit-appearance: none;
  padding: 9px 9px 9px 12px;
  background: var(--color-white-900);
  border: 1.4px solid var(--color-gray-250);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  min-width: 148px;
}

@media screen and (min-width: 1008px) {
  .answersFilter {
    max-width: 148px;
  }
}
