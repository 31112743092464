.content {
  background-color: #fff;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mainLogo {
  width: 170px;
}

.logo a {
  padding: 0;
}

.logoText {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 100%;
}

.hamburger {
  margin-left: auto;
}

.activeMenu {
  position: fixed;
  top: 70px;
  left: 0;
  height: calc(100vh - 70px);
  background-color: #fff;
  z-index: 99;
  width: 100%;
}

.link {
  display: block;
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.login {
  border-radius: 8px;
  background: #5bafd7;
  color: #f8f8fa;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  width: 140px;
  padding: 11px;
}

.closeIcon {
  display: block;
  background-color: transparent;
  padding: 0;
  border: none;
  width: 28px;
}

.closeIcon path {
  fill: #1c1d40;
}

.activeMenuTablet {
  display: none;
}

.activeLink {
  color: #5bafd7;
  text-decoration: underline;
}

.rightContent {
  width: calc(100% - 160px);
  flex: none;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .activeMenu {
    display: none;
  }

  .hamburger {
    display: none;
  }

  .activeMenuTablet {
    display: block;
    margin-left: auto;
  }

  .links {
    flex-direction: row;
  }

  .link {
    color: #71717a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .link:hover {
    color: #5bafd7;
    text-decoration: underline;
  }

  .content {
    padding: 25px 32px;
  }

  .activeLink {
    color: #5bafd7;
    text-decoration: underline;
  }
}

@media screen and (min-width: 1320px) {
  .content {
    padding: 25px 80px;
    align-items: flex-start;
  }

  .login:hover {
    color: var(--color-white-900);
    background: var(--color-blue-700);
    border-color: var(--color-blue-700);
  }

  .main {
    position: relative;
  }

  .logo {
    order: -1;
  }
}
