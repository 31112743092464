.modal {
  max-width: 788px;
  width: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  margin-left: 0;
  top: 50%;
  left: 50%;
  padding: 20px 12px;
  border-radius: 16px;
}

.title {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
}

.slider {
  height: 336px;
}

.slider div {
  height: 100%;
}

.slider img {
  height: 100%;
  object-fit: cover;
}

.arrow {
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowNext {
  right: 20px;
}

.arrowPrev {
  left: 20px;
}

.arrowPrev svg {
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .modal {
    padding: 24px;
  }

  .slider {
    height: 440px;
  }
}
