.content:first-child {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
}

.hidden {
  display: none;
}

.select {
  width: 100%;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.languageName {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  display: block;
  margin-bottom: 18px;
}

.language {
  margin-bottom: 24px;
  position: relative;
}

.language:last-child {
  margin-bottom: 0;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.languageContent {
  width: 100%;
}

.removeButton {
  position: absolute;
  right: 0;
  top: -10px;
}

div.fullWidth,
div.skillLanguage {
  width: 100%;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .name {
    display: none;
  }

  .removeButton {
    position: inherit;
    top: auto;
    right: auto;
  }

  .language {
    display: flex;
    gap: 12px;
    margin-bottom: 32px;
  }

  .languageName {
    display: none;
  }

  .content {
    display: flex;
    align-items: flex-start;
    gap: 36px;
    padding-top: 1px;
  }

  .selectWrap {
    max-width: 180px;
  }

  div.skillLanguage {
    max-width: 330px;
    width: 330px;
  }

  .photoStaff svg {
    display: none;
  }

  .language:last-child {
    margin-bottom: 0;
  }

  .content:first-child {
    padding-bottom: 28px;
    margin-bottom: 28px;
  }

  .head {
    margin-bottom: 0;
  }

  .inputContent {
    width: 100%;
  }

  div.skillLanguage {
    max-width: 100%;
    width: 100%;
  }

  div.fullWidth {
    max-width: 100%;
    width: 100%;
  }
}
