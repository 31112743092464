.banners {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.text {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  max-width: 150px;
  display: block;
}

.banner,
.secondBanner {
  display: flex;
  padding-left: 20px;
  width: 100%;
  height: 240px;
  padding-top: 40px;
  background-size: cover;
  background-position: bottom;
}

.banner {
  background-image: url("../../../media/icons/create-new-account-profession/banner1.png");
}

.secondBanner {
  background-image: url("../../../media/icons/create-new-account-profession/banner2.png");
}

@media screen and (min-width: 1008px) {
  .banners {
    display: flex;
  }

  .banner,
  .secondBanner {
    width: 100%;
    height: 480px;
    padding-left: 56px;
    align-items: center;
    padding-top: 0;
  }

  .text {
    font-size: 36px;
    line-height: 44px;
    max-width: 220px;
  }
}
