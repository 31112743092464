.content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 0;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-blue-800);
}

.icon {
  text-align: center;
  display: block;
  width: 75px;
  height: 75px;
  margin-top: auto;
}

.detail {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-800);
  text-align: center;
  display: block;
  margin-top: 20px;
}

.hr {
  display: block;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 60px 30px 80px;
  }
}
