.content {
  max-width: 1032px;
}

.inputWrapper {
  position: relative;
  margin-bottom: 8px;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
}

.input {
  border-color: transparent;
  padding: 13px 20px 13px 40px;
}

.cityStateZip {
  border-color: transparent;
  padding: 13px 20px 13px 13px;
}

.selectContainer {
  position: relative;
}

.practicesDropdownButton {
  min-height: 48px;
  height: 48px;
  padding-left: 10px;
  padding-right: 20px;
  margin-bottom: 8px;
  width: 100%;
  border-color: transparent;
  border-radius: 8px;
  background-color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.practiceArrow {
  width: 14px;
  height: 14px;
  transform: rotate(180deg);
}

.practiceArrowActive {
  transform: rotate(0deg);
}

.button {
  width: 100%;
  line-height: 20px;
  border: none;
  padding: 12px;
  border-radius: 8px;
}

.cityStateZipContent {
  position: relative;
}

.cityStateZipList {
  width: 100%;
  position: absolute;
  top: 100%;
  background-color: transparent;
  padding: 0;
  border: 1px solid #eaeaea;
  max-height: 250px;
  overflow-y: auto;
  z-index: 2;
}

.cityStateZipListItem {
  border: none;
  width: 100%;
  padding: 16px 10px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}

.cityStateZipListItem:hover {
  background-color: #f8f8fa;
}

.cityStateZipListItem:last-child {
  border: none;
}

.cityStateZipListItemCountry {
  color: #61616f;
  font-size: 16px;
  font-weight: 400;
  display: block;
  line-height: normal;
  margin-bottom: 8px;
}

.cityStateZipListItemState {
  color: var(--color-gray-500);
  font-size: 14px;
  font-weight: 400;
  display: block;
  line-height: normal;
}

.cityStateZipWrap {
  margin-bottom: 8px;
}

.practicesDropdownList {
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  background-color: #fff;
  border: 1px solid #eaeaea;
  padding: 12px;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.dropdownOptions,
.dropdownOptionsBold {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #353546;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 4px 0;
  margin-bottom: 8px;
}

.dropdownOptions:hover,
.dropdownOptionsBold:hover {
  text-decoration: underline;
}

.dropdownOptionsBold {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .content {
    display: flex;
  }

  .input,
  .cityStateZip {
    border-radius: 8px 0 0 8px;
    border-right: 1px solid #eaeaea;
    height: 62px;
  }

  .cityStateZip {
    border-radius: 0;
    border-right: 1px solid #eaeaea;
    height: 62px;
  }

  .cityStateZipWrap {
    width: 100%;
    margin-bottom: 0;
  }

  .practicesDropdownButton {
    border-radius: 0;
    min-height: 62px;
    height: 62px;
    border-right: 1px solid #eaeaea;
    margin-bottom: 0;
    width: 198px;
  }

  .inputWrapper {
    width: 188px;
    flex: none;
    margin-bottom: 0;
  }

  .button {
    width: 120px;
    border-radius: 0 8px 8px 0;
  }

  .practicesDropdownList {
    left: -190px;
    width: 730px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1008px) {
  .practicesDropdownList {
    left: -190px;
    width: 980px;
  }
}

@media screen and (min-width: 1320px) {
  .practicesDropdownList {
    left: -340px;
    width: 1032px;
  }

  .inputWrapper {
    width: 340px;
  }

  .practicesDropdownButton {
    width: 286px;
  }
}
