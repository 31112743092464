.messageThreadItem {
  margin-bottom: 20px;
  position: relative;
  padding: 0 0 0 50px;
}

.initialsContent,
.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ac4b77;
  outline: none;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.message {
  background-color: #eff0f2;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  padding: 16px;
  border-radius: 16px 16px 16px 0;
  word-break: break-all;
}

.date {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #abafb9;
  display: block;
  text-align: left;
  margin-top: 10px;
}

.messageThreadItemClient {
  padding: 0 50px 0 0;
}

.messageThreadItemClient .message {
  background-color: #5bafd7;
  color: #ffffff;
  border-radius: 16px 16px 0 16px;
}

.messageThreadItemClient .date {
  text-align: right;
}
.messageThreadItemClient .initialsContent,
.messageThreadItemClient .image {
  left: auto;
  right: 0;
}
