.paymentOkMain {
  justify-content: space-between;
  max-width: 485px;
}

.popupHeading {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-250);
  margin: 0 0 25px;
}

.paymentOkWrp {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-top: 10%;
}

.check {
  background-size: contain;
  margin: 0 auto 50px;
}

.check svg {
  width: 120px;
  height: 120px;
}
