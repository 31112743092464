.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
}

.title {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
}

.video {
  height: 344px;
  width: 100%;
}

.video img {
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}
