@import "variables.css";
@import "grid.css";
@import "textareaEditor.css";
@import "../fonts/open-sans-kit/openSansFont.css";

body {
  font-family: openSans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  margin: 0;
  color: #353546;
  background-color: var(--color-white-900);
}

.disableScroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

p {
  margin: 0 0 10px;
}

a {
  color: #5bafd7;
  cursor: pointer;
  text-decoration: none;
}

img {
  max-width: 100%;
}

input {
  border: 1px solid #d7d2d2;
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
}

input:focus {
  outline: none;
  border: 1px solid #5bafd7;
}

input:hover,
input:focus {
  background: #f2fafd;
}

input:invalid,
input:-moz-submit-invalid,
input:-moz-ui-invalid {
  box-shadow: none;
}

.button:disabled {
  cursor: default;
}

.button:hover,
.button:active {
  color: #fff;
  background: #2c2d64;
  border-color: #2c2d64;
}

pre {
  white-space: pre-wrap;
  margin: 0 0 10px;
  font-family: openSans, sans-serif;
  font-size: 14px;
}

textarea {
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  overflow: auto;
  resize: none;
  padding: 10px;
}

textarea:focus {
  outline: none;
}

input[type="checkbox"] {
  cursor: pointer;
}

input[type="text"],
input[type="date"],
input[type="url"] {
  width: 100%;
}

input[type="password"] {
  width: 100%;
}

input[type="email"] {
  width: 100%;
}

select {
  display: block;
  font-weight: 600;
  color: #353546;
  padding: 10px;
  width: 100%;
  border: 1px solid #eaeaea;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffff;
  height: 52px;
}

select:focus {
  outline: none;
}

fieldset {
  all: unset;
}

.h3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  margin-top: 0;
}

.h2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-top: 0;
}

.main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

hr {
  all: unset;
  border-top: 1px solid #eaeaea;
  min-width: 100%;
}

.label {
  color: #8b8b8b;
}

.button {
  color: #fff;
  background-color: #5bafd7;
  border: 1.4px solid #5bafd7;
  border-radius: 6px;
  padding: 15px;
  line-height: 19px;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.button--outline {
  color: #8b8b8b;
  background: transparent;
  border: 1.5px solid #eaeaea;
  display: inline-block;
}

.button--clear {
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
}

.button--clear:hover,
.button--clear:active {
  background: none;
  color: unset;
}

.nav {
  display: none;
  padding: 80px 20px 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
}

.title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray-150);
  min-height: 100vh;
}

.sectionOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(9, 10, 40, 0.3);
  overflow: hidden;
  z-index: 3;
}

.sectionOverlayModal {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 50%;
  box-shadow: none;
  border-radius: 0;
}

.zIndex4 {
  z-index: 4 !important;
}

.svg_diagram_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.relative {
  position: relative;
}

div .slick-arrow {
  width: 48px;
  height: 48px;
  background-color: #abafb9;
  z-index: 1;
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .nav {
    padding: 0 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .card {
    justify-content: space-around;
    box-shadow: var(--box-shadow-300);
  }
}

@media screen and (min-width: 1320px) {
  .wrapper {
    padding: 32px 20px;
  }

  .sidebar {
    padding: 95px 80px 30px 30px;
  }
}
