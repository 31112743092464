.wrapper {
  padding: 12px 20px;
  max-width: 712px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white-900);
  min-height: calc(100vh - 72px);
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px;
}

.selectWrap {
  width: 100%;
  margin: 12px 0;
}

.checkboxName {
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  margin-top: -3px;
}

.selectLatest > [class$="control"] {
  min-height: 44px;
  max-height: 44px;
  border-color: #eaeaea;
}

.selectLatest {
  width: 100%;
}

.selectLatest > [class$="container"] {
  width: 100%;
}

.selectLatest div[class$="ValueContainer"] {
  padding-left: 12px;
}

.mainTitle {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  pointer-events: none;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.disable {
  pointer-events: none;
}

.filter {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  cursor: pointer;
}

.asideTitle {
  display: inline-block;
  margin-right: 100px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.rightSide {
  display: none;
}

.selectMobile {
  flex: 0 0 25%;
  max-width: 25%;
}

.tabNavContent {
  width: 100%;
}

div .team {
  width: 100%;
  margin-top: 12px;
}

@media screen and (min-width: 768px) {
  .heading {
    margin-bottom: 20px;
  }

  .flex {
    position: relative;
  }

  .mainTitle {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;
  }

  .selectWrap {
    width: 250px;
    margin: 0;
  }

  div .team {
    width: auto;
    margin: 0;
  }
}

@media screen and (min-width: 1008px) {
  .wrapper {
    max-width: 1204px;
    margin: 0 auto;
    padding: 4px 24px 24px;
  }

  .heading {
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 0;
  }

  .mainTitle {
    margin-bottom: 40px;
  }

  .rightSide {
    display: block;
    flex: 0 0 350px;
    margin-right: 50px;
  }

  .selectMobile {
    display: none;
  }

  .content {
    margin: 0;
    padding: 0 5px;
  }
  .sidebar {
    margin: 0;
  }
  .container {
    display: flex;
  }
}

@media screen and (min-width: 1320px) {
  .rightSide {
    display: block;
    flex: 0 0 400px;
    margin-right: 50px;
  }

  .flex {
    display: flex;
    margin: 0 0 24px;
  }
}
