.headerBackground {
  background-color: #fff;
}

.headerMessages {
  background-color: #f8f8fa;
}

.headerGlobal {
  display: flex;
  padding: 24px 20px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  max-width: 1204px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.headerActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  max-height: 74px;
}

.headerMessages .headerActions {
  background-color: transparent;
}

.headerMessages .headerGlobal {
  max-width: 1306px;
}

.relative {
  position: relative;
}

@media screen and (min-width: 1008px) {
  .mainSearch {
    order: -2;
  }

  .header {
    padding: 32px 40px;
  }
}

@media screen and (min-width: 1320px) {
  .headerGlobal {
    padding: 24px 24px;
    max-height: 114px;
  }

  .headerMessages .headerGlobal {
    padding: 32px 8px;
  }
}
