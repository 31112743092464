.link {
  color: var(--color-white-900);
  background-color: var(--color-blue-400);
  border: 1.4px solid var(--color-blue-400);
  border-radius: 6px;
  padding: 15px;
  line-height: 20px;
  cursor: pointer;
  display: block;
}

.link:hover {
  color: var(--color-white-900);
  background: #2c2d64;
  border-color: #2c2d64;
}

.wrapper {
  justify-content: center;
}

.mainLogo {
  width: 170px;
}

.header {
  background: var(--color-white-900);
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 32px;
  z-index: 4;
}

.headerLink {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.info {
  margin: 0 auto 20px;
}

.footer {
  margin: 0 auto;
}
