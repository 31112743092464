.greyDatePicker {
  background: #f8f8fa;
}

.greyDatePicker input {
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.greyDatePicker fieldset {
  border: none;
}

.greyDatePicker > div {
  display: flex;
  flex-direction: row-reverse;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 8px;
}

.asterisk {
  color: var(--color-red-400);
  margin: 0 2px;
}

.datePicker {
  width: 100%;
}

.datePicker input {
  padding: 10px 5px;
}

.datePicker input:focus {
  border: none;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  margin: 5px 0 -10px;
}
