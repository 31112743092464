.mainListings {
  position: relative;
}

.head {
  display: none;
  align-items: center;
  gap: 32px;
  padding: 16px 0;
  border-bottom: 1px solid #eaeaea;
}

.disableAll .headItem {
  opacity: 0;
  pointer-events: none;
}

.headItem {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #1c1d40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.headItemNoActive {
  cursor: default;
}

.headItem span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 4px 6px;
  border-radius: 6px;
  width: -moz-max-content;
  width: max-content;
  width: -moz-available;
}

.controlMenu {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 10px;
}

.headItem svg {
  width: 14px;
  height: 14px;
}

.headItem:first-child {
  min-width: 254px;
  max-width: 346px;
  width: 100%;
}

.headItem:nth-child(2) {
  min-width: 129px;
  max-width: 221px;
  width: 100%;
}

.headItem:nth-child(3) {
  min-width: 130px;
  max-width: 220px;
  width: 100%;
}

.headItem:nth-child(4) {
  min-width: 136px;
  max-width: 0;
  width: 100%;
}

.listingsItem {
  padding: 16px 0;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}

.listingsContent {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.listingsContent:last-child {
  margin-bottom: 0;
}

.listingsContentUnActive {
  margin: 0;
}

.listingsLabel {
  color: #353546;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  width: 60px;
}

.listingDetail {
  padding-right: 40px;
  width: 100%;
}

.date {
  color: #8b8b8b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 4px;
}

.membership {
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 4px 0;
}

.membership:hover {
  text-decoration: underline;
}

.attorney {
  color: #8b8b8b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.statusActive {
  color: #5bafd7;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  display: block;
}

.mobileDropDown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.mobileDropDownActive {
  transform: rotate(180deg);
}

.mobileDropDown svg {
  width: 16px;
  height: 16px;
}

.content {
  display: none;
}

.contentActive {
  display: block;
}

.statusActive:hover {
  text-decoration: none;
}

.status {
  display: flex;
}

.statusUnActive {
  color: #abafb9;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.statusUnActiveExpired {
  color: #e16d4f;
}

.changes {
  display: flex;
}

.changesText {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  gap: 8px;
  align-items: baseline;
  border-radius: 6px;
  padding: 2px 4px;
}

.changesTextGrey {
  background: #f4f5f6;
}

.changesTextYellow {
  background: #fdf2da;
  color: #f7bf44;
}

.changesTextGreen {
  background: #e9f1da;
  color: #91b848;
}

.changesTextRed {
  color: #da4655;
  background: #f9e1e4;
}

.pin {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.pinRed {
  background-color: #da4655;
}

.pinGreen {
  background-color: #91b848;
}

.pinGrey {
  background-color: #abafb9;
}

.pinYellow {
  background-color: #f7bf44;
}

.pinDarkGrey {
  background-color: #abafb9;
}

.sponsor {
  color: #ac4b77;
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.free {
  display: block;
  margin-bottom: 8px;
  color: #353546;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.options {
  color: var(--blue, #5bafd7);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.options:hover {
  text-decoration: none;
}

.promptControlMenu {
  right: 50px;
  transform: translate(0, -10%);
}

.promptControlMenu:after {
  right: 0;
  bottom: auto;
  top: 37px;
}

.mainListings {
  position: relative;
}

.headItemActive span {
  border-radius: 6px;
  background-color: #f4f5f6;
}

.headItemActive svg {
  transform: rotate(180deg);
}

.pagination {
  margin: 24px 0;
}

.pagination > nav {
  margin: 0;
  justify-content: flex-start;
}

.disableAll .headItem {
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .pagination {
    margin: 40px 0;
  }
  .controlMenu {
    top: 10px;
    right: 10px;
  }

  .listingDetail {
    padding-right: 0;
  }

  .head {
    display: flex;
  }

  .listingsItem {
    display: flex;
    gap: 32px;
    margin: 0;
    padding: 24px 4px;
  }

  .listingsLabel {
    display: none;
  }

  .listingsContent {
    margin-bottom: 0;
    padding-left: 6px;
  }

  .listingsContentFirst {
    min-width: 254px;
    max-width: 346px;
    width: 100%;
  }

  .listingsContentSecond {
    min-width: 129px;
    max-width: 221px;
    width: 100%;
  }

  .listingsContentThird {
    min-width: 130px;
    max-width: 220px;
    width: 100%;
  }

  .listingsContentFourth {
    min-width: 136px;
    max-width: 0;
    width: 100%;
  }

  .statusActive,
  .statusUnActive,
  .changesText,
  .sponsor,
  .free {
    font-size: 14px;
    line-height: 20px;
  }

  .mobileDropDown {
    display: none;
  }

  .content {
    display: flex;
    gap: 32px;
    width: 100%;
  }

  .listingsItemActive {
    background: #f8fdff;
  }
}
