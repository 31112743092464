.answer {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 25px 25px 0 25px;
  margin-bottom: 32px;
  position: relative;
}

.heading {
  display: flex;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-250);
  padding-bottom: 17px;
}

.downLine {
  width: 100%;
  border-top: 1px solid #eaeaea;
  margin-bottom: 20px;
}

.comments {
  position: relative;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.counterWrap {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.heading {
  display: flex;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-250);
  padding-bottom: 17px;
}

.photo {
  width: 92px;
  height: 92px;
  min-height: 92px;
  background-color: var(--color-red-400);
  overflow: hidden;
  margin-right: 17px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
}

.company:first-letter {
  text-transform: capitalize;
}

.city {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 1px;
  color: var(--color-gray-500);
}

.calendarIcon {
  position: absolute;
  top: 0;
  display: none;
}

.panel {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
}

.date {
  color: var(--color-gray-500);
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  position: relative;
}

.text {
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-black-500);
  margin-bottom: 32px;
}

.helpButtons {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.helpful,
.unhelpful {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: var(--color-gray-500);
}

.helpful {
  padding-right: 30px;
}

.helpfulIcon {
  position: absolute;
  top: 0;
  left: 60px;
  outline: none;
}

.unhelpfulIcon {
  position: absolute;
  right: 0;
  outline: none;
}

.counter {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black-500);
  margin: 0 8px;
}

.main {
  align-items: center;
  text-align: left;
  justify-content: center;
  margin-bottom: 10px;
}

.grey {
  color: var(--color-gray-500);
  display: inline-block;
  margin-right: 5px;
}

.dropdownComments {
  border: 0;
  margin-bottom: 44px;
  justify-content: center;
}

.initials {
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
}

.dropdownButton {
  display: flex;
  padding-top: 12px;
}

@media screen and (min-width: 768px) {
  .counterWrap {
    display: flex;
    margin-bottom: 0;
  }

  .panel:first-child:after {
    content: "|";
    display: inline-block;
    padding: 0 5px;
  }

  .main {
    padding-top: 0;
    justify-content: left;
  }

  .helpButtons {
    justify-content: right;
    position: absolute;
    right: 0;
  }

  .calendarIcon {
    display: block;
  }

  .date {
    padding-left: 30px;
    margin: 0;
  }

  .infoBox {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
  }

  .name {
    font-size: 24px;
    line-height: 34px;
  }

  .heading {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }

  .photo {
    width: 120px;
    height: 120px;
    margin-right: 25px;
  }

  .company {
    font-size: 20px;
    line-height: 28px;
  }

  .city {
    font-size: 14px;
    line-height: 20px;
  }

  .dropdownComments {
    width: 100%;
    padding-top: 0;
  }

  .helpful {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1008px) {
  .helpful {
    margin-right: 65px;
  }

  .helpButtons {
    margin-bottom: 0;
  }

  .initials {
    font-size: 44px;
  }
}
