.questionNumber {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1c1d40;
  display: inline-block;
  margin-right: 5px;
}

.wrapper,
.wrapperDatePicker {
  margin-bottom: 24px;
}

.wrapperDatePicker > div {
  width: 100%;
}

.wrapperDatePicker input {
  padding: 10.5px 12px;
}

.question {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
}

.myAnswer {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 8px;
}

.textArea {
  height: 44px;
  padding: 12px;
}

.select [class$="control"] {
  max-height: 44px;
  min-height: 44px;
  border-radius: 8px;
  border-color: #eaeaea;
}
