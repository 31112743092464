.tabs {
  display: flex;
  gap: 24px;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 24px;
}

.tab {
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 10px;
  display: block;
  position: relative;
}

.tab:after {
  content: "";
  height: 3px;
  width: 100%;
  background-color: #5bafd7;
  border-radius: 3px 3px 0 0;
  display: none;
  position: absolute;
  bottom: 0;
}

.tabsActive:after {
  display: block;
}

.tabsActive {
  color: #5bafd7;
}

@media screen and (min-width: 1320px) {
  .tabs {
    margin-bottom: 28px;
  }
}
