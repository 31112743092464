.hamburger {
  background: none;
  border: none;
  outline: none;
  width: 28px;
  height: 28px;
  padding: 0;
}

.hamburger svg {
  margin-top: 7px;
}

.icon {
  min-width: 25px;
  margin-right: 0;
}

.iconClose {
  width: 25px;
}

.iconClose path {
  fill: #1c1d40;
  stroke: #1c1d40;
}

@media screen and (min-width: 1008px) {
  .hamburger {
    margin-left: auto;
  }
}

@media screen and (min-width: 1320px) {
  .hamburger {
    display: none;
  }
}
