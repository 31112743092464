.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
}

.tile {
  padding: 12px 0;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0;
}

.tile:first-child {
  padding-top: 0;
}

.tile:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.title {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
}

.tileTitle {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.tileDescription {
  color: #61616f;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
}

.tileAuthor {
  color: #61616f;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .tileHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .tileDescription {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .tileTitle {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tile {
    padding: 24px 0;
    border-radius: 0;
  }

  .tile:first-child {
    padding-top: 0;
  }

  .tile:last-child {
    padding-bottom: 0;
  }

  .tileAuthor {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}
