.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
  }
}
