.matches {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  max-width: 1204px;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid #eaeaea;
}

.matches:last-child {
  margin-bottom: 0;
}

.photo {
  max-width: 75px;
  margin-bottom: 16px;
  flex: none;
  border-radius: 50%;
}

.name {
  color: #353546;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}

.address {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
}

.info {
  margin-bottom: 20px;
}

.website {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5bafd7;
  margin-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;
}

.website:hover {
  text-decoration: none;
}

.profile {
  border: 1px solid #5bafd7;
  padding: 11px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0;
  background-color: #fff;
  cursor: pointer;
}

.icon {
  fill: #fff;
  display: inline-block;
  margin-right: 20px;
}

.uppercase {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  text-transform: uppercase;
}

.button {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 11px 12px;
  width: 130px;
  margin-left: auto;
  display: block;
  height: 44px;
}

.container {
  padding: 20px;
  border-radius: 16px;
  background: #fff;
  margin-bottom: 24px;
  border: 1px solid #eaeaea;
}

.title {
  color: #353546;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 32px;
}

.rightSide {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}

.label {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.48px;
  margin-bottom: 8px;
  display: block;
}

.value {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
}

.detailItem {
  margin-bottom: 40px;
}

.detail {
  padding-top: 32px;
}

.indent {
  display: block;
  margin-top: 24px;
  border-top: 1px solid #eaeaea;
}

@media screen and (min-width: 1008px) {
  .flex {
    display: flex;
    align-items: center;
  }

  .photo {
    margin-bottom: 0;
    margin-right: 16px;
  }

  .info {
    width: 330px;
    flex: none;
    padding-right: 20px;
    margin-bottom: 0;
  }

  .website {
    margin: 0;
  }

  .detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 90px;
    max-width: 420px;
  }

  .detailItem {
    width: 50%;
    flex: none;
  }
}
